import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    status: '',
    message: '',
    BidingList: [],
    BidingData: {}
};

const bidingDetail = createSlice({
    name: 'bidingDetail',
    initialState,
    reducers: {
        createBidPending: (state) => {
            state.isLoading = true;
        },
        createBidSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload
        },
        createBidError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        createBidResetMessage: (state) => {
            state.message = '';
        },
        getBidingListPending: (state) => {
            state.isLoading = true;
        },
        getBidingListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.BidingList = payload
        },
        getBidingListError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getBidingListResetMessage: (state) => {
            state.message = '';
        },


        getBidingDetailPending: (state) => {
            state.isLoading = true;
        },
        getBidingDetailSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.BidingData = payload
        },
        getBidingDetailError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getBidingDetailResetMessage: (state) => {
            state.message = '';
        },


        claimStatusPending: (state) => {
            state.isLoading = true;
        },
        claimStatusSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload
        },
        claimStatusError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        claimStatusResetMessage: (state) => {
            state.message = '';
        },
    },
});

const { reducer, actions } = bidingDetail;
export const { createBidPending, createBidSuccess, createBidError, createBidResetMessage,
    getBidingListPending, getBidingListSuccess, getBidingListError, getBidingListResetMessage,
    getBidingDetailPending, getBidingDetailSuccess, getBidingDetailError, getBidingDetailResetMessage,
    claimStatusPending,claimStatusSuccess,claimStatusError,claimStatusResetMessage

} = actions;

export default reducer;