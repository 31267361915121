import React from "react";
import Slider from "react-slick";
import './NFTCollections.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bestImg6 from '../assets/images/6.jpg';
import bestImg7 from '../assets/images/7.png';
import bestImg8 from '../assets/images/8.jpg';
import bestImg9 from '../assets/images/9.jpg';
import gif1 from '../assets/bottle1.gif'
import gif2 from '../assets/bottle2.gif'
import gif3 from '../assets/bottle3.gif'
import globeGif from '../assets/The Globe.gif'
import { NavLink, Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

const BestCollection = () => {
  AOS.init({
      once: true,
      duration: 2000,
      });

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1169,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 999,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 2
              }
            }
          ]
    };

    return (
        <>
            <div className='pb-large bestCollection' data-aos="fade-right">
                <div className='mainHd mb-3 mb-md-4'>Best NFT Collections</div>
                <Slider className="collectionSlider" {...settings}>
                    <div className="item">
                        <NavLink>
                          <img alt="" src={gif1} />
                          <div className="over">
                              <div className="inner">
                              <li><Link to="/top-collections">
                                <span className="name">Murano Most Etro 105, 2004</span>
                                <span className="info">Floor Price <span>05.02 USD</span></span>
                                <span className="info">Volume <span>2.3 USD</span></span>
                                </Link></li>
                              </div>
                          </div>
                        </NavLink>                      
                    </div>
                    <div className="item">
                        <NavLink>
                          <img alt="" src={gif3} />
                          <div className="over">
                              <div className="inner">
                              <li><Link to="/top-collections">
                                <span className="name">Murano Most Missoni 168, 2005</span>
                                <span className="info">Floor Price <span>05.02 USD</span></span>
                                <span className="info">Volume <span>2.3 USD</span></span>
                                </Link></li>
                              </div>
                          </div>
                        </NavLink>                      
                    </div>
                    <div className="item">
                        <NavLink>
                          <img alt="" src={gif2} />
                          <div className="over">
                              <div className="inner">
                              <li><Link to="/top-collections">
                                <span className="name">Muarno Most bepitsolini 1/1, 2004</span>
                                <span className="info">Floor Price <span>05.02 USD</span></span>
                                <span className="info">Volume <span>2.3 USD</span></span>
                                </Link></li>
                              </div>
                          </div>
                        </NavLink>                      
                    </div>
                    <div className="item">
                        <NavLink>
                          <img alt="" src={globeGif} />
                          <div className="over">
                              <div className="inner">
                              <li><Link to="/top-collections">
                                <span className="name">The Globe 1866</span>
                                <span className="info">Floor Price <span>05.02 USD</span></span>
                                <span className="info">Volume <span>2.3 USD</span></span>
                                </Link></li>
                              </div>
                          </div>
                        </NavLink>                      
                    </div>
                </Slider>
            </div>
        </>

    )

}
export default BestCollection;