import React, { useEffect } from 'react';
import './MyProfile.scss';
import { NavLink, Link, } from "react-router-dom";
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import Button from 'react-bootstrap/Button';
import EmptyBox from '../assets/images/empty_box.png';
import UserImg from '../assets/images/user_img.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { getCollectionsList } from './redux/action/collectionAction';
import Collections from '../component/Collections.js';

const MyCollections = () => {
    const dispatch = useDispatch();
    const { collectionList } = useSelector((state) => state.collectionDetail)
    useEffect(() => {
        dispatch(getCollectionsList());
    }, [getCollectionsList])
    


    return (

        <>
            <div className="mainCon">
                <Header />
                <div className='pt-md-5 pb-md-5 pt-4 pb-4'>
                    <div className='container'>
                        <div className='mainHd mb-2 mb-md-2 text-center'>My Collections</div>
                        <div className='noteText mb-5 text-center'>Create, curate, and manage collections of unique NFTs to share and sell.</div>
                        {/* <div className='text-center'><img alt="" src={EmptyBox} /></div>
                        <div className='text-center pt-3 pb-4 noResult'>Nothing Found</div> */}
                        {/* <div className='text-center'><NavLink to="/create-new-collection" activeClassName="active"><Button type="button">Create New Collection</Button></NavLink></div> */}
                        <Collections />
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default MyCollections;