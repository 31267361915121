import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import './Admin.scss';
import AdminHeader from '../../component/AdminHeader';
import AdminMenu from '../../component/AdminMenu';
import Button from 'react-bootstrap/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DetailImg from '../../assets/images/watch_img6.png'
import rotate from '../../assets/images/rotate.png';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import DetailNft from '../../component/DetailNft.js';
import DetailBid from '../../component/DetailBid.js';
import DetailHistory from '../../component/DetailHistory.js';
import { useSelector, useDispatch } from 'react-redux';
import { collectionDetail } from '../redux/action/adminUserAction';

const AdminFunctionDetail = () => {
    const dispatch = useDispatch();
    const { collectionDatas } = useSelector((state) => state.adminUserDetail)
    const collectionId = JSON.parse(localStorage.getItem('collectionId'));

    console.log('collectionData-qqqq----->', collectionDatas.data && collectionDatas.data.data[0].userName)

    useEffect(() => {
        dispatch(collectionDetail(collectionId));
    }, [collectionDetail])


    return (

        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className='mb-3 d-flex align-items-center'>
                            <div className='me-3'><NavLink to="/admin-function"><Button className='backBtn d-flex align-items-center'><KeyboardBackspaceIcon className='me-1' /> Back</Button></NavLink></div>
                            <div className="dbMainHd">Admin Function Details</div>
                        </div>
                        <div className='mb-4 mb-md-5 nftDetail d-flex flex-wrap'>
                            <div className='img position-relative'><span className='rotate'><img alt="" src={rotate} /></span><img src={collectionDatas.data && collectionDatas.data.data[0].profileImage} alt='' /></div>
                            <div className='det ps-0 ps-md-4'>
                                <ul className='p-0 m-0'>

                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>Collection Name:</span>
                                        <span className='right'>{collectionDatas.data && collectionDatas.data.data[0].collectionName}</span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>Category:</span>
                                        <span className='right'>{collectionDatas.data && collectionDatas.data.data[0].category}</span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>Creator:</span>
                                        <span className='right'>{collectionDatas.data && collectionDatas.data.data[0].userName}</span>
                                    </li>



                                    <li className='d-flex pt-3 pb-3 desc'>
                                        <span className='left me-3'>User Request Message:</span>
                                        <span className='right'>{collectionDatas.data && collectionDatas.data.data[0].authAnswer}</span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>Collection Introduction File</span>
                                        <span className='right'><a href={collectionDatas.data && collectionDatas.data.data[0].collectionIntroduction} target="_blank">View File</a></span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>Collection Details Files</span>
                                        <span className='right'><a href={collectionDatas.data && collectionDatas.data.data[0].collectionDetailsFile} target="_blank">View File</a></span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>Collection License
                                            NFT  </span>
                                        <span className='right'><a href={collectionDatas.data && collectionDatas.data.data[0].CollectionLicenseNFTfile} target="_blank">View File</a></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default AdminFunctionDetail;