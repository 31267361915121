import React, { } from 'react';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import BannerImg from '../assets/images/limited_editions_img2.jpg';
import Shipmenticon1 from '../assets/images/shipment-icon1.png';
import Shipmenticon2 from '../assets/images/shipment-icon2.png';
import Shipmenticon3 from '../assets/images/shipment-icon3.png';
import Shipmenticon4 from '../assets/images/shipment-icon4.png';
import Shipmenticon5 from '../assets/images/shipment-icon5.png';
import Shipmenticon6 from '../assets/images/shipment-icon6.png';
import Shipmenticon7 from '../assets/images/shipment-icon7.png';
import Shipmenticon8 from '../assets/images/shipment-icon8.png';
import Shipmenticon9 from '../assets/images/shipment-icon9.png';
import Shipmenticon10 from '../assets/images/shipment-icon10.png';
const NFTShipment = () => {



    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='mainHd mb-3'>SHIPMENTS</div>
                        <div className='fractionalBanner d-flex flex-wrap align-items-center mb-5 flex-column-reverse flex-sm-row'>
                            <div className='right text-center pe-0 pt-sm-0 pt-3 pe-sm-5'>COMING SOON</div>
                            <div className='left'><img src={BannerImg} alt='' className='img' /></div>
                        </div>
                        <div className='contentBox pb-4'>
                            <p>conversion of a physical asset into a non-fungible token (NFT), followed by shipping the physical asset to the owner and finally delivering it. Here's a step-by-step process for this:</p>
                            <ul className='shipmentList m-0 p-0'>
                                <li>
                                    <div className='icon'><img src={Shipmenticon1} alt='' /></div>
                                    <div className='det'>
                                        <span>Asset Assessment and Documentation:</span>
                                        <ul className='m-0 p-0'>
                                            <li>Identify the physical asset to be tokenized as an NFT.</li>
                                            <li>Document the details of the asset, including its condition, specifications, and any relevant information.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className='icon'><img src={Shipmenticon2} alt='' /></div>
                                    <div className='det'>
                                        <span>Creation of NFT:</span>
                                        <ul className='m-0 p-0'>
                                            <li>Choose a blockchain platform that supports NFTs, such as Ethereum or Binance Smart Chain.</li>
                                            <li>Use a smart contract to tokenize the physical asset, creating a unique NFT that represents ownership.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className='icon'><img src={Shipmenticon3} alt='' /></div>
                                    <div className='det'>
                                        <span>Ownership Transfer:</span>
                                        <ul className='m-0 p-0'>
                                            <li>Establish a secure and transparent process for transferring ownership of the NFT to the new owner.</li>
                                            <li>Execute the transfer of ownership through the blockchain, ensuring that it is properly recorded on the blockchain's decentralized ledger.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className='icon'><img src={Shipmenticon4} alt='' /></div>
                                    <div className='det'>
                                        <span>Verification of Ownership:</span>
                                        <ul className='m-0 p-0'>
                                            <li>The new owner can verify their ownership by checking the blockchain for the NFT details and confirmation of the ownership transfer.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className='icon'><img src={Shipmenticon5} alt='' /></div>
                                    <div className='det'>
                                        <span>Shipping Preparation:</span>
                                        <ul className='m-0 p-0'>
                                            <li>Once ownership is confirmed, prepare the physical asset for shipping.</li>
                                            <li>Ensure that the asset is securely packaged to prevent damage during transit.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className='icon'><img src={Shipmenticon6} alt='' /></div>
                                    <div className='det'>
                                        <span>Shipping Process:</span>
                                        <ul className='m-0 p-0'>
                                            <li>Arrange for shipping with a reliable courier or logistics service.</li>
                                            <li>Provide the shipping company with the necessary documentation, including the destination address and any required customs information.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className='icon'><img src={Shipmenticon7} alt='' /></div>
                                    <div className='det'>
                                        <span>Tracking and Updates:</span>
                                        <ul className='m-0 p-0'>
                                            <li>Enable tracking for the shipment so that both the sender and the recipient can monitor the progress in real-time.</li>
                                            <li>Provide regular updates to the owner regarding the status of the shipment.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className='icon'><img src={Shipmenticon8} alt='' /></div>
                                    <div className='det'>
                                        <span>Delivery Confirmation:</span>
                                        <ul className='m-0 p-0'>
                                            <li>Once the physical asset reaches its destination, obtain confirmation of delivery from the shipping company.</li>
                                            <li>This confirmation may include a signature from the recipient or other proof of delivery.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className='icon'><img src={Shipmenticon9} alt='' /></div>
                                    <div className='det'>
                                        <span>Final Verification:</span>
                                        <ul className='m-0 p-0'>
                                            <li>Confirm with the owner that the physical asset has been received in the expected condition.</li>
                                            <li>Address any issues or concerns related to the delivery promptly.</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className='icon'><img src={Shipmenticon10} alt='' /></div>
                                    <div className='det'>
                                        <span>Completion and Documentation:</span>
                                        <ul className='m-0 p-0'>
                                            <li>Close the transaction by updating any relevant records and documentation.</li>
                                            <li>Maintain a record of the entire process, including the creation of the NFT, ownership transfer, and physical asset delivery.</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>

    )

}
export default NFTShipment;