import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import './Checkout.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import Form from 'react-bootstrap/Form';
import itemImg from '../assets/images/watch_img6.png'
import hBar from '../assets/images/hedera_logo.png'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import EmiraresDelivers from '../assets/images/emirares-delivers.png';
import UPS from '../assets/images/ups.png';
import FedEx from '../assets/images/fedex.png';
import Aramex from '../assets/images/aramex.png';
const Checkout = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='mainHd mb-2 mb-md-3'>Checkout</div>
                        <div className='d-flex flex-wrap justify-content-between'>
                            <div className='checkoutLeft'>
                                <div className='checkoutInner p-3 p-sm-4 mb-4 rounded-3'>
                                    <ul className='form p-0 d-flex flex-wrap pb-4'>
                                        <li className='col-6'>
                                            <label className='fieldHd d-block pb-2 ps-1'>First Name</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-6'>
                                            <label className='fieldHd d-block pb-2 ps-1'>Last Name</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-6'>
                                            <label className='fieldHd d-block pb-2 ps-1'>Email Address</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-6'>
                                            <label className='fieldHd d-block pb-2 ps-1'>Phone Number</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-12'>
                                            <label className='fieldHd d-block pb-2 ps-1'>Country</label>
                                            <div className='selectBox'>
                                                <select className='formInput'>
                                                    <option>Select</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li className='col-12'>
                                            <label className='fieldHd d-block pb-2 ps-1'>Address 1</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-12'>
                                            <label className='fieldHd d-block pb-2 ps-1'>Address 2</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-12'>
                                            <label className='fieldHd d-block pb-2 ps-1'>City</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-6'>
                                            <label className='fieldHd d-block pb-2 ps-1'>State/Province</label>
                                            <div className='selectBox'>
                                                <select className='formInput'>
                                                    <option>Select</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li className='col-6'>
                                            <label className='fieldHd d-block pb-2 ps-1'>Zip/Postal Code</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                    </ul>
                                    <div className='d-flex justify-content-between align-items-center mb-4'>
                                        <div className='dbMainHd'>Shipping Details</div>
                                        <div><label className='d-flex align-items-center grayClr'><Form.Check className='checkbox me-2' /> Same as billing address</label></div>
                                    </div>
                                    <ul className='form p-0 d-flex flex-wrap'>
                                        <li className='col-6'>
                                            <label className='fieldHd d-block pb-2 ps-1'>First Name</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-6'>
                                            <label className='fieldHd d-block pb-2 ps-1'>Last Name</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-12'>
                                            <label className='fieldHd d-block pb-2 ps-1'>Address 1</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-12'>
                                            <label className='fieldHd d-block pb-2 ps-1'>Address 2</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-12'>
                                            <label className='fieldHd d-block pb-2 ps-1'>City</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li className='col-6'>
                                            <label className='fieldHd d-block pb-2 ps-1'>State/Province</label>
                                            <div className='selectBox'>
                                                <select className='formInput'>
                                                    <option>Select</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li className='col-6'>
                                            <label className='fieldHd d-block pb-2 ps-1'>Zip/Postal Code</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                    </ul>
                                </div>
                                <div className='checkoutInner p-3 p-sm-4 mb-4 rounded-3'>
                                    <div className='dbMainHd'>Shipping Price</div>
                                    <ul className='m-0 p-0 shippingPrice'>
                                        <li className='d-flex mt-3 justify-content-between align-items-center'>
                                            <label className='d-flex align-items-center'><Form.Check className='checkbox me-3' /> Same as billing address</label>
                                            <div>$20.50</div>
                                        </li>
                                        <li className='d-flex mt-3 justify-content-between align-items-center'>
                                            <label className='d-flex align-items-center'><Form.Check className='checkbox me-3' /> One Day Delivery</label>
                                            <div>$17.50</div>
                                        </li>
                                        <li className='d-flex mt-3 justify-content-between align-items-center'>
                                            <label className='d-flex align-items-center'><Form.Check className='checkbox me-3' /> Two Day Delivery</label>
                                            <div>$10.50</div>
                                        </li>
                                        <li className='d-flex mt-3 justify-content-between align-items-center'>
                                            <label className='d-flex align-items-center'><Form.Check className='checkbox me-3' /> Free Delivery</label>
                                            <div>$0.00</div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='checkoutInner p-3 p-sm-4 mb-4 rounded-3'>
                                    <div className='dbMainHd pb-3'>Shipping Partners</div>
                                    <ul className='p-0 partnerList d-flex flex-wrap'>
                                        <li><label><img src={EmiraresDelivers} alt='' /> <input type='radio' name='partner' className='radio' /></label></li>
                                        <li><label><img src={UPS} alt='' /> <input type='radio' name='partner' className='radio' /></label></li>
                                        <li><label><img src={FedEx} alt='' /> <input type='radio' name='partner' className='radio' /></label></li>
                                        <li><label><img src={Aramex} alt='' /> <input type='radio' name='partner' className='radio' /></label></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='checkoutRight'>
                                <div className='checkoutInner rounded-3'>
                                    <div className='p-3 p-sm-4'>
                                        <div className='dbMainHd mb-3'>Your Order</div>
                                        <div className='orederDet d-flex align-items-center justify-content-between'>
                                            <div className='det d-flex align-items-center'>
                                                <div className='img me-3'><img src={itemImg} alt='' className='rounded-2 me-2' /></div>
                                                <div className='address'>Breitling Superocean Heritage B01 Chronograph 44 Limited Edition</div>
                                            </div>
                                            <div className='right text-end ms-3'>
                                                <div className='d-flex align-items-center'><span className='icon d-flex align-items-center justify-content-center me-2 rounded-circle'><img src={hBar} alt='' /></span>5.89</div>
                                                <div className='mt-2 price'>$16,589.58</div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className='m-0 p-0 priceList'>
                                        <li className='d-flex justify-content-between align-items-center ps-3 pe-3 ps-sm-4 pe-sm-4 pt-3 pb-3'>
                                            <span>Subtotal</span>
                                            <span>$16,589.58</span>
                                        </li>
                                        <li className='d-flex justify-content-between align-items-center ps-3 pe-3 ps-sm-4 pe-sm-4 pt-3 pb-3'>
                                            <span>Shipping</span>
                                            <span>$17.50</span>
                                        </li>
                                        <li className='d-flex justify-content-between align-items-center ps-3 pe-3 ps-sm-4 pe-sm-4 pt-3 pb-3'>
                                            <span>Taxes</span>
                                            <span>$152.62</span>
                                        </li>
                                        <li className='total d-flex justify-content-between align-items-center ps-3 pe-3 ps-sm-4 pe-sm-4 pt-3 pb-3'>
                                            <span>Total</span>
                                            <span>$16,759.70</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className='text-center mt-4'><Button onClick={handleShow}>Next - Payment</Button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <Modal className="popupBox" show={show}>
                <Modal.Body>
                    <div className='text-center'>
                        <div className='sucessfullIcon mb-3'><TaskAltOutlinedIcon /></div>
                        <div className='mb-4 dbMainHd'>Your order has been placed successfully!</div>
                        <div className='mb-4'><NavLink to="/my-order" className='trackShipment'>Track Shipment</NavLink></div>
                        <div><NavLink to="/my-order"Z><Button>Close</Button></NavLink></div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )

}
export default Checkout;