import { userDeatailPending, userDeatailSuccess, userDeatailError, userDeatailResetMessage, userUpdateSuccess } from '../reducer/userReducer';
import { fetchUser, updateUser } from '../../../api/userApi';
import { multiPartData } from '../../../utils';

export const getUserDetail = (frmDt) => async (dispatch) => {
    try {
        dispatch(userDeatailPending());
        const result = await fetchUser();
        result.code === 200
            ? dispatch(userDeatailSuccess(result))
            : dispatch(userDeatailError(result.message));


    } catch (error) {
        dispatch(userDeatailResetMessage(error.message));
    }
};

export const updateUserDetail = (frmDt) => async (dispatch) => {
    try {
        const updatedData = multiPartData(frmDt)
        dispatch(userDeatailPending());
        const result = await updateUser(updatedData);
        console.log('result------>',result)
        if (result.code === 200) {
            return dispatch(userUpdateSuccess(result))
        } else {
            return dispatch(userDeatailError(result.message));
        }



    } catch (error) {
        dispatch(userDeatailResetMessage(error.message));
    }
};