import React, { useState,useEffect } from 'react';
import './ProfileMenu.scss';
import { NavLink, Link } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PaymentIcon from '@mui/icons-material/Payment';
import SecurityIcon from '@mui/icons-material/Security';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const ProfileMenu = () => {

    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };
    
    return (
        <>
            <div className="profileMenu">
                <a className={isActive ? 'showMenu active' : 'showMenu'} 
      onClick={toggleClass} ><span className="show">Show</span> <span className="hide">Hide</span> Menu</a>
                <div onClick={toggleClass} className={isActive ? 'bgCover active' : 'bgCover'}></div>
                <ul className={isActive ? 'p-0 m-0 active' : 'p-0 m-0'}>
                    <li><NavLink to="/edit-profile" activeClassName="active"><AccountCircleIcon /> Profile</NavLink></li>
                    <li><NavLink to="/featured-items" activeClassName="active"><LocalActivityIcon /> Featured & Items</NavLink></li>
                    <li><NavLink to="/notification" activeClassName="active"><NotificationsIcon /> Notifications</NavLink></li>
                    <li><NavLink to="/offer" activeClassName="active"><LocalOfferIcon /> Offers</NavLink></li>
                    <li><NavLink to="/payment" activeClassName="active"><PaymentIcon /> Payment</NavLink></li>
                    <li><a><SecurityIcon /> Account Support</a></li>
                    <li><a><AttachMoneyIcon /> Earnings</a></li>
                </ul>
            </div>
        </>

    )

}
export default ProfileMenu;