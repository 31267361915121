import React, { } from 'react';
import './MyProfile.scss';
import { NavLink, Link } from "react-router-dom";
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import Button from 'react-bootstrap/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ItemImg from '../assets/images/item_img.jpg';
import SearchIcon from '@mui/icons-material/Search';
const MyOrder = () => {



    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-md-5 pb-md-5 pt-4 pb-4'>
                    <div className='container'>
                        <div className='mainHd mb-3 mb-md-4'>My Orders & Items</div>
                        <div className='d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center'>
                            <div className="noteText mb-3">Total Items: 4</div>
                            <div className='d-flex mb-3'>
                                <div className='search me-2'>
                                    <input type='text' className='input' placeholder='Search...' />
                                    <SearchIcon className='searchIcon' />
                                </div>
                                <div><a className='filter d-flex justify-content-center align-items-center'><FilterAltIcon /></a></div>
                            </div>
                        </div>
                        <div className='table'>
                            <table>
                                <tr>
                                    <th>Image</th>
                                    <th>Reference No.</th>
                                    <th>NFT ID</th>
                                    <th>NFT Name</th>
                                    <th>Creator</th>
                                    <th>Price</th>
                                    <th>Address</th>
                                    <th>Order Date</th>
                                    <th>Order Date</th>
                                    <th>Status</th>
                                </tr>
                                {/* <tr>
                                    <td><a><img src={ItemImg} alt='' className='itemImg' /></a></td>
                                    <td>#123456</td>
                                    <td>#123</td>
                                    <td>Luxury Watch</td>
                                    <td>Creator Name Here</td>
                                    <td>2.5 HBAR</td>
                                    <td>133 Ramblewood Ave. Olney, MD 20832</td>
                                    <td>01/01/2022</td>
                                    <td><span className='d-block expected'>Expected</span> 01/20/2022</td>
                                    <td><span className='status d-inline-block processing'>Processing</span></td>
                                </tr>
                                <tr>
                                    <td><a><img src={ItemImg} alt='' className='itemImg' /></a></td>
                                    <td>#123456</td>
                                    <td>#123</td>
                                    <td>Luxury Watch</td>
                                    <td>Creator Name Here</td>
                                    <td>2.5 HBAR</td>
                                    <td>133 Ramblewood Ave. Olney, MD 20832</td>
                                    <td>01/01/2022</td>
                                    <td>01/20/2022</td>
                                    <td><span className='status d-inline-block delivered'>Delivered</span></td>
                                </tr>
                                <tr>
                                    <td><a><img src={ItemImg} alt='' className='itemImg' /></a></td>
                                    <td>#123456</td>
                                    <td>#123</td>
                                    <td>Luxury Watch</td>
                                    <td>Creator Name Here</td>
                                    <td>2.5 HBAR</td>
                                    <td>133 Ramblewood Ave. Olney, MD 20832</td>
                                    <td>01/01/2022</td>
                                    <td>01/20/2022</td>
                                    <td><NavLink to="/checkout"><Button className='placeOrder'>Place Order</Button></NavLink></td>
                                </tr>
                                <tr>
                                    <td><a><img src={ItemImg} alt='' className='itemImg' /></a></td>
                                    <td>#123456</td>
                                    <td>#123</td>
                                    <td>Luxury Watch</td>
                                    <td>Creator Name Here</td>
                                    <td>2.5 HBAR</td>
                                    <td>133 Ramblewood Ave. Olney, MD 20832</td>
                                    <td>01/01/2022</td>
                                    <td>01/20/2022</td>
                                    <td><NavLink to="/checkout"><Button className='placeOrder'>Place Order</Button></NavLink></td>
                                </tr> */}
                            </table>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default MyOrder;