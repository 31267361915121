import React, { } from 'react';
import { NavLink, Link } from "react-router-dom";
import './Footer.scss';
import logo from '../assets/images/footer-logo.gif'
import FacebookIcon from '../assets/images/facebook.png'
import TwitterIcon from '../assets/images/twitter.png'
import InstagramIcon from '../assets/images/instagram.png'
import YoutubeIcon from '../assets/images/youtube.png'
import TelegramIcon from '../assets/images/telegram.png'
import RedditIcon from '../assets/images/reddit.png'
import LinkedinIcon from '../assets/images/linkedin.png'
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';



const Footer = () => {
    let wallet = localStorage.getItem('hashconnectData')
    // console.log(wallet,"wallet");
    return (
        <>
            <footer className='footerCon'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-5 pb-4 pb-md-5'>
                            <div className='footerLeft'>
                                <div className='footerLogo mb-3'><img alt="" src={logo} /></div>
                                <div className='footerText'>The world’s first Phygital marketplace for your own limited edition items, such as collectables and
NFTs. Buy, sell, create own and ship various exclusive real rare items.</div>
                                <div className='pt-4 pt-md-5'>
                                    <div className='communityHd pb-3'>Join The Community</div>
                                    <ul className='d-flex flex-wrap p-0 socialLink'>
                                        <li><a><img alt="" src={FacebookIcon} /></a></li>
                                        <li><a><img alt="" src={TwitterIcon} /></a></li>
                                        <li><a><img alt="" src={InstagramIcon} /></a></li>
                                        <li><a><img alt="" src={YoutubeIcon} /></a></li>
                                        <li><a><img alt="" src={TelegramIcon} /></a></li>
                                        <li><a><img alt="" src={RedditIcon} /></a></li>
                                        <li><a><img alt="" src={LinkedinIcon} /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-7 pb-4 pb-md-5 d-flex flex-wrap justify-content-between'>
                            <div className='footerColumn'>
                                <div className='footerHd mb-2'>Marketplace</div>
                                <ul className='footerLinks m-0 p-0'>
                                    <NavLink to="/product-listing" > <li><a>All NFTs</a></li></NavLink>
                                    <NavLink to="/top-collections" >  <li><a>Top</a></li></NavLink>
                                    <NavLink to="/art" >  <li><a>Art</a></li></NavLink>
                                    <NavLink to="/collectibles" >  <li><a>Collectibles</a></li></NavLink>
                                    {/* <NavLink to="/my-collections" >  <li><a>Domain Names</a></li></NavLink> */}
                                    {/* <NavLink to="/music" >  <li><a>Music</a></li></NavLink> */}
                                    <NavLink to="/photography" > <li><a>Design</a></li></NavLink> 
                                    {/* <NavLink to="/sports" >  <li><a>Sports</a></li></NavLink> */}
                                    {/* <NavLink to="/trading-cards" > <li><a>Trading Cards</a></li></NavLink> */}
                                    <NavLink to="/utility" > <li><a>Jewellery</a></li></NavLink>
                                    <NavLink to="/watches" > <li><a>Watches</a></li></NavLink>
                                    {/* <NavLink to="/watches" > <li><a>Virtual Worlds</a></li></NavLink> */}
                                </ul>
                            </div>
                            <div className='footerColumn'>
                                <div className='footerHd mb-2'>My Account</div>
                                <ul className='footerLinks m-0 p-0'>
                                <NavLink to={wallet ? "/my-profile" : "/connect-wallet"}><li><a>Profile</a></li></NavLink>
                            <NavLink to={wallet ? "/my-watchlist" : "/connect-wallet"}><li><a>Favorites</a></li></NavLink>
                            <NavLink to={wallet ? "/my-watchlist" : "/connect-wallet"}><li><a>Watchlist</a></li></NavLink>
                            <NavLink to={wallet ? "/my-collections" : "/connect-wallet"}><li><a>My Collections</a></li></NavLink>
                            <NavLink to={wallet ? "/edit-profile" : "/connect-wallet"}><li><a>Settings</a></li></NavLink>
                                </ul>
                            </div>
                            <div className='footerColumn'>
                                <div className='footerHd mb-2'>Resources</div>
                                <ul className='footerLinks m-0 p-0'>
                                    <li><NavLink to="/fractional-nft">FractionalNFT</NavLink></li>
                                    <li><NavLink to="/nft-shipment">Shipments</NavLink></li>
                                    <li><NavLink to="/help-center">Help Center</NavLink></li>
                                    <li><a>Platform Status</a></li>
                                    <li><NavLink to="/partners"><a>Partners</a></NavLink></li>
                                    <li><a>Taxes</a></li>
                                    <li><NavLink to="/blog">Blog</NavLink></li>
                                    <li><a>Docs</a></li>
                                    <li><a>Newsletter</a></li>
                                </ul>
                            </div>
                            <div className='footerColumn'>
                                <div className='footerHd mb-2'>Company</div>
                                <ul className='footerLinks m-0 p-0'>
                                    <li><NavLink to="/about">About</NavLink></li>
                                    <li><a>Careers</a></li>
                                    <li><a>Ventures</a></li>
                                    <li><a>Grants</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footerBot'>
                    <div className='container'>
                        <div className='d-flex flex-wrap justify-content-between align-items-center'>
                            <div className='copy pt-3  pt-md-4 pb-0 pb-md-4'>&copy; <div>2024 Ledex Protocol. All Rights Reserved. Designed & Developed By <a href="https://www.flexsin.com/">Flexsin Technologies</a></div></div>
                            {/* <div className='footerBR pt-3 pb-3  pt-md-4 pb-md-4'>
                                <ul className='p-0 d-flex'>
                                    <li><a><WbSunnyOutlinedIcon /></a></li>
                                    <li><a><DarkModeOutlinedIcon /></a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </footer>
        </>

    )

}
export default Footer;