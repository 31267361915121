import React, { useEffect } from 'react';
import './MyProfile.scss';
import { NavLink, Link,useNavigate } from "react-router-dom";
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import Button from 'react-bootstrap/Button';
import EmptyBox from '../assets/images/empty_box.png';
import UserImg from '../assets/images/user_img.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { getWatchList } from './redux/action/watchListAction';
import { getCollectionDetail } from './redux/action/collectionAction';

const MyWatchlist = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoding, status, message, watchList } = useSelector((state) => state.watchList)
    useEffect(() => {
        dispatch(getWatchList());
    }, [getWatchList])


    const collectionDetails = (id) => {
        dispatch(getCollectionDetail(id))
        localStorage.setItem('collectionId', JSON.stringify(id));
        navigate(`/collection-details`)
    }
    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-md-5 pb-md-5 pt-4 pb-4'>
                    <div className='container'>
                        <div className='mainHd mb-4 mb-md-5 text-center'>My Watchlist</div>
                        <div className='table'>
                            <table>
                                <tr>
                                    <th>#</th>
                                    <th>Collection</th>
                                    <th>Volume</th>
                                    <th>Floor Price</th>
                                    <th>Owners</th>
                                    <th>Items</th>
                                </tr>

                                {
                                    (watchList.data && watchList.data.length > 0) ?
                                        watchList.data.map((data, i) => (
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>
                                                    <div className='text-nowrap collection'><a  onClick={() => collectionDetails(data.collectionId)}><img alt="" src={data.profileImage} className="rounded-circle me-2" /> {data.collectionName}</a></div>
                                                </td>
                                                <td className='text-nowrap'>{data.totalVolumes}</td>
                                                <td className='text-nowrap'>{data.florPrice}</td>
                                                <td>{data.userName}</td>
                                                <td className='text-nowrap'>{data.itemCount}</td>
                                            </tr>
                                        )) : <div>
                                            <div className='text-center'><img alt="" src={EmptyBox} /></div>
                                            <div className='text-center pt-3 pb-4 noResult'>Nothing Found</div>
                                        </div>


                                }

                                {/* <tr>
                                    <td colSpan='6' className='pt-5 pb-5'>
                                        <div className='text-center'><img alt="" src={EmptyBox} /></div>
                                        <div className='text-center pt-3 pb-4 noResult'>Nothing Found</div>
                                        <div className='text-center'><NavLink to="/explore-collections" activeClassName="active"><Button type="button">Explore Collections</Button></NavLink></div>
                                    </td>
                                </tr>*/ }
                                {/* <tr>
                                    <td>1</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here</a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here</a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here</a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here</a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here</a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here</a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr> */}
                            </table>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default MyWatchlist;