import React, { } from 'react';
import './EditProfile.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import ProfileMenu from '../component/ProfileMenu';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Icon from '../assets/images/hedera_logo.png';
const Notifications = () => {



    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='d-flex flex-wrap'>
                            <ProfileMenu />
                            <div className='profileDet'>
                                <div className='mainHd mb-2 mb-md-3'>Notification Settings</div>
                                <div className='noteText mb-4'>Select which notifications you would like to receive for 0x278f...1111</div>
                                <ul className='mb-4 mb-md-5 p-0 notifiationList'>
                                    <li>
                                        <Form.Check checked className='checkbox' />
                                        <span className='d-block pb-1'>Item Sold</span>
                                        When someone purchased one of your items
                                    </li>
                                    <li>
                                        <Form.Check className='checkbox' />
                                        <span className='d-block pb-1'>Bid Activity</span>
                                        When someone bids on one of your items
                                    </li>
                                    <li>
                                        <Form.Check className='checkbox' />
                                        <span className='d-block pb-1'>Price Change</span>
                                        When an item you made an offer on changes in price
                                    </li>
                                    <li>
                                        <Form.Check className='checkbox' />
                                        <span className='d-block pb-1'>Auction Expiration</span>
                                        When a timed auction you created ends
                                    </li>
                                    <li>
                                        <Form.Check className='checkbox' />
                                        <span className='d-block pb-1'>Outbid</span>
                                        When an offer you placed is exceeded by another user
                                    </li>
                                    <li>
                                        <Form.Check className='checkbox' />
                                        <span className='d-block pb-1'>Owned Item Updates</span>
                                        When a significant update occurs for one of the items you have purchased on Ledex
                                    </li>
                                    <li>
                                        <Form.Check className='checkbox' />
                                        <span className='d-block pb-1'>Successful Purchase</span>
                                        When you successfully buy an item
                                    </li>
                                    <li>
                                        <Form.Check className='checkbox' />
                                        <span className='d-block pb-1'>OpenSea Newsletter</span>
                                        Occasional updates from the Ledex team
                                    </li>
                                </ul>
                                <div className='subHd mb-2'>Minimum Bid Threshold</div>
                                <div className='noteText mb-4'>Receive notifications only when you receive offers with a value greater than or equal to this amount of HBAR.</div>
                                <div className='mb-4 mb-md-5 currencyBox d-flex'>
                                    <div className='currency d-flex align-items-center'><span className='me-3 d-flex align-items-center justify-content-center overflow-hidden'><img alt="" src={Icon} /></span> Hedera</div>
                                    <div className='price d-flex align-items-center'>0.005</div>
                                </div>
                                <div><Button type="button">Save</Button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default Notifications;