import React, { } from 'react';
import './SubmitRequest.scss';
import HeaderSubmitRequest from '../component/HeaderSubmitRequest.js';
import Footer from '../component/Footer.js';
import AccountMenu from '../component/AccountMenu';
import Button from 'react-bootstrap/Button';
const SubmitRequest = () => {



    return (
        <>
            <div className="mainCon">
                <HeaderSubmitRequest />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='d-flex flex-wrap'>
                            <AccountMenu />
                            <div className='profileDet'>
                                <div className='requestForm'>
                                    <ul className='form p-0'>
                                        <li>
                                            <label className='fieldHd d-block pb-2 ps-1'>Your email address</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li>
                                            <label className='fieldHd d-block pb-2 ps-1'>Language</label>
                                            <div className='selectBox'>
                                                <select className='formInput'>
                                                    <option>Eng</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <label className='fieldHd d-block pb-2 ps-1'>What do you need help with?</label>
                                            <div className='selectBox'>
                                                <select className='formInput'>
                                                    <option>Select</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <label className='fieldHd d-block pb-2 ps-1'>Issue Subject</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li>
                                            <label className='fieldHd d-block pb-2 ps-1'>Ethereum/Flow Address(optional)</label>
                                            <input type="text" className='formInput' />
                                        </li>
                                        <li>
                                            <label className='fieldHd d-block pb-2 ps-1'>Please describe the problem in as much detail as possible.</label>
                                            <textarea rows="8" cols="8" className='formInput textarea'></textarea>
                                        </li>
                                        <li>
                                            <label className='fieldHd d-block pb-2 ps-1'>Attachments(optional)</label>
                                            <div className='browseBox d-flex align-items-center justify-content-center p-2'><span className='browse me-1'>Add <input type='file' className='file' /> </span> file or drop files here</div>
                                        </li>
                                        <li className='mt-2'><Button type="button">Submit</Button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default SubmitRequest;