import React, { useState } from 'react';
import './ConnectWallet.scss';
import Header from '../component/Header.js';
import walletImg from '../assets/images/Roman-watch.png';
import Hedera from '../assets/images/hedera_logo.png';
import { walletStatusUpdate } from './connectWalletRedux/connectWalletAction';
import { useDispatch, useSelector } from 'react-redux';
import {useHashConnect} from '../helpers/pairwallet.js';
import video from '../assets/lamp.mp4';
import '../component/CreateAndSell.scss';

  


const ConnectWallet = () => {
    const {
        connectToExtension,
        disconnect,
        pairingData,
        availableExtension,
        network,
        pairingString,
      } = useHashConnect();
    const dispatch = useDispatch();
    let wallet = localStorage.getItem('walletAddress')
    const { isLoding, status } = useSelector((state) => state.walletStatus)
    const daaa = useSelector((state) => state.walletStatus)

    const connectWallet = async () => {
        const saveData = await connectToExtension()
        if (pairingString) {
            dispatch(walletStatusUpdate({ walletAddress: wallet }))
        }

    }

    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='d-flex flex-wrap'>
                    <div className='walletLeft d-block d-md-flex justify-content-end align-items-center'>
                        <div className='inner'>
                            <div className='left'>
                                <div className='largeHd mb-2'>Connect Wallet</div>
                                <div className='walletText mb-4 mb-xl-5'>Incredibly fast. Predictably low fees. Finality in seconds.</div>
                                <div className='walletBox d-flex justify-content-between align-items-center mb-4 mb-xl-5'>
                                    <div className='text d-flex align-items-center'><span className='icon me-3 d-flex justify-content-center align-items-center'><img alt="" src={Hedera} /></span> Hedera Hbar</div>
                                    {/* <p id='accountid'></p> */}
                                    <p>{wallet}</p>
                                    {/* <button disabled={wallet ? true : false} className='connectBtn' onClick={async () => {
                                        const saveData = await pairHashpack()
                                        setPairingString(saveData.pairingString)
                                        dispatch(walletStatusUpdate({ walletAddress: wallet }))
                                    }}>Connect</button> */}

                                    <button disabled={wallet ? true : false} className='connectBtn' onClick={connectWallet}>Connect</button>


                                </div>

                                <div className='walletSubHd mb-3'>Create A Ledger Hedera (Hbar) Account Via Hashpack</div>
                                <ul className='walletList m-0 p-0'>
                                    <li><div>Unlock your Ledger device and open the Hedera app. Your device displays Awaiting Commands</div></li>
                                    <li><div>Go to the <a className='ms-2 me-2'> HashPack website </a> and select Launch Web App.</div></li>
                                    <li><div>Create a Main Password, Confirm the password, agree to the terms and conditions then click Next.</div></li>
                                    <li><div>Select Create New Wallet &#62; New Wallet using Ledger then click Connect. Connect Ledger appears on your screen.</div></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className='walletRight' style={{ backgroundImage: 'url(' + walletImg + ')', backgroundPosition: 'center', backgroundSize: 'cover' }}></div> */}

                    {<div className='walletRight' style={{backgroundPosition: 'center', backgroundSize: 'cover' }}>
                    <video width="100%" height="auto" loop autoPlay muted>
                                        <source src={video} type="video/mp4" />
                                    </video>
                        
                        </div>}



                    {/* <div className="csRight text-center" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1200">
                            <div className="lampVideo">
                                <div className="videoInner">
                                    <video width="100%" height="auto" loop autoPlay muted>
                                        <source src={video} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
				</div> */}
                </div>
            </div>
        </>

    )

}
export default ConnectWallet;