import React, { } from 'react';
import { NavLink, Link } from "react-router-dom";
import './Admin.scss';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import Button from 'react-bootstrap/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const SupportTicketsDetail = () => {

    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className='mb-3 d-flex align-items-center'>
                            <div className='me-3'><NavLink to="/support-tickets"><Button className='backBtn d-flex align-items-center'><KeyboardBackspaceIcon className='me-1' /> Back</Button></NavLink></div>
                            <div className="dbMainHd">Support Ticket Details</div>
                        </div>
                        <div className='supportTicketDet p-3 p-md-4 rounded-3'>
                            <div className='d-flex mb-3'>
                                <Button className='me-3 closeTicket'>Close Ticket</Button>
                                <Button>Send Email</Button>
                            </div>
                            <ul className='p-0 m-0'>
                                <li className='d-flex pt-3 pb-3'>
                                    <span className='left me-3'>ID:</span>
                                    <span className='right'>#123456</span>
                                </li>
                                <li className='d-flex pt-3 pb-3'>
                                    <span className='left me-3'>Requester:</span>
                                    <span className='right'>Javon Terry</span>
                                </li>
                                <li className='d-flex pt-3 pb-3'>
                                    <span className='left me-3'>Subject:</span>
                                    <span className='right'>Transaction Issue</span>
                                </li>
                                <li className='d-flex pt-3 pb-3'>
                                    <span className='left me-3'>Agent:</span>
                                    <span className='right'>Charlie Fernandez</span>
                                </li>
                                <li className='d-flex pt-3 pb-3'>
                                    <span className='left me-3'>Status:</span>
                                    <span className='right'>Open</span>
                                </li>
                                <li className='d-flex pt-3 pb-3'>
                                    <span className='left me-3'>Create Date & Time:</span>
                                    <span className='right'>8/8/2022 l 11:25AM</span>
                                </li>
                                <li className='d-flex pt-3 pb-3'>
                                    <span className='left me-3'>Attachment:</span>
                                    <span className='right'>payment issue.jpg</span>
                                </li>
                                <li className='d-flex pt-3 pb-3'>
                                    <span className='left me-3'>Email Address:</span>
                                    <span className='right'>Javon_terry@email.com</span>
                                </li>
                                <li className='d-flex pt-3 pb-3 message'>
                                    <span className='left me-3'>Message:</span>
                                    <span className='right'>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's stan dard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.<br /><br />
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's stan dard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default SupportTicketsDetail;