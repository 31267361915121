import React from "react";
import './CreateAndSell.scss';
import SellImg from '../assets/images/sell_img.png';
import Step1 from '../assets/images/step1.png';
import Step2 from '../assets/images/step2.png';
import Step3 from '../assets/images/step3.png';
import Step4 from '../assets/images/step4.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import video from '../assets/lamp.mp4';

const CreateAndSell = () => {


    AOS.init({
        once: true,
        duration: 1200,
    });
    return (
        <>
            <div className='pb-large overflow-hidden'>
                <div className="container">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div className="csLeft">
                            <div className='mainHd mb-4' data-aos="fade-down">Create And Sell NFTs</div>
                            <ul className="p-0 m-0 csList">
                                <li data-aos="fade-right" data-aos-easing="linear" data-aos-duration="600">
                                    <span className="number">1</span>
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="img text-start text-sm-center"><img alt="" src={Step1} /></div>
                                        <div className="det">
                                            <span className="hd">Set up your wallet</span>
                                            Connect your wallet (Hashgraph) and enjoy the infinite rare collection of LX
                                        </div>
                                    </div>
                                </li>
                                <li data-aos="fade-right" data-aos-easing="linear" data-aos-duration="800">
                                    <span className="number">2</span>
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="img text-start text-sm-center"><img alt="" src={Step2} /></div>
                                        <div className="det">
                                            <span className="hd">Create your collection</span>
                                            Mint and list physical goods within the digital world. Welcome to the phygital era
                                        </div>
                                    </div>
                                </li>
                                <li data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">
                                    <span className="number">3</span>
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="img text-start text-sm-center"><img alt="" src={Step3} /></div>
                                        <div className="det">
                                            <span className="hd">Add your NFTs</span>
                                            List, trade, and collect your Pieces and many more with the new feature of Fractionalization of the good.
                                        </div>
                                    </div>
                                </li>
                                <li data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1200">
                                    <span className="number">4</span>
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="img text-start text-sm-center"><img alt="" src={Step4} /></div>
                                        <div className="det">
                                            <span className="hd">List them for sale</span>
                                            List your pieces and start your own auction, whether fractionalized or not
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="csRight text-center" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1200">
                            <div className="lampVideo">
                                <div className="videoInner">
                                    <video width="100%" height="auto" loop autoPlay muted>
                                        <source src={video} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default CreateAndSell;