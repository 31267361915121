import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './Admin.scss';
import Switch from '@mui/material/Switch';
import { ToastContainer, toast } from 'react-toastify';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import SearchIcon from '@mui/icons-material/Search';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-js-pagination';
import { useSelector, useDispatch } from 'react-redux';
import { getCollectionList, updateAuth, collectionDetail } from '../redux/action/adminUserAction';
const label = { inputProps: { 'aria-label': 'Switch demo' } };
const AdminFunction = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [serach, setSearch] = useState("")
    const { collectionList, status } = useSelector((state) => state.adminUserDetail)

    const adminFunDetail = (id) => {
        localStorage.setItem('collectionId', JSON.stringify(id));
        navigate(`/admin-function/detail`)
    }

    useEffect(() => {
        let obj = { page: currentPage, search: serach }
        dispatch(getCollectionList(obj));
    }, [currentPage])

    const handleAuthentication = (collectionId) => {
        dispatch(updateAuth(collectionId)).then(res => {
            if (res.payload.code == 200) {
                dispatch(getCollectionList());
                toast.success(res.payload.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.payload.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })


    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setSearch(value)
        let obj={page:currentPage,search:value}
        dispatch(getCollectionList(obj))
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        // updateState({
        //     ...iState, [name]: value,
        //     updatedData: {
        //         ...iState.updatedData, [name]: value
        //     }
        // })
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

    }


    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className='mb-3 d-flex align-items-center'>

                            <div className="dbMainHd">Air Drop</div>
                        </div>
                        <div className='mb-3 userDetBox d-block d-xl-flex justify-content-between'>
                            <div className='left d-flex align-items-center me-2'>
                                <div className='dbRight'>

                                    <div className='settingForm p-3 p-md-4 mb-4 rounded-3'>
                                        <ul className='form p-0'>
                                            <li>
                                                <label className='fieldHd d-block pb-2 ps-1'>Account Address</label>
                                                <input type="text" className='formInput' name="password" onChange={handleInputChange} />

                                            </li>
                                            <li>
                                                <label className='fieldHd d-block pb-2 ps-1'>Token Id</label>
                                                <input type="text" className='formInput' name="newPassword" onChange={handleInputChange} />

                                            </li>

                                        </ul>
                                    </div>
                                    <div><Button type="button" onClick={handleSubmit}>Save</Button></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center'>
                            <div className="dbMainHd d-flex align-items-center mb-3">Collections</div>
                            <div className='d-flex mb-3'>
                                <div className='search me-2'>
                                    <input type='text' className='input' name='search' value={serach} onChange={handleChange} placeholder='Search...' />
                                    <SearchIcon className='searchIcon' />
                                </div>

                            </div>
                        </div>

                        <div className='dbTable rounded-3'>
                            <table>
                                <tr>
                                    <th>Image</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Creator</th>
                                    <th>Authentication</th>
                                    <th>Action</th>
                                </tr>


                                {
                                    (collectionList.data && collectionList.data.collectionList.length > 0) ?
                                        collectionList.data.collectionList.map((data, i) => (

                                            <tr className='cursor-pointer'>
                                                <td><img src={data.profileImage} alt='' className='itemImg' onClick={() => adminFunDetail(data._id)} /></td>
                                                <td onClick={() => adminFunDetail(data._id)}>{data._id}</td>
                                                <td onClick={() => adminFunDetail(data._id)}>{data.collectionName}</td>
                                                <td onClick={() => adminFunDetail(data._id)}>{data.userName}</td>
                                                <td>{data.authentication}</td>
                                                {/* <td> <Link onClick={() => handleAuthentication(data._id)}> <div className='ms-2 toggleBtn'><Form.Check type="switch" id="custom-switch2" label="" /></div></Link>
                                                </td> */}
                                                <td> <div className='ms-2 toggleBtn' onClick={() => handleAuthentication(data._id)}> <Switch {...label} defaultChecked={data.authentication == 'enable' ? true : false} /></div>

                                                </td>

                                            </tr>

                                        )) : ""


                                }
                            </table>
                        </div>
                        <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={collectionList.data && collectionList.data.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                </div>
            </div>
        </>

    )

}

export default AdminFunction;