import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import Dashboard from '../assets/images/dashboard.png';
import User from '../assets/images/profile.png';
import Nft from '../assets/images/nft_icon.png';
import Ticket from '../assets/images/ticket.png';
import CollectibleNft from '../assets/images/collectible_nft.png';
import Logout from '../assets/images/logout_icon.png'
import Settings from '../assets/images/settings.png';
import { adminLogout } from '../api/adminApi';


const AdminMenu = () => {
    const navigate = useNavigate()
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    const logOut = () => {
        adminLogout();
        localStorage.removeItem('accessJWT');
        navigate('/login')
    };


    return (
        <>
            <div><a className={isActive ? 'showdbMenu active' : 'showdbMenu'}
                onClick={toggleClass} ><span><i></i><i></i><i></i></span></a></div>
            <div onClick={toggleClass} className={isActive ? 'dbMenuBg active' : 'dbMenuBg'}></div>
            <div className={isActive ? 'dbMenu active' : 'dbMenu'}>
                <ul className='p-0 m-0'>
                    <li><NavLink to="/dashboard" activeClassName="active"><img src={Dashboard} alt='' className='me-2' /> Dashboard</NavLink></li>
                    <li><NavLink to="/users" activeClassName="active"><img src={User} alt='' className='me-2' /> Users</NavLink></li>
                    <li><NavLink to="/nft" activeClassName="active"><img src={Nft} alt='' className='me-2' /> NFTs</NavLink></li>
                    <li><NavLink to="/approval-nft" activeClassName="active"><img src={Nft} alt='' className='me-2' /> NFTs Approval</NavLink></li>
                    <li><NavLink to="/best-limitd-edition" activeClassName="active"><img src={Nft} alt='' className='me-2' /> Best Limited Editions</NavLink></li>
                    <li><NavLink to="/support-tickets" activeClassName="active"><img src={Ticket} alt='' className='me-2' /> Support Tickets</NavLink></li>
                    <li><NavLink to="/collectible-nft" activeClassName="active"><img src={CollectibleNft} alt='' className='me-2' /> Collectible NFT</NavLink></li>
                    <li><NavLink to="/admin-function" activeClassName="active"><img src={CollectibleNft} alt='' className='me-2' />Admin Function</NavLink></li>
                    <li><NavLink to="/edit-admin-profile" activeClassName="active"><img src={User} alt='' className='me-2' /> Profile</NavLink></li>
                    <li><NavLink to="/settings" activeClassName="active"><img src={Settings} alt='' className='me-2' /> Settings</NavLink></li>
                    <li><a onClick={logOut} activeClassName="active"><img src={Logout} alt='' className='me-2' /> Logout</a></li>
                </ul>
            </div>
        </>

    )

}
export default AdminMenu;