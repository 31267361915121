import React, { useState, useEffect } from 'react';
import './MyProfile.scss';
import { NavLink, Link } from "react-router-dom";
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import bannerImg from '../assets/images/watch-banner.jpg';
import UserImg from '../assets/images/watch_img2.png';
import HBar from '../assets/images/hedera_logo.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TwitterIcon from '../assets/images/twitter.png';
import TelegramIcon from '../assets/images/telegram.png';
import RedditIcon from '../assets/images/reddit.png';
import Interneted from '../assets/images/internetd.png';
import Share from '../assets/images/share.png';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import OnSale from '../component/OnSale.js';
import Created from '../component/created';
import Favorite from '../component/Favorite.js';
import Collections from '../component/Collections.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector, useDispatch } from 'react-redux';
import { getNftDetail } from './redux/action/nftAction';
import { getUserDetail } from './redux/action/userAction';


const CreatorPage = (props) => {
    const dispatch = useDispatch();
    const { nftData } = useSelector((state) => state.nftItemDetail)
    const { profileData } = useSelector((state) => state.userDetail)
    const TabSelected = localStorage.getItem("TabIndex")
    console.log(TabSelected, 'nftData111------>', nftData)

    useEffect(() => {
        dispatch(getUserDetail());
    }, [getUserDetail])

    return (
        <>
            <div className="mainCon">
                <Header />
                <div>
                    <div className='profileBanner' style={{ backgroundImage: 'url(' +profileData && profileData.data && profileData.data.coverImg + ')', backgroundPosition: 'center' }}><img alt="" src="" /></div>
                    <div className='container'>
                        <div className='d-flex flex-column flex-lg-row justify-content-between profileTop'>
                            <div className='left'>
                                <div className='userImg'><img alt="" src={profileData.data && profileData.data.profileImg} /></div>
                                <div className='userName pt-2'>{profileData.data && profileData.data.userName}<CheckCircleIcon className='check ms-1' /></div>
                                <div className='d-flex align-items-center'>
                                    {/* <div className='me-3 mt-3'><span className='grayClr'>Created by</span> {nftData.data[0].userName}</div> */}
                                    <div className='d-inline-flex align-items-center mt-3 userId position-relative'>
                                        <span className='bar d-block text-center'><img alt="" src={HBar} /></span>
                                        <span className='d-block code ps-1 pe-2'>{profileData.data && profileData.data.walletAddress}</span>
                                        <span className='copy pe-3'><a><ContentCopyIcon /></a></span>
                                    </div>
                                </div>
                                {/* <div className='pt-3 follow'>604 <span>followers</span> <span className='ms-1 me-1'>&bull;</span> 5 <span>following</span></div> */}

                            </div>
                            <div className='right'>
                                <ul className='d-flex flex-wrap p-0 profileSocial'>
                                    <li><a><img alt="" src={TwitterIcon} /></a></li>
                                    <li><a><img alt="" src={TelegramIcon} /></a></li>
                                    <li><a><img alt="" src={RedditIcon} /></a></li>
                                    <li><a><img alt="" src={Interneted} /></a></li>
                                    {/* <li className='follow'><a>+ Follow</a></li>
                                    <li className='share'><a><img alt="" src={Share} /></a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Tab.Container defaultActiveKey={TabSelected !== "" ? TabSelected : ""}>
                        <div className='topCon'>
                            <div className='container'>
                                <Nav className='d-flex m-0 ps-0 pt-4 tabs' defaultActiveKey="Created">
                                    <Nav.Link eventKey="Created" class={TabSelected === "Created" ? "active" : ""} >Created</Nav.Link>
                                    <Nav.Link eventKey="OnSale">On Sale</Nav.Link>
                                    <Nav.Link eventKey="Owned">Owned</Nav.Link>
                                    <Nav.Link eventKey="Collections">Collections</Nav.Link>
                                    <Nav.Link eventKey="Favorite" class={TabSelected === "Favorite" ? "active" : ""}>Favorite</Nav.Link>
                                    {/* <Nav.Link eventKey="Activity">Activity</Nav.Link> */}
                                </Nav>
                            </div>
                        </div>
                        <div className='pt-md-5 pb-md-5 pt-4 pb-4'>
                            <div className='container'>
                                <Tab.Content>
                                    <Tab.Pane eventKey="OnSale">
                                        <OnSale />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Created">
                                        <Created />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Owned">
                                        Owned Detail Goes Here
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Collections">
                                        <Collections />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Favorite">
                                        <Favorite />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Activity">
                                        Activity Detail Goes Here
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default CreatorPage;