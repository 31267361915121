import {
    Client,
    ContractFunctionParameters,
    ContractExecuteTransaction,
    ContractId,
} from '@hashgraph/sdk';
import axios from 'axios';
import { hashConnect } from './pairwallet';
//const auctionSC = ContractId.fromString('0.0.456391')
const marketplacecontract = ContractId.fromSolidityAddress('0000000000000000000000000000000000368549')
const auctionSC = ContractId.fromSolidityAddress('000000000000000000000000000000000032cdf0')

export const createCollectionContract = async(CollectibleType) =>{
    console.log('CollectibleType-->',CollectibleType)
    const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
        console.log(hashconnectData)
        const provider = hashConnect.getProvider('testnet', hashconnectData.topic , hashconnectData.pairingData[0].accountIds[0])
        console.log(provider, "provider log");
        const signer = hashConnect.getSigner(provider)
        console.log(signer,"signer ssssssssssssss");
        const hexaddr = signer.getAccountId();
        const creatorAddress = hexaddr.toSolidityAddress();

        let sendHbarTx = await new ContractExecuteTransaction()
        .setContractId(marketplacecontract)
        .setGas(10000000)
        .setFunction("createNftContract", new ContractFunctionParameters().addString("Test protocol").addString("").addBool(CollectibleType)) //param
        .freezeWithSigner(signer)
        sendHbarTx = await sendHbarTx.signWithSigner(signer);
        const tx =await sendHbarTx.executeWithSigner(signer)
        const rex = await provider.getTransactionReceipt(tx.transactionId);
        let tid =tx.transactionId
        const stid=tid.split("@")
        tid=stid[0]+"-"+stid[1].replace(".","-")
        console.log(tx, "txxxxxxxxxxxxxx");
        const client = Client.forTestnet()
      
           if (rex) {
        var promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                let smartContract;
                axios.get(`https://testnet.mirrornode.hedera.com/api/v1/contracts/results/${tid}`).then((response) => {
                     smartContract=response.data.logs[0].address
                    resolve(smartContract)
                })
            }, 10000);
        })
        return promise

    }
    
}

export const Mint = async (uploadImg, contractId,tokenId) => {
    const read = contractId
    console.log(read,"read")
    if (read) {
        const nftcontract = ContractId.fromSolidityAddress(read)
        const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
        const provider = hashConnect.getProvider('testnet', hashconnectData.topic, hashconnectData.pairingData[0].accountIds[0])
        const signer = hashConnect.getSigner(provider)
        const hexaddr = signer.getAccountId();
        const userAddress = hexaddr.toSolidityAddress();

        console.log(tokenId, uploadImg, contractId, ">>>>>>")
         const sendHbarTx = await new ContractExecuteTransaction()
            .setContractId(nftcontract)
            .setGas(1000000)
            .setFunction('Mint', new ContractFunctionParameters().addAddress(userAddress).addUint256(tokenId).addString(uploadImg)).freezeWithSigner(signer);
        const tx = await sendHbarTx.executeWithSigner(signer)
        return tx
    } else {
        console.log("error")
    }
}


var approvalForAll = async(x,tokenId,tokenAdd) =>{
    const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
        const provider = hashConnect.getProvider('testnet', hashconnectData.topic , hashconnectData.pairingData[0].accountIds[0])
        const signer = hashConnect.getSigner(provider)
        const nftaddr=ContractId.fromSolidityAddress(tokenAdd)
        const contractAddress =(x).toSolidityAddress();
        const sendHbarTx = await new ContractExecuteTransaction()
        .setContractId(nftaddr)
        .setGas(10000000)
        .setFunction("approve", new ContractFunctionParameters().addAddress(contractAddress).addUint256(tokenId)) //param
        .freezeWithSigner(signer);

        const tx =await sendHbarTx.executeWithSigner(signer)
       return tx
}

export const sellNft = async (sellId,tokenId,tokenAdd,amount) => {
   
let saleAmount=(amount*100000000)
let appr=await approvalForAll(marketplacecontract,tokenId,tokenAdd)
if(appr){
 const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
    const provider = hashConnect.getProvider('testnet', hashconnectData.topic, hashconnectData.pairingData[0].accountIds[0])
    const signer = hashConnect.getSigner(provider)
    const nftAddress=ContractId.fromSolidityAddress(tokenAdd)
    const nftEvmAdd=nftAddress.toSolidityAddress()
    const sendHbarTx = await new ContractExecuteTransaction()
        .setContractId(marketplacecontract)
        .setGas(10000000)
        .setPayableAmount(1)
        .setFunction("createMarketItem", new ContractFunctionParameters().addAddress(nftEvmAdd).addUint256(tokenId).addUint256(saleAmount).addUint256(sellId)) //param
        .freezeWithSigner(signer);

    const tx = await sendHbarTx.executeWithSigner(signer)
    console.log(tx, "txxxxxxxxxxxxxx");
    return tx
}

}
export const buyNft = async(saletokenId,amount) =>{
    const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
        const provider = hashConnect.getProvider('testnet', hashconnectData.topic , hashconnectData.pairingData[0].accountIds[0])
        const signer = hashConnect.getSigner(provider)
        const createHbarTx = await new ContractExecuteTransaction()
        .setContractId(marketplacecontract)
        .setGas(10000000)
        .setPayableAmount(amount)
        .setFunction("createMarketSale", new ContractFunctionParameters().addUint256(saletokenId))
        .freezeWithSigner(signer);

        const tx = await createHbarTx.executeWithSigner(signer)
        console.log(tx, "Txxxx");
        return tx
}
export const startAuction = async(auctionId,tokenId,tokenAdd,AuctionAmount,timeDuration) => {
    let AuctionAmounts=(AuctionAmount*100000000)
    let appr=await approvalForAll(auctionSC,tokenId,tokenAdd)
    if(appr){
        const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
        console.log(hashconnectData)
        const provider = hashConnect.getProvider('testnet', hashconnectData.topic , hashconnectData.pairingData[0].accountIds[0])
        console.log(provider, "provider log");
        const signer = hashConnect.getSigner(provider)
        console.log(signer,"signer ssssssssssssss");
        const nftcontract=ContractId.fromSolidityAddress(tokenAdd)
        const contractAddress = nftcontract.toSolidityAddress();
    
        const sendHbarTx = await new ContractExecuteTransaction()
            .setContractId(auctionSC)
            .setGas(10000000)
            .setFunction("startEnglishAuction", new ContractFunctionParameters().addAddressArray([contractAddress]).addUint256Array([tokenId]).addUint256(AuctionAmounts).addUint256(timeDuration).addUint256(auctionId)) //param
            .freezeWithSigner(signer);
    
            const tx =await sendHbarTx.executeWithSigner(signer)
            console.log(tx, "txxxxxxxxxxxxxx");
            return tx
    }
   
}
export const placeBidInAuction = async(auctionTokenId,bidAmount) => {
    let amounts=(bidAmount*100000000)
    const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
        console.log(hashconnectData)
        const provider = hashConnect.getProvider('testnet', hashconnectData.topic , hashconnectData.pairingData[0].accountIds[0])
        console.log(provider, "provider log");
        const signer = hashConnect.getSigner(provider)
        console.log(signer,"signer ssssssssssssss");
    
        const sendHbarTx = await new ContractExecuteTransaction()
        .setContractId(auctionSC)
        .setGas(10000000)
        .setPayableAmount(bidAmount)
        .setFunction("placeBidInEnglishAuction", new ContractFunctionParameters().addUint256(auctionTokenId).addUint256(amounts)) //param
        .freezeWithSigner(signer);

        const tx =await sendHbarTx.executeWithSigner(signer)
        console.log(tx, "txxxxxxxxxxxxxx");
        return tx
}

export const claimFundsFromEnglishAuction = async(autionId) =>{
    const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
        console.log(hashconnectData)
        const provider = hashConnect.getProvider('testnet', hashconnectData.topic , hashconnectData.pairingData[0].accountIds[0])
        console.log(provider, "provider log");
        const signer = hashConnect.getSigner(provider)
        console.log(signer,"signer ssssssssssssss");
    
        const sendHbarTx = await new ContractExecuteTransaction()
        .setContractId(auctionSC)
        .setGas(10000000)
        .setFunction("claimFundsFromEnglishAuction", new ContractFunctionParameters().addUint256(autionId)) //param
        .freezeWithSigner(signer);

        const tx =await sendHbarTx.executeWithSigner(signer)
        console.log(tx, "txxxxxxxxxxxxxx");
}

export const claimNftFromEnglishAuction = async(autionId) =>{
    const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
        console.log(hashconnectData)
        const provider = hashConnect.getProvider('testnet', hashconnectData.topic , hashconnectData.pairingData[0].accountIds[0])
        console.log(provider, "provider log");
        const signer = hashConnect.getSigner(provider)
        console.log(signer,"signer ssssssssssssss");
    
        const sendHbarTx = await new ContractExecuteTransaction()
        .setContractId(auctionSC)
        .setGas(10000000)
        .setFunction("claimNftFromEnglishAuction", new ContractFunctionParameters().addUint256(autionId)) //param
        .freezeWithSigner(signer);

        const tx =await sendHbarTx.executeWithSigner(signer)
        console.log(tx, "txxxxxxxxxxxxxx");
        return tx
}

export const cancelAuction = async(auctionTokenId) =>{
    const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
        console.log(hashconnectData)
        const provider = hashConnect.getProvider('testnet', hashconnectData.topic , hashconnectData.pairingData[0].accountIds[0])
        console.log(provider, "provider log");
        const signer = hashConnect.getSigner(provider)
        console.log(signer,"signer ssssssssssssss");
    
        const sendHbarTx = await new ContractExecuteTransaction()
        .setContractId(auctionSC)
        .setGas(10000000)
        .setFunction("cancelAuction", new ContractFunctionParameters().addUint256(auctionTokenId)) //param
        .freezeWithSigner(signer);

        const tx =await sendHbarTx.executeWithSigner(signer)
        console.log(tx, "txxxxxxxxxxxxxx");
}

export const withdrawAuctionBalance = async(auctionTokenId) =>{
    const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
        console.log(hashconnectData)
        const provider = hashConnect.getProvider('testnet', hashconnectData.topic , hashconnectData.pairingData[0].accountIds[0])
        console.log(provider, "provider log");
        const signer = hashConnect.getSigner(provider)
        console.log(signer,"signer ssssssssssssss");
    
        const sendHbarTx = await new ContractExecuteTransaction()
        .setContractId(auctionSC)
        .setGas(10000000)
        .setFunction("withdrawAuctionBalance", new ContractFunctionParameters().addUint256(auctionTokenId)) //param
        .freezeWithSigner(signer);

        const tx =await sendHbarTx.executeWithSigner(signer)
        console.log(tx, "txxxxxxxxxxxxxx");
}

// export const withdrawContractBalance = async() =>{
//     const hashconnectData = JSON.parse(window.localStorage.hashconnectData)
//         console.log(hashconnectData)
//         const provider = hashConnect.getProvider('testnet', hashconnectData.topic , hashconnectData.pairingData[0].accountIds[0])
//         console.log(provider, "provider log");
//         const signer = hashConnect.getSigner(provider)
//         console.log(signer,"signer ssssssssssssss");
    
//         const sendHbarTx = await new ContractExecuteTransaction()
//         .setContractId(auctionSC)
//         .setGas(10000000)
//         .setFunction("withdrawContractBalance", new ContractFunctionParameters().addUint256("1")) //param
//         .freezeWithSigner(signer);

//         const tx =await sendHbarTx.executeWithSigner(signer)
//         console.log(tx, "txxxxxxxxxxxxxx");
// }




