import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Slider from "react-slick";
import './TopCollections.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TC_img1 from '../assets/images/top_collections_img1.jpg';
import TC_img2 from '../assets/images/top_collections_img2.jpg';
import TC_img3 from '../assets/images/top_collections_img3.jpg';
import TC_img4 from '../assets/images/top_collections_img4.jpg';
import { getCollectionDetail, getTopCollectionsList } from '../pages/redux/action/collectionAction';
import AOS from 'aos';
import 'aos/dist/aos.css';


const TopCollections = () => {
   
    AOS.init({
        once: true,
        duration: 1200,
        });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { topCollectionList } = useSelector((state) => state.collectionDetail)

    useEffect(() => {
        dispatch(getTopCollectionsList());
    }, [getTopCollectionsList])



    const collectionDetails = (id) => {
        dispatch(getCollectionDetail(id))
        localStorage.setItem('collectionId', JSON.stringify(id));
        navigate(`/collection-details`)
    }
    const filterRecord=(query)=>{
        dispatch(getTopCollectionsList(query));
     }

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <>
            <div className='pb-large overflow-hidden'>
                <div className="container">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <div className='mainHd mb-4'  data-aos="fade-right">Top Collections</div>
                        <ul className="d-flex mb-4 dayTab"  data-aos="fade-left">
                            <li><a onClick={()=>filterRecord('Day')} className="active">1 day</a></li>
                            <li><a onClick={()=>filterRecord('Week')}>7 day</a></li>
                            <li><a onClick={()=>filterRecord('Month')}>30 day</a></li>
                        </ul>
                    </div>
                    <Slider  data-aos="fade-up" className="topCollectoion bottomArrow" {...settings}>



                        {
                            (topCollectionList && topCollectionList.data && topCollectionList.data.length > 0) ?
                                topCollectionList.data.map((data, i) => (

                                    <div className="item text-center" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500" onClick={() => collectionDetails(data.collectionId)}>
                                        <div className="img mb-4"><a><img alt="" src={data.profileImage} /></a></div>
                                        <div className="name mb-2"><a>{data.collectionName}</a></div>
                                        {/* <div className="text mb-3">15.85 HBAR</div> */}
                                        <div className="price mb-1">Floor: {data.florPrice}USD</div>
                                        <div className="price mb-1">${data.totalVolumes} - {data.florPrice}</div>
                                    </div>


                                )) : <div>
                                    <div className='text-center pt-3 pb-4 noResult'>Nothing Found</div>
                                </div>


                        }



                        {/* <div className="item text-center">
                            <div className="img mb-4"><a><img alt="" src={TC_img2} /></a></div>
                            <div className="name mb-2"><a>Art Blocks Factory</a></div>
                            <div className="text mb-3">15.85 HBAR</div>
                            <div className="price mb-1">Floor: 15.85 HBAR</div>
                            <div className="price mb-1">$318K - 33.5K</div>
                        </div>
                        <div className="item text-center">
                            <div className="img mb-4"><a><img alt="" src={TC_img3} /></a></div>
                            <div className="name mb-2"><a>Art Blocks Factory</a></div>
                            <div className="text mb-3">15.85 HBAR</div>
                            <div className="price mb-1">Floor: 15.85 HBAR</div>
                            <div className="price mb-1">$318K - 33.5K</div>
                        </div>
                        <div className="item text-center">
                            <div className="img mb-4"><a><img alt="" src={TC_img4} /></a></div>
                            <div className="name mb-2"><a>Art Blocks Factory</a></div>
                            <div className="text mb-3">15.85 HBAR</div>
                            <div className="price mb-1">Floor: 15.85 HBAR</div>
                            <div className="price mb-1">$318K - 33.5K</div>
                        </div>
                        <div className="item text-center">
                            <div className="img mb-4"><a><img alt="" src={TC_img1} /></a></div>
                            <div className="name mb-2"><a>Art Blocks Factory</a></div>
                            <div className="text mb-3">15.85 HBAR</div>
                            <div className="price mb-1">Floor: 15.85 HBAR</div>
                            <div className="price mb-1">$318K - 33.5K</div>
                        </div>
                        <div className="item text-center">
                            <div className="img mb-4"><a><img alt="" src={TC_img2} /></a></div>
                            <div className="name mb-2"><a>Art Blocks Factory</a></div>
                            <div className="text mb-3">15.85 HBAR</div>
                            <div className="price mb-1">Floor: 15.85 HBAR</div>
                            <div className="price mb-1">$318K - 33.5K</div>
                        </div> */}
                    </Slider>
                    <div className="mt-4" data-aos="fade-up"><NavLink to="/top-collections" className="btn btn-primary">See All Collections</NavLink></div>
                </div>
            </div>
        </>

    )

}
export default TopCollections;