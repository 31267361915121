import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import './ProductListing.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import rotate from '../assets/images/rotate.png';
import watchImg1 from '../assets/images/watch_img1.png';
import watchImg2 from '../assets/images/watch_img2.png';
import watchImg3 from '../assets/images/watch_img3.png';
import watchImg4 from '../assets/images/watch_img4.png';
import watchImg5 from '../assets/images/watch_img5.png';
import watchImg6 from '../assets/images/watch_img6.png';
import watchImg7 from '../assets/images/watch_img7.png';
import watchImg8 from '../assets/images/watch_img8.png';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import { useSelector, useDispatch } from 'react-redux';
import { getUserNftList, getNftDetail } from './redux/action/nftAction'
import { getFavoriteDetail } from './redux/action/favouriteAction';
import { getCollectionsList } from './redux/action/collectionAction';

const initialState = {
    type: '',
    category: '',
    minPrice: '',
    maxPrice: '',
    search: ''
}

const ProductListing = () => {
    const [isActive, setActive] = useState(false);
    const [checked, setChecked] = useState(false);

    const [catOpt, setCatOpt] = useState([])
    const [typeOpt, setTypeOpt] = useState([])
    const [statusOpt, setStatusOpt] = useState([])
    const [collectionOpt, setCollectionOpt] = useState([])
    const [noncollcheck, setNoncollcheck] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [iState, updateState] = useState(initialState)
    const { nftUserList } = useSelector((state) => state.nftItemDetail)
    const { collectionList } = useSelector((state) => state.collectionDetail)

    const {
        type,
        category,
        minPrice,
        maxPrice,
        search
    } = iState

    useEffect(() => {
        dispatch(getCollectionsList());
    }, [getCollectionsList])


    useEffect(() => {
        dispatch(getUserNftList());
    }, [getUserNftList])

    const toggleClass = () => {
        setActive(!isActive);
    };

    const nftDetail = (id) => {
        //dispatch(getNftDetail(id))
        dispatch(getFavoriteDetail({ nftItemId: id }))
        localStorage.setItem('nftItemId', JSON.stringify(id));
        navigate(`/product-detail`)
    }

    const handleSearch = (e) => {
        const { value, name } = e.target
        updateState({
            ...iState, [name]: value,

        })
        dispatch(getUserNftList({ search: value }));

    }


    const handleChecked = (e) => {
        const { value, checked } = e.target
        if (checked) {
            let catUpdate = catOpt
            catUpdate.push(value)
            setCatOpt(catUpdate)
            updateState({
                ...iState, category: catOpt
            })
            dispatch(getUserNftList({ category: catUpdate }));
        } else {
            setCatOpt(catOpt.filter((e) => e !== value))
            dispatch(getUserNftList());
        }
    }

    const handleInputChangeCollectible = (e) => {
        const { value, checked } = e.target
        if (checked) {
            let typeUpdate = typeOpt
            typeUpdate.push(value)
            setTypeOpt(typeUpdate)
            updateState({
                ...iState, type: typeOpt
            })
            dispatch(getUserNftList({ type: typeUpdate }));
        } else {
            setCatOpt(typeOpt.filter((e) => e !== value))
            dispatch(getUserNftList());
        }


    }

    const handleInputChangeStatus = (e) => {
        const { value, checked } = e.target
        if (checked) {
            let statusUpdate = statusOpt
            statusUpdate.push(value)
            setStatusOpt(statusUpdate)
            updateState({
                ...iState, type: statusOpt
            })
            dispatch(getUserNftList({ status: statusUpdate }));
        } else {
            setCatOpt(statusOpt.filter((e) => e !== value))
            dispatch(getUserNftList());
        }
    }

    const handleInputChangeMinPrice = (e) => {
        const { value, name } = e.target
        updateState({
            ...iState, [name]: value,

        })

    }
    const handleInputChangeMaxPrice = (e) => {
        const { value, name } = e.target
        updateState({
            ...iState, [name]: value,

        })
        dispatch(getUserNftList({ minPrice: minPrice, maxPrice: value }));

    }


    const handleInputChangeCollection = (e) => {
        const { value, checked } = e.target
        if (checked) {
            let collectionUpdate = collectionOpt
            collectionUpdate.push(value)
            setCollectionOpt(collectionUpdate)
            updateState({
                ...iState, type: collectionOpt
            })
            dispatch(getUserNftList({ collection: collectionUpdate }));
        } else {
            setCatOpt(collectionOpt.filter((e) => e !== value))
            dispatch(getUserNftList());
        }


    }
    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='mainHd mb-3 mb-md-4'>Explore Matketplace</div>
                        <div className='d-flex flex-wrap align-items-center listingTop'>
                            <div className='mb-3 search'>
                                <input type='text' className='input' placeholder='Search by name' name="search" value={search} onChange={handleSearch} />
                                <SearchIcon className='searchIcon' />
                            </div>
                            <div className='mb-3 sortBy selectBox'>
                                <select className='formInput'>
                                    <option>Price low to high</option>
                                </select>
                            </div>
                        </div>
                        <div className='d-flex flex-wrap'>
                            <div className='listingLeft'>
                                <a className='filterLink' onClick={toggleClass}><TuneIcon /></a>
                                <div className={isActive ? 'inner open' : 'inner'}>
                                    <Accordion className='listingAccordian' alwaysOpen defaultActiveKey={['1', '2', '3', '4', '5', '6']}>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Type</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className='mb-4 p-0'>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Collectible <Form.Check className='checkbox ms-2' name="Collectible" value="Collectible" onChange={handleInputChangeCollectible} /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Non Collectible <Form.Check className='checkbox ms-2' name="Non Collectible" value="Non Collectible" onChange={handleInputChangeCollectible} /></label></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Status</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className='mb-4 p-0'>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Buy Now <Form.Check className='checkbox ms-2' name="buy" value="buy" onChange={handleInputChangeStatus} /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>On Auction <Form.Check className='checkbox ms-2' name="auction" value="auction" onChange={handleInputChangeStatus} /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Buy with Card <Form.Check className='checkbox ms-2' name="buy" value="buy" onChange={handleInputChangeStatus} /></label></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>Price</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='mb-4 d-flex align-items-center priceRange'>
                                                    <div className='selectBox me-2'>
                                                        <select className='formInput'>
                                                            <option>USD</option>
                                                            <option>sad</option>
                                                        </select>
                                                    </div>
                                                    <div className='box'><input type="number" className='formInput' placeholder='Min' name="minPrice" value={minPrice} onChange={handleInputChangeMinPrice} /></div>
                                                    <div className='ps-2 pe-2'>to</div>
                                                    <div className='box'><input type="number" className='formInput' placeholder='Max' name="maxPrice" value={maxPrice} onChange={handleInputChangeMaxPrice} /></div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>Quantity</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className='mb-4 p-0'>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>All items <Form.Check className='checkbox ms-2' /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Single items <Form.Check className='checkbox ms-2' /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Bundles <Form.Check className='checkbox ms-2' /></label></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>Category</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className='mb-4 p-0'>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Collectibles <Form.Check className='checkbox ms-2' name="Collectibles" value="Collectibles" onChange={handleChecked} /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Domain Names <Form.Check className='checkbox ms-2' name="Domain Names" value="Domain Names" onChange={handleChecked} /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Music <Form.Check className='checkbox ms-2' name="Music" value="Music" onChange={handleChecked} /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Photography <Form.Check className='checkbox ms-2' name="Photography" value="Photography" onChange={handleChecked} /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Sports <Form.Check className='checkbox ms-2' name="Sports" value="Sports" onChange={handleChecked} /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Trading Cards <Form.Check className='checkbox ms-2' name="Trading Cards" value="Trading Cards" onChange={handleChecked} /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Utility <Form.Check className='checkbox ms-2' name="Utility" value="Utility" onChange={handleChecked} /></label></li>
                                                    <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>Virtual Worlds <Form.Check className='checkbox ms-2' name="Virtual Worlds" value="Virtual Worlds" onChange={handleChecked} /></label></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>Collections</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className='mb-4 p-0'>
                                                    {collectionList.data && collectionList.data.length > 0 && collectionList.data.map((collection, i) =>
                                                        <li className='mb-3 grayClr'><label className='d-flex align-items-center justify-content-between'>{collection.collectionName} <Form.Check className='checkbox ms-2' name={collection.collectionName} value={collection.collectionName} onChange={handleInputChangeCollection} /></label></li>
                                                    )}

                                                </ul>
                                                <div><Button className='allBtn'>All Collections</Button></div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <div className='applyBtn'><Button onClick={toggleClass}>Apply</Button></div>
                                </div>
                            </div>
                            <div className='listingRight'>
                                <ul className='p-0 productList d-flex flex-wrap'>





                                    {
                                        (nftUserList.data && nftUserList.data.length > 0) ?
                                            nftUserList.data.map((data, i) => (

                                                <li>
                                                    {
                                                        data.authentication == 'enable' ? <div className='inner'>
                                                            <div className='img'><a onClick={() => nftDetail(data._id)}><img alt="" src={data.featuredImage} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: {data.nftItemCount}</span></div>
                                                            <div className='userName'>
                                                                <a>{data.itemName}</a>
                                                                <span className='d-block pt-1'>{data.userName}</span>
                                                            </div>
                                                            {
                                                                data.amount ? <div className='price'>Price <span className='d-block mt-1'>{data.amount} HBAR</span></div> : ''
                                                            }

                                                        </div> : ''
                                                    }


                                                </li>


                                            )) : ""


                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default ProductListing;