import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	walletStatusFinal: "false",
	status: '',
	message: '',
	walletaAdd:{}
};

const walletStatusSlice = createSlice({
	name: 'walletStatusFinal',
	initialState,
	reducers: {
		walletConnectPending: (state) => {
			state.isLoading = true;
		},
		walletConnectSuccess: (state, { payload }) => {
			state.walletStatusFinal = payload;

		},
		
		walletConnectError: (state, { payload }) => {
			state.isLoading = false;
			state.walletStatus = 'error';
			state.message = payload;
		},
		walletConnectResetMessage: (state) => {
			state.message = '';
		},
		walletAddress: (state, { payload }) => {
			state.walletAdd = payload;

		},
	},
});

const { reducer, actions } = walletStatusSlice;

export const {
	walletConnectPending,
	walletConnectSuccess,
	walletConnectError,
	walletConnectResetMessage,
	walletAddress

} = actions;

export default reducer;