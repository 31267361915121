import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    status: '',
    message: '',
    profileData: {},
    dashboardData: {},
    UserList: []
};

const adminDetail = createSlice({
    name: 'userDetail',
    initialState,
    reducers: {
        
        adminLoginPending: (state) => {
            state.isLoading = true;
        },
        adminLoginSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload;
        },
        adminLoginError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        adminLoginResetMessage: (state) => {
            state.message = '';
        },


        adminDeatailPending: (state) => {
            state.isLoading = true;
        },
        adminDeatailSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.profileData = payload
        },

        adminDeatailError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        adminDeatailResetMessage: (state) => {
            state.message = '';
        },


        adminUpdatePending: (state) => {
            state.isLoading = true;
        },

        adminUpdateSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.profileData = payload
        },

        adminUpdateError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        adminUpdateResetMessage: (state) => {
            state.message = '';
        },

        adminChangePassPending: (state) => {
            state.isLoading = true;
        },

        adminChangePassSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload
        },

        adminChangePassError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        adminChangePassResetMessage: (state) => {
            state.message = '';
        },
        adminDashboardDeatailPending: (state) => {
            state.isLoading = true;
        },
        adminDashboardDeatailSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.dashboardData = payload
        },

        adminDashboardDeatailError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        adminDashboardDeatailResetMessage: (state) => {
            state.message = '';
        },




        adminFogotPending: (state) => {
            state.isLoading = true;
        },
        adminFogotSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload;
        },
        adminFogotError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        adminFogotResetMessage: (state) => {
            state.message = '';
        },


        adminVeriyOtpPending: (state) => {
            state.isLoading = true;
        },
        adminVeriyOtpSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload;
        },
        adminVeriyOtpError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        adminVeriyOtpResetMessage: (state) => {
            state.message = '';
        },

        adminResetPending: (state) => {
            state.isLoading = true;
        },
        adminResetSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload;
        },
        adminResetError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        adminResetResetMessage: (state) => {
            state.message = '';
        },

        adminResendOtpPending: (state) => {
            state.isLoading = true;
        },
        adminResendOtpSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload;
        },
        adminResendOtpError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        adminResendOtpResetMessage: (state) => {
            state.message = '';
        },


    },
});

const { reducer, actions } = adminDetail;
export const { adminLoginPending, adminLoginSuccess, adminLoginError,
    adminLoginResetMessage, adminDeatailPending, adminDeatailSuccess, adminDeatailError,
    adminDeatailResetMessage, adminUpdatePending, adminUpdateSuccess, adminUpdateError,
    adminUpdateResetMessage, adminChangePassPending, adminChangePassSuccess, adminChangePassError,
    adminChangePassResetMessage, adminDashboardDeatailPending, adminDashboardDeatailSuccess, adminDashboardDeatailError,
    adminDashboardDeatailResetMessage,adminFogotPending,adminFogotSuccess,adminFogotError,adminFogotResetMessage,
    adminVeriyOtpPending,adminVeriyOtpSuccess,adminVeriyOtpError,adminVeriyOtpResetMessage,
    adminResetPending,adminResetSuccess,adminResetError,adminResetResetMessage,adminResendOtpPending,
    adminResendOtpSuccess,adminResendOtpError,adminResendOtpResetMessage
} = actions;

export default reducer;