import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import './Admin.scss';
import AdminHeader from '../../component/AdminHeader';
import AdminMenu from '../../component/AdminMenu';
import Button from 'react-bootstrap/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DetailImg from '../../assets/images/watch_img6.png'
import rotate from '../../assets/images/rotate.png';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import DetailNft from '../../component/DetailNft.js';
import DetailBid from '../../component/DetailBid.js';
import DetailHistory from '../../component/DetailHistory.js';
import { useSelector, useDispatch } from 'react-redux';
import { getAdminNftDetail } from '../redux/action/adminNftAction';


const NftDetail = () => {
    const dispatch = useDispatch();
    const { nftAdminData } = useSelector((state) => state.adminNftDetail)
    const nftItemId = JSON.parse(localStorage.getItem('nftItemId'));
    
    useEffect(() => {
        dispatch(getAdminNftDetail(nftItemId));
    }, [getAdminNftDetail])


    return (

        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className='mb-3 d-flex align-items-center'>
                            <div className='me-3'><NavLink to="/nft"><Button className='backBtn d-flex align-items-center'><KeyboardBackspaceIcon className='me-1' /> Back</Button></NavLink></div>
                            <div className="dbMainHd">NFT Details</div>
                        </div>
                        <div className='mb-4 mb-md-5 nftDetail d-flex flex-wrap'>
                            <div className='img position-relative'><span className='rotate'><img alt="" src={rotate} /></span><img src={nftAdminData.data && nftAdminData.data[0].itemProfile} alt='' /></div>
                            <div className='det ps-0 ps-md-4'>
                                <ul className='p-0 m-0'>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>NFT ID:</span>
                                        <span className='right'>{nftAdminData.data && nftAdminData.data[0].nftItemCount}</span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>NFT Name:</span>
                                        <span className='right'>{nftAdminData.data && nftAdminData.data[0].itemName}</span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>NFT Type:</span>
                                        <span className='right'>{nftAdminData.data && nftAdminData.data[0].CollectibleType}</span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>Creator:</span>
                                        <span className='right'>{nftAdminData.data && nftAdminData.data[0].userName}</span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>Collection:</span>
                                        <span className='right'>{nftAdminData.data && nftAdminData.data[0].collectionName}</span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>Owner:</span>
                                        <span className='right'>{nftAdminData.data && nftAdminData.data[0].userName}</span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>Price:</span>
                                        <span className='right'>{nftAdminData.data && nftAdminData.data[0].amount} HBAR</span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3'>
                                        <span className='left me-3'>Buyer:</span>
                                        <span className='right'>N/A</span>
                                    </li>
                                    <li className='d-flex pt-3 pb-3 desc'>
                                        <span className='left me-3'>Desctription:</span>
                                        <span className='right'>{nftAdminData.data && nftAdminData.data[0].description}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <Tab.Container defaultActiveKey="Details">
                                <Nav className='detailTab' defaultActiveKey="Details">
                                    <Nav.Link eventKey="Details" class="active">Details</Nav.Link>
                                    <Nav.Link eventKey="Bids">Bids</Nav.Link>
                                    <Nav.Link eventKey="History">History</Nav.Link>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="Details">
                                        <div className='detList'>


                                            <ul className='d-flex flex-wrap p-0'>


                                                {
                                                    (nftAdminData.data && nftAdminData.data[0].properties.length > 0) ?
                                                        nftAdminData.data[0].properties.map((data, i) => (

                                                            <li>


                                                                <div className='inner rounded-3 p-3 text-center'>
                                                                    <div className='pb-1 blueClr'>{data.properties}</div>
                                                                    <div className='pb-1 mid'>{data.levels}</div>
                                                                    <div className='grayClr'>{data.stats}</div>


                                                                </div>
                                                            </li>
                                                        )) : ""


                                                }


                                            </ul>
                                        </div>
                                        {/* <DetailNft /> */}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Bids">
                                        <DetailBid />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="History">
                                        <DetailHistory />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default NftDetail;