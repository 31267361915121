import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify'
import './App.scss';
import ScrollToTop from "./scrollToTop";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.scss';
import Home from "./pages/Home";
import ConnectWallet from "./pages/ConnectWallet";
import ExploreCollections from "./pages/ExploreCollections";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import About from "./pages/About";
import EditProfile from "./pages/EditProfile";
import FeaturedItems from './pages/FeaturedItems';
import Notifications from './pages/Notifications';
import Offer from './pages/Offer';
import Payment from './pages/Payment';
import MyProfile from './pages/MyProfile';
import MyWatchlist from './pages/MyWatchlist';
import UserNft from './pages/userNft';
import MyOrder from './pages/MyOrder';
import MyCollections from './pages/MyCollections';
import CreateNewCollection from './pages/CreateNewCollection';
import CreateNewItem from './pages/CreateNewItem';
import Checkout from './pages/Checkout';
import HelpCenter from './pages/HelpCenter';
import SubmitRequest from './pages/SubmitRequest';
import TopCollections from './pages/TopCollections';
import CollectionDetails from './pages/CollectionDetails';
import MyPurchasedNftDetails from './pages/MyPurchasedNftDetails';
import ProductListing from './pages/ProductListing';
import ProductDetail from './pages/ProductDetail';
import CreatorPage from './pages/CreatorPage';
import Login from './pages/Admin/Login';
import ForgotPassword from './pages/Admin/ForgotPassword';
import Verification from './pages/Admin/Verification';
import ResetPassword from './pages/Admin/ResetPassword';
import PasswordResetSuccessfull from './pages/Admin/PasswordResetSuccessfull';
import Dashboard from './pages/Admin/Dashboard';
import Users from './pages/Admin/Users';
import UsersDetail from './pages/Admin/UsersDetail';
import Nft from './pages/Admin/Nft';
import Nftapproval from './pages/Admin/nftApproval';
import NftDetail from './pages/Admin/NftDetail';
import SupportTickets from './pages/Admin/SupportTickets';
import SupportTicketsDetail from './pages/Admin/SupportTicketsDetail';
import CollectibleNft from './pages/Admin/CollectibleNft';
import CollectibleNftDetail from './pages/Admin/CollectibleNftDetail';
import Settings from './pages/Admin/Settings';
import Editadminprofile from './pages/Admin/editAdminProfile';
import AdminFunction from './pages/Admin/AdminFunction';
import AdminFunctionDetail from './pages/Admin/adminFunDetails';
import Created from './component/created';
import Trending from './component/Trending';
import Art from './component/Art'
import Collectibles from './component/Collectibles';
import Music from './component/Music';
import Photography from './component/Photography';
import Sports from './component/Sports';
import Watches from './component/Watches';
import Utility from './component/Utility';
import Tradingcards from './component/Tradingcards';
import Recentactivelist from './pages/Admin/recentActiveList';
import Topcreator from './pages/Admin/topCreatorList';
import Mostbiditem from './pages/Admin/mostItemBid';
import Besteditions from './pages/Admin/bestEdition';
import NftAppurvalDetail from './pages/Admin/nftappurvalDetails';
import FractionalNFT from './pages/FractionalNFT';
import NFTShipment from './pages/NFTShipment';
import Partners from './pages/Partners';

function App() {
  return (
    <>
      <ToastContainer transition={Slide} />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/connect-wallet" element={<ConnectWallet />} />
          <Route path="/explore-collections" element={<ExploreCollections />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/blog-detail" element={<BlogDetail />} />
          <Route path="/about" element={<About />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/featured-items" element={<FeaturedItems />} />
          <Route path="/notification" element={<Notifications />} />
          <Route path="/offer" element={<Offer />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/my-watchlist" element={<MyWatchlist />} />
          <Route path="/nftList" element={<UserNft />} />
          <Route path="/my-order" element={<MyOrder />} />
          <Route path="/my-collections" element={<MyCollections />} />
          <Route path="/create-new-collection" element={<CreateNewCollection />} />
          <Route path="/create-new-item" element={<CreateNewItem />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/help-center" element={<HelpCenter />} />
          <Route path="/submit-request" element={<SubmitRequest />} />
          <Route path="/top-collections" element={<TopCollections />} />
          <Route path="/collection-details" element={<CollectionDetails />} />
          <Route path="/my-purchased-nft-details" element={<MyPurchasedNftDetails />} />
          <Route path="/product-listing" element={<ProductListing />} />
          <Route path="/product-detail" element={<ProductDetail />} />
          <Route path="/creator-page" element={<CreatorPage />} />
          <Route path="/created" element={<Created />} />
          <Route path="/trending" element={<Trending />} />
          <Route path="/art" element={<Art />} />
          <Route path="/collectibles" element={<Collectibles />} />
          <Route path="/music" element={<Music />} />
          <Route path="/photography" element={<Photography />} />
          <Route path="/sports" element={<Sports />} />
          <Route path="/watches" element={<Watches />} />
          <Route path="/utility" element={<Utility />} />
          <Route path="/trading-cards" element={<Tradingcards />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/password-reset-successfull" element={<PasswordResetSuccessfull />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/detail" element={<UsersDetail />} />
          <Route path="/nft" element={<Nft />} />
          <Route path="/nft/detail" element={<NftDetail />} />
          <Route path="/support-tickets" element={<SupportTickets />} />
          <Route path="/support-tickets/detail" element={<SupportTicketsDetail />} />
          <Route path="/collectible-nft" element={<CollectibleNft />} />
          <Route path="/collectible-nft/detail" element={<CollectibleNftDetail />} />
          <Route path="/settings" element={<Settings />} />AdminFunction
          <Route path="/edit-admin-profile" element={<Editadminprofile />} />
          <Route path="/admin-function" element={<AdminFunction />} />
          <Route path="/admin-function/detail" element={<AdminFunctionDetail />} />
          <Route path="/recent-active" element={<Recentactivelist />} />
          <Route path="/top-creator" element={<Topcreator />} />
          <Route path="/most-biditem" element={<Mostbiditem />} />
          <Route path="/best-limitd-edition" element={<Besteditions />} />
          <Route path="/approval-nft" element={<Nftapproval />} />
          <Route path="/nftAppurval-detail" element={<NftAppurvalDetail />} />
          <Route path="/fractional-nft" element={<FractionalNFT />} />
          <Route path="/nft-shipment" element={<NFTShipment />} />
          <Route path="/partners" element={<Partners />}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
