import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import './Admin.scss';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import Button from 'react-bootstrap/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Pagination from 'react-js-pagination';
import HBar from '../../assets/images/hbar_wht.png';
import { useSelector, useDispatch } from 'react-redux';
import { getUserDetail } from '../redux/action/adminUserAction';
import { getUserNftList } from '../redux/action/adminNftAction';

const UsersDetail = () => {
    const dispatch = useDispatch();
    const [serach, setSearch] = useState("")
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const { userData } = useSelector((state) => state.adminUserDetail)
    const { nftUserList } = useSelector((state) => state.adminNftDetail)
    const userId = JSON.parse(localStorage.getItem('userId'));

    useEffect(() => {

        dispatch(getUserDetail(userId));
    }, [getUserDetail])

    useEffect(() => {
        let obj = { page: currentPage, search: serach }
        dispatch(getUserNftList(obj));
    }, [currentPage])

    const handleChange = (e) => {
        const { name, value } = e.target
        setSearch(value)
        let obj = { page: currentPage, search: value }
        dispatch(getUserNftList(obj))
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };



    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className='mb-3 d-flex align-items-center'>
                            <div className='me-3'><NavLink to="/users"><Button className='backBtn d-flex align-items-center'><KeyboardBackspaceIcon className='me-1' /> Back</Button></NavLink></div>
                            <div className="dbMainHd">User Details</div>
                        </div>
                        <div className='mb-3 userDetBox d-block d-xl-flex justify-content-between'>
                            <div className='left d-flex align-items-center me-2'>
                                <div className='img'><img src={userData.data && userData.data.getUserDetail[0].profileImg} alt='' className='rounded-circle' /></div>
                                <div className='det ms-3'>
                                    <div className='name mb-3'>{userData.data && userData.data.getUserDetail[0].userName}</div>
                                    <ul className='info p-0 d-flex flex-wrap'>
                                        <li><span className='d-block'>{userData.data && userData.data.nftDetails.length > 0 ? userData.data.nftDetails[0].totalNft : 0}</span> items</li>
                                        <li><span className='d-block'>{userData.data && userData.data.ownerCount}</span> owners</li>
                                        <li><span className='d-block'>{userData.data && userData.data.collectionDetails.length > 0 ? userData.data.collectionDetails[0].totalCollection : 0}</span> collections</li>
                                        <li>
                                            <div className='position-relative barBox'><img src={HBar} alt='' /> <span className='d-block'>{userData.data && userData.data.nftDetails.length > 0 ? userData.data.nftDetails[0].totalVolume : 0}</span> total volumes</div>
                                        </li>
                                        <li>
                                            <div className='position-relative barBox'><img src={HBar} alt='' /> <span className='d-block'>{userData.data && userData.data.nftDetails.length > 0 ? userData.data.nftDetails[0].florPrice : 0}</span> floor price</div>
                                        </li>
                                        <li>
                                            <div className='position-relative barBox'><img src={HBar} alt='' /> <span className='d-block'>0</span> best offers</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center'>
                            <div className="dbMainHd d-flex align-items-center mb-3">NFTs</div>
                            <div className='d-flex mb-3'>
                                <div className='search me-2'>
                                    <input type='text' className='input' name='search' value={serach} onChange={handleChange} placeholder='Search...' />
                                    <SearchIcon className='searchIcon' />
                                </div>
                                <div><a className='filter d-flex justify-content-center align-items-center'><FilterAltIcon /></a></div>
                            </div>
                        </div>

                        <div className='dbTable rounded-3'>
                            <table>
                                <tr>
                                    <th>Image</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Creator</th>
                                    <th>Owner</th>
                                    <th>Collection</th>
                                    <th>NFT Type</th>
                                    <th>Price</th>
                                    <th>Sold To</th>
                                </tr>


                                {
                                    (nftUserList.data && nftUserList.data.nftList.length > 0) ?
                                        nftUserList.data.nftList.map((data, i) => (

                                            <tr>
                                                <td><img src={data.featuredImage} alt='' className='itemImg' /></td>
                                                <td>{data.nftItemCount}</td>
                                                <td>{data.itemName}</td>
                                                <td>{data.userName}</td>
                                                <td>{data.userName}</td>
                                                <td>{data.collectionName}</td>
                                                <td>{data.CollectibleType}</td>
                                                <td>{data.amount} HBAR</td>
                                                <td>Joey Vincent</td>
                                            </tr>

                                        )) : ""


                                }



                            </table>

                        </div>

                        <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={nftUserList.data && nftUserList.data.totalRecord}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                </div>
            </div>
        </>

    )

}
export default UsersDetail;