import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	status: '',
	message: '',
	collectionList: [],
	userCollectionList: [],
	topCollectionList:[],
	collectionData: {},
	tokenData:{}
};

const collectionDetail = createSlice({
	name: 'collectionDetail',
	initialState,
	reducers: {
		createCollectionPending: (state) => {
			state.isLoading = true;
		},
		createCollectionSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.message = payload
		},
		createCollectionError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		createCollectionResetMessage: (state) => {
			state.message = '';
		},
		getCollectionListPending: (state) => {
			state.isLoading = true;
		},
		getCollectionListSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.collectionList = payload
		},
		getCollectionListError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		getCollectionListResetMessage: (state) => {
			state.message = '';
		},

		getUserCollectionListPending: (state) => {
			state.isLoading = true;
		},
		getUserCollectionListSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.userCollectionList = payload
		},
		getUserCollectionListError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		getUserCollectionListResetMessage: (state) => {
			state.message = '';
		},
		collectionDeatailPending: (state) => {
			state.isLoading = true;
		},
		collectionDeatailSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.collectionData = payload
		},
		collectionDeatailError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		collectionDeatailResetMessage: (state) => {
			state.message = '';
		},

		createrequestAuthPending: (state) => {
			state.isLoading = true;
		},
		createrequestAuthSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.message = payload
		},
		createrequestAuthError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		createrequestAuthResetMessage: (state) => {
			state.message = '';
		},

		tokenPending: (state) => {
			state.isLoading = true;
		},
		tokenSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.message = payload
		},
		tokenError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		tokenResetMessage: (state) => {
			state.message = '';
		},



		getTopCollectionListPending: (state) => {
			state.isLoading = true;
		},
		getTopCollectionListSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.topCollectionList = payload
		},
		getTopCollectionListError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		getTopCollectionListResetMessage: (state) => {
			state.message = '';
		},



	},
});

const { reducer, actions } = collectionDetail;
export const { createCollectionPending, createCollectionSuccess, createCollectionError,
	createCollectionResetMessage, getCollectionListPending, getCollectionListSuccess, getCollectionListError,
	getCollectionListResetMessage, collectionDeatailPending, collectionDeatailSuccess, collectionDeatailError,
	collectionDeatailResetMessage, createrequestAuthPending, createrequestAuthSuccess, createrequestAuthError,
	createrequestAuthResetMessage, getUserCollectionListPending, getUserCollectionListSuccess, getUserCollectionListError,
	getUserCollectionListResetMessage,tokenPending,tokenSuccess,tokenError,tokenResetMessage,
	getTopCollectionListPending,getTopCollectionListSuccess,getTopCollectionListError,getTopCollectionListResetMessage
} = actions;

export default reducer;