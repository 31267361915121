import React from "react";
import Slider from "react-slick";
import './NFTCollections.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bestImg1 from '../assets/images/1.jpg';
import bestImg2 from '../assets/images/2.jpg';
import bestImg3 from '../assets/images/3.jpg';
import bestImg4 from '../assets/images/4.jpg';
import bestImg5 from '../assets/images/5.jpg';
import silverPerfume from '../assets/Silver 3d perfume bottle.gif'
import greenPerfume from '../assets/Green 3d perfume bottle.gif'
import frame from '../assets/The Frame.gif'
import { NavLink, Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

const NFTCollections = () => {
  AOS.init({
      once: true,
      duration: 2000,
      });

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1169,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 999,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 2
              }
            }
          ]
    };

    return (
        <>
            <div className='pb-large bestCollection' data-aos="fade-left">
                <div className='mainHd mb-3 mb-md-4'>Featured NFT Collections</div>
                <Slider className="collectionSlider" {...settings}>
                    {/* <div className="item">
                        <NavLink>
                          <img alt="" src={bestImg1} />
                          <div className="over">
                              <div className="inner">
                                <span className="name">Bovet Amadeo Fleurier</span>
                                <span className="info">Floor Price <span>05.02 HBAR</span></span>
                                <span className="info">Volume <span>2.3 HBAR</span></span>
                              </div>
                          </div>
                        </NavLink>                      
                    </div> */}
                    <div className="item">
                        <NavLink>
                          <img alt="" src={silverPerfume} />
                          <div className="over">
                              <div className="inner">
                                <span className="name">Perfume bottle (Blackerstreet N 9.) Cooper Square n 9</span>
                                <span className="info">Floor Price <span>05.02 USD</span></span>
                                <span className="info">Volume <span>2.3 USD</span></span>
                              </div>
                          </div>
                        </NavLink>                      
                    </div>
                    <div className="item">
                        <NavLink>
                          <img alt="" src={greenPerfume} />
                          <div className="over">
                              <div className="inner">
                                <span className="name">Perfume bottle (Bleeckerstreet N. 9) BOND n 9</span>
                                <span className="info">Floor Price <span>05.02 USD</span></span>
                                <span className="info">Volume <span>2.3 USD</span></span>
                              </div>
                          </div>
                        </NavLink>                      
                    </div>
                    <div className="item">
                        <NavLink>
                          <img alt="" src={frame} />
                          <div className="over">
                              <div className="inner">
                                <span className="name">The Frame 1932</span>
                                <span className="info">Floor Price <span>05.02 USD</span></span>
                                <span className="info">Volume <span>2.3 USD</span></span>
                              </div>
                          </div>
                        </NavLink>                      
                    </div>
                    <div className="item">
                        <NavLink>
                          <img alt="" src={bestImg2} />
                          <div className="over">
                              <div className="inner">
                                <span className="name">Black Panther</span>
                                <span className="info">Floor Price <span>05.02 USD</span></span>
                                <span className="info">Volume <span>2.3 USD</span></span>
                              </div>
                          </div>
                        </NavLink>                      
                    </div>
                </Slider>
            </div>
        </>

    )

}
export default NFTCollections;