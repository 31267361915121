import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';
import './Admin.scss';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ItemImg from '../../assets/images/item_img.jpg';
import NftIcon from '../../assets/images/nft_icon_dark.png';
import Pagination from 'react-js-pagination';
import { useSelector, useDispatch } from 'react-redux';
import { getNftList, getAdminNftDetail, updateStatus,getApprovalNftList } from '../redux/action/adminNftAction';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const Nftapproval = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [serach, setSearch] = useState("")
    const { nftAppurvalList } = useSelector((state) => state.adminNftDetail)

    useEffect(() => {
        let obj = { page: currentPage, search: serach }
        dispatch(getApprovalNftList(obj));
    }, [currentPage])


    const nftDetail = (id) => {
        dispatch(getAdminNftDetail(id))
        localStorage.setItem('nftItemId', JSON.stringify(id));
        navigate(`/nftAppurval-detail`)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setSearch(value)
        let obj = { page: currentPage, search: value }
        dispatch(getApprovalNftList(obj))
    }


    const handleAuthentication = (nftItemId) => {
        dispatch(updateStatus(nftItemId)).then(res => {
            if (res.payload.code == 200) {
                let obj = { page: currentPage, search: serach }
                dispatch(getApprovalNftList(obj));
                toast.success(res.payload.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.payload.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })

    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className='d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center'>
                            <div className="dbMainHd d-flex align-items-center mb-3"><span className='d-flex align-items-center justify-content-center rounded-1 me-3'><img alt='' src={NftIcon} /></span>Approval NFTs</div>
                            <div className='d-flex mb-3'>
                                <div className='search me-2'>
                                    <input type='text' className='input' name='search' value={serach} onChange={handleChange} placeholder='Search...' />
                                    <SearchIcon className='searchIcon' />
                                </div>
                                <div><a className='filter d-flex justify-content-center align-items-center'><FilterAltIcon /></a></div>
                            </div>
                        </div>

                        <div className='dbTable rounded-3'>
                            <table>
                                <tr>
                                    <th>Image</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Creator</th>
                                    <th>Owner</th>
                                    <th>Collection</th>
                                    <th>NFT Type</th>
                                    <th>Price</th>
                                    <th>Sold To</th>
                                    <th>NFT Status</th>
                                    <th>Action</th>
                                </tr>


                                {
                                    (nftAppurvalList.data && nftAppurvalList.data.nftList.length > 0) ?
                                    nftAppurvalList.data.nftList.map((data, i) => (

                                            <tr className='cursor-pointer'  >
                                                <td onClick={() => nftDetail(data._id)}><img src={data.featuredImage} alt='' className='itemImg' /></td>
                                                <td onClick={() => nftDetail(data._id)}>{`Item-${data.nftItemCount}`}</td>
                                                <td onClick={() => nftDetail(data._id)}>{data.itemName}</td>
                                                <td onClick={() => nftDetail(data._id)}>{data.userName}</td>
                                                <td onClick={() => nftDetail(data._id)}>{data.userName}</td>
                                                <td onClick={() => nftDetail(data._id)}>{data.collectionName}</td>
                                                <td onClick={() => nftDetail(data._id)}>{data.CollectibleType}</td>
                                                <td>{data.amount ? data.amount : 0} HBAR</td>
                                                <td>Joey Vincent</td>
                                                <td>{data.nftStatus}</td>
                                                {/* <td> <Link onClick={() => handleAuthentication(data._id)}> <div className='ms-2 toggleBtn'><Form.Check type="switch" id="custom-switch2" label="" /></div></Link>
                                                </td> */}
                                                <td> <div className='ms-2 toggleBtn' onClick={() => handleAuthentication(data._id)}> <Switch {...label} defaultChecked={data.nftStatus == 'Active' ? true : false} /></div>

                                                </td>

                                            </tr>

                                        )) : ""


                                }


                            </table>
                        </div>
                        <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={nftAppurvalList.data && nftAppurvalList.data.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                </div>
            </div>
        </>

    )

}
export default Nftapproval;