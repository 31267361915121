import axios from 'axios';
import config from '../config/config';

const url = `${config.Url}admin/`

export const getAllUser = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.get(`${url}getAdminUserList?pageNumber=${frmData.page}&search=${frmData.search}`, {
				headers: {
					token: localStorage.getItem('adminToken'),
				},
			});

			resolve(result);
		} catch (error) {
			reject(error);
		}
	});
};

export const fetchUser = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const userId = JSON.parse(localStorage.getItem('userId'));
			const res = await axios.get(`${url}getAdminUserDetail?userId=${userId}`, {
				headers: {
					token: localStorage.getItem('adminToken'),
				},
			});

			resolve(res.data);
		} catch (error) {
			console.log(error);
			reject(error.message);
		}
	});
};

export const getAllCollection = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.get(`${url}getCollectionList?pageNumber=${frmData.page}&search=${frmData.search}`, {
				headers: {
					token: localStorage.getItem('adminToken'),
				},
			});

			resolve(result);
		} catch (error) {
			reject(error);
		}
	});
};

export const getCollectionDetail = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.get(`${url}getCollectionDetail?collectionId=${frmData}`, {
				headers: {
					token: localStorage.getItem('adminToken'),
				},
			});

			resolve(result);
		} catch (error) {
			reject(error);
		}
	});
};


export const updateAuthentication = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.get(`${url}updateAuthentication?collectionId=${frmData}`, {
				headers: {
					token: localStorage.getItem('adminToken'),
				},
			});

			resolve(result);
		} catch (error) {
			reject(error);
		}
	});
};


export const updateUserStatus = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.get(`${url}updateUserStatus?userId=${frmData}`, {
				headers: {
					token: localStorage.getItem('adminToken'),
				},
			});

			resolve(result);
		} catch (error) {
			reject(error);
		}
	});

};

export const createBestLimitedEdition = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(`${url}createBestLimitedEdition`, frmData, {
				headers: {},
			});
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
}

export const getAllBestLimitedEditionList = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${url}getBestLimitedEditionList?pageNumber=${frmData.page}&search=${frmData.search}`, {

                headers: {
                    token: localStorage.getItem('adminToken'),
                },
            });


            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};

