import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import './Header.scss';
import logo from '../assets/images/Logo.gif'
import SearchIcon from '@mui/icons-material/Search';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Profile from '../assets/images/profile_icon.png'
import Favorites from '../assets/images/favorites_icon.png'
import Watchlist from '../assets/images/watchlist_icon.png'
import Orders from '../assets/images/order_icon.png'
import Collections from '../assets/images/collections_icon.png'
import Settings from '../assets/images/settings_icon.png'
import Logout from '../assets/images/logout_icon.png'
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import { userLogout } from '../api/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { allSearch } from '../pages/redux/action/allsearchAction';

const Header = ({ setCurrentTab }) => {
    const [isActive, setActive] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [walletConnected, setWalletConnected] = useState(false);
    const [value, setValue] = useState('');
    const [search, setSearch] = useState('');
    const [showModel, setShowModel] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const { allSearchList } = useSelector((state) => state.allsearchDetail)
    const navigat = useNavigate()
    let wallet = localStorage.getItem('walletAddress')
    const toggleClass = () => {
        setActive(!isActive);
    };

    // console.log('allSearchList----->', allSearchList)

    const [isActive2, setActive2] = useState(false);

    const toggleClass2 = () => {
        setActive2(!isActive2);
    };

    const logOut = () => {
        userLogout();
        localStorage.removeItem('accessJWT');
        localStorage.removeItem('walletAddress');
        localStorage.removeItem('hashconnectData');
        localStorage.removeItem('userid');
        localStorage.removeItem('userName');
        navigat('/');
    };
    const handleTab = (type) => {
        localStorage.setItem("TabIndex", type)
    }
    const openModel = () => {
        setShowModel(true)
    }
    const handleClose = () => {
        setShowModel(false)
    }


    const handleInput = async (e) => {
        const { name, value } = e.target
        setShowModel(true)
        setSearch(value)
        dispatch(allSearch(value));
    }



    const collectionDetail = (id) => {
        localStorage.setItem('collectionId', JSON.stringify(id));
        navigate(`/collection-details`)
    }

    const nftDetail = (id) => {
        localStorage.setItem('nftItemId', JSON.stringify(id));
        navigate(`/product-detail`)
    }


    return (
        <>
            <header className='header d-flex justify-content-between'>
                <div className='logo d-flex justify-content-center align-items-center'><NavLink to="/"><img alt="" src={logo} /></NavLink></div>
                <div className='searchBox'>

                    <input className="searchInput" type="text" name="search" placeholder='Search...' onChange={handleInput} />
                    <SearchIcon className='searchIcon' />
                    {
                        <div>
                            <div>
                                {

                                    showModel ?
                                        <div className="main">
                                            <div className="popup">
                                                <div className="popup-header">
                                                    <h3>Collections</h3>
                                                    <Link><h3 onClick={handleClose}>X</h3></Link>
                                                </div>
                                                <div>
                                                    <ul className='collList'>
                                                        {
                                                            (allSearchList.data && allSearchList.data.collectionList && allSearchList.data.collectionList.length > 0) ?
                                                                allSearchList.data.collectionList.map((data, i) => (

                                                                    <li >
                                                                        <a onClick={() => collectionDetail(data._id)}>

                                                                            <span className='name d-flex align-items-center'><img alt="" src={data.profileImage[0]} />{data.collectionName} </span>
                                                                        </a>
                                                                    </li>


                                                                )) : ""


                                                        }
                                                    </ul>
                                                </div>

                                                <div className="popup-header">
                                                    <h3>NFT Item</h3>
                                                    <Link><h3 onClick={handleClose}>X</h3></Link>
                                                </div>
                                                <div>
                                                    <ul className='collList'>
                                                        {
                                                            (allSearchList.data && allSearchList.data.nftList && allSearchList.data.nftList.length > 0) ?
                                                                allSearchList.data.nftList.map((data, i) => (

                                                                    <li >
                                                                        <a onClick={() => nftDetail(data._id)}>

                                                                            <span className='name d-flex align-items-center'><img alt="" src={data.itemProfile} /> {data.itemName} </span>
                                                                        </a>
                                                                    </li>


                                                                )) : ""


                                                        }
                                                    </ul>
                                                </div>


                                                <div className="popup-header">
                                                    <h3>Accounts</h3>
                                                    <Link><h3 onClick={handleClose}>X</h3></Link>
                                                </div>
                                                <div>
                                                    <ul className='collList'>
                                                        {
                                                            (allSearchList.data && allSearchList.data.userList && allSearchList.data.userList.length > 0) ?
                                                                allSearchList.data.userList.map((data, i) => (

                                                                    <li >
                                                                        <a>

                                                                            <span className='name d-flex align-items-center'><img alt="" src={data.profileImg} /> {data.userName} </span>
                                                                        </a>
                                                                    </li>


                                                                )) : ""


                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> : ""
                                }
                            </div>
                        </div>
                    }
                </div>
                <nav className='menu'>
                    <a className={isActive ? 'showMobMenu active' : 'showMobMenu'}
                        onClick={toggleClass} ><span><i></i><i></i><i></i></span></a>
                    <ul className={isActive ? 'menuLink m-0 p-0 d-flex active' : 'menuLink m-0 p-0 d-flex'}>
                        <li>
                            <NavLink to="/explore-collections" activeClassName="active">Explore</NavLink>
                            <ul className='submenu m-0 p-0'>
                                <li><Link to='/product-listing'>All NFTs</Link></li>
                                <li><Link to='/art'>Art</Link></li>
                                <li><Link to='/collectibles'>Collectibles</Link></li>

                                {/* <li><Link to='/music'>Music</Link></li>*/}
                                <li><Link to='/photography'>Design</Link></li> 
                                {/* <li><Link to='/sports'>Sports</Link></li> */}
                                <li><Link to='/utility'>Jewellery</Link></li>
                                {/* <li><Link to='/trading-cards'>Trading Cards</Link></li> */}
                                <li><Link to='/watches'>Watches</Link></li>
                            </ul>
                        </li>
                        <li><Link to='/trending'>Trending</Link></li>
                        <li>
                            <a>Resources</a>
                            <ul className='submenu m-0 p-0'>
                                <li><Link to="/fractional-nft">Fractional NFT</Link></li>
                                <li><Link to="/nft-shipment">Shipments</Link></li>
                                <li><Link to="/help-center">Help Center</Link></li>
                                <li><Link to='/blog'>Blog</Link></li>
                                <li><a>Newsletter</a></li>
                            </ul>
                        </li>
                        {!wallet ? <li><NavLink to="/connect-wallet" activeClassName="active">Create</NavLink></li> : <li><NavLink to="/create-new-item" activeClassName="active">Create</NavLink></li>}
                        {!wallet ? <li className='wallet'><NavLink to="/connect-wallet"><AccountBalanceWalletOutlinedIcon className='icon' />{wallet ? wallet : "Connect Wallet"} </NavLink></li> : <li className='wallet'><a className={isActive2 ? 'active' : ''} onClick={toggleClass2} >{wallet}</a></li>}
                    </ul>
                </nav>
            </header>
            <div className={isActive2 ? 'bg active' : 'bg'} onClick={toggleClass2}></div>
            <div className={isActive2 ? 'accountMenu active' : 'accountMenu'}>
                <a className='close' onClick={toggleClass2}><CloseIcon /></a>
                <ul className='m-0 p-0'>
                    <li onClick={toggleClass2}><NavLink to="/my-profile" onClick={() => handleTab("Created")}><span className='icon'><img className='me-2' alt="" src={Profile} /></span>Profile</NavLink></li>
                    <li onClick={toggleClass2}><NavLink to="/creator-page" onClick={() => handleTab("Favorite")}><span className='icon'><img className='me-2' alt="" src={Favorites} /></span>Favorites</NavLink></li>
                    <li onClick={toggleClass2}><NavLink to="/my-watchlist"><span className='icon'><img className='me-2' alt="" src={Watchlist} /></span>Watchlist</NavLink></li>
                    <li onClick={toggleClass2}><NavLink to="/my-order"><span className='icon'><img className='me-2' alt="" src={Orders} /></span>My Orders</NavLink></li>
                    <li onClick={toggleClass2}><NavLink to="/my-collections"><span className='icon'><img className='me-2' alt="" src={Collections} /></span>My Collections</NavLink></li>
                    <li onClick={toggleClass2}><NavLink to="/edit-profile"><span className='icon'><img className='me-2' alt="" src={Settings} /></span>Settings</NavLink></li>
                    <li><a onClick={logOut}><span className='icon'><img className='me-2' alt="" src={Logout} /></span>Log out</a></li>
                </ul>
            </div>



        </>

    )

}
export default Header;