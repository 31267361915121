import { createCollection, getAllCollection, fetchColectionDetail, createAuthRequest, getUserCollection, getTokenAddress, getAllTopCollection } from '../../../api/collectionApi';
import {
    createCollectionPending, createCollectionSuccess, createCollectionError, createCollectionResetMessage,
    getCollectionListPending, getCollectionListSuccess, getCollectionListError, getCollectionListResetMessage,
    collectionDeatailPending, collectionDeatailSuccess, collectionDeatailError, collectionDeatailResetMessage,
    createrequestAuthPending, createrequestAuthSuccess, createrequestAuthError, createrequestAuthResetMessage,
    getUserCollectionListPending, getUserCollectionListSuccess, getUserCollectionListError, getUserCollectionListResetMessage,
    tokenPending, tokenSuccess, tokenError, tokenResetMessage, getTopCollectionListPending, getTopCollectionListSuccess,
    getTopCollectionListError, getTopCollectionListResetMessage
} from '../reducer/collectionReducer';
import { multiPartData } from '../../../utils';



export const createCollections = (frmDt) => async (dispatch) => {
    try {
        let data = multiPartData(frmDt)
        dispatch(createCollectionPending());

        const result = await createCollection(data);
        if (result.code === 200) {
            return dispatch(createCollectionSuccess(result))
        } else {
            return dispatch(createCollectionError(result.message));
        }

    } catch (error) {
        dispatch(createCollectionResetMessage(error.message));
    }
};

export const getCollectionsList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getCollectionListPending());
        const result = await getAllCollection(frmDt);
        result.data.code === 200
            ? dispatch(getCollectionListSuccess(result.data))
            : dispatch(getCollectionListError(result.message));


    } catch (error) {
        dispatch(getCollectionListResetMessage(error.message));
    }
};

export const getUserCollectionsList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getUserCollectionListPending());
        const result = await getUserCollection(frmDt);
        result.data.code === 200
            ? dispatch(getUserCollectionListSuccess(result.data))
            : dispatch(getUserCollectionListError(result.message));


    } catch (error) {
        dispatch(getUserCollectionListResetMessage(error.message));
    }
};

export const getCollectionDetail = (frmDt) => async (dispatch) => {
    try {
        dispatch(collectionDeatailPending());
        const result = await fetchColectionDetail(frmDt);
        result.code === 200
            ? dispatch(collectionDeatailSuccess(result))
            : dispatch(collectionDeatailError(result.message));


    } catch (error) {
        dispatch(collectionDeatailResetMessage(error.message));
    }
};


export const createRequest = (frmDt) => async (dispatch) => {
    try {
        let data = multiPartData(frmDt)
        dispatch(createrequestAuthPending());

        const result = await createAuthRequest(data);
        if (result.code === 200) {
            return dispatch(createrequestAuthSuccess(result))
        } else {
            return dispatch(createrequestAuthError(result.message));
        }
        console.log(result);
    } catch (error) {
        dispatch(createrequestAuthResetMessage(error.message));
    }
};

export const getTokenAdd = (frmDt) => async (dispatch) => {
    try {
        dispatch(tokenPending());
        const result = await getTokenAddress(frmDt);
        console.log('resultqqqqqqq------>', result)
        if (result.data.code === 200) {
            return dispatch(tokenSuccess(result.data))
        } else {
            dispatch(tokenError(result.message));
        }
    } catch (error) {
        dispatch(tokenResetMessage(error.message));
    }
};


export const getTopCollectionsList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getTopCollectionListPending());
        const result = await getAllTopCollection(frmDt);
        result.data.code === 200
            ? dispatch(getTopCollectionListSuccess(result.data))
            : dispatch(getTopCollectionListError(result.message));


    } catch (error) {
        dispatch(getTopCollectionListResetMessage(error.message));
    }
};