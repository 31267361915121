import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	status: '',
	message: '',
	profileData: {},
};

const getUserDetails = createSlice({
	name: 'userDetail',
	initialState,
	reducers: {
		userDeatailPending: (state) => {
			state.isLoading = true;
		},
		userDeatailSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.profileData = payload
		},

		userUpdateSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.profileData = payload
		},

		userDeatailError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		userDeatailResetMessage: (state) => {
			state.message = '';
		},
	},
});

const { reducer, actions } = getUserDetails;
export const { userDeatailPending, userDeatailSuccess, userDeatailError,
	userDeatailResetMessage, userUpdateSuccess
} = actions;

export default reducer;