import React, { } from 'react';
import { NavLink, Link } from "react-router-dom";
import './Blog.scss';
import HeaderBlog from '../component/HeaderBlog.js';
import Footer from '../component/Footer.js';
import blogDetail from '../assets/images/blog-detail-img.jpg';
import blogImg1 from '../assets/images/blog_img1.jpg';
import blogImg2 from '../assets/images/blog_img2.jpg';
import blogImg3 from '../assets/images/blog_img3.jpg';
import blogImg4 from '../assets/images/blog_img4.jpg';
import blogImg5 from '../assets/images/blog_img5.jpg';
import blogImg6 from '../assets/images/blog_img6.jpg';
import blogImg7 from '../assets/images/blog_img7.jpg';
import blogImg8 from '../assets/images/blog_img8.jpg';
import blogImg9 from '../assets/images/blog_img9.jpg';
import blogImg10 from '../assets/images/blog_img10.jpg';
import blogImg11 from '../assets/images/blog_img11.jpg';
import blogImg12 from '../assets/images/blog_img12.jpg';
import blogImg13 from '../assets/images/blog_img13.jpg';
import blogImg14 from '../assets/images/blog_img14.jpg';
import blogImg15 from '../assets/images/blog_img15.jpg';
import blogImg16 from '../assets/images/blog_img16.jpg';
const Blog = () => {



    return (
        <>
            <div className="mainCon blogCon">
                <HeaderBlog />
                <div className='pt-4 pt-md-5 pb-4 pb-md-5'>
                    <div className='container'>
                        <div className='blogTop mb-4 mb-md-5'>
                            <div className='img' style={{ backgroundImage: 'url(' + blogDetail + ')', backgroundPosition: 'center', backgroundSize: 'cover' }}><img alt="" src={blogDetail} /></div>
                            <NavLink to="/blog/blog-detail" className='det'>
                                <figure className='m-0'>
                                    <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                    <span className='hd d-block mb-0 mb-sm-2'>Your Ledex NFTs just got even more decentralized and resilient</span>
                                    <span className='text d-block'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span>
                                </figure>
                            </NavLink>
                        </div>
                        <ul className='blogList d-flex flex-wrap p-0'>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg1} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg2} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg3} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg4} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg5} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg6} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg7} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg8} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg9} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg10} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg11} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg12} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg13} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg14} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg15} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg16} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default Blog;