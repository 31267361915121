import axios from 'axios';
import config from '../config/config';
const url = `${config.Url}user/`

export const createFavourite = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(`${url}createFavorites`, frmData, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getAllFavourite = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.get(`${url}favoriteList`, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(result);
			console.log('result-------->', result.data)
		} catch (error) {
			reject(error);
		}
	});
};


export const fetchFavorite = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const accessJWT = localStorage.getItem('accessJWT');

			if (!accessJWT) {
				reject('Token not found!');
			}

			const res = await axios.post(`${url}favoriteDetail`, frmData, {
				headers: {
					token: accessJWT,
				},
			});

			resolve(res.data);
		} catch (error) {
			console.log(error);
			reject(error.message);
		}
	});
};



