import { walletConnectSuccess, walletConnectPending, walletConnectError, walletAddress } from './connectWalletSlice';
import { uesrRegister } from '../../api/userApi'



export const walletStatusUpdate = (frmDt, type) => async (dispatch) => {
    try {
        dispatch(walletAddress(frmDt))
        console.log('frmDt------------------------>', frmDt)
        dispatch(walletConnectPending());
        const result = await uesrRegister(frmDt);
        dispatch(walletConnectSuccess(frmDt))



    } catch (error) {
        dispatch(walletConnectError(error.message));
    }


};










