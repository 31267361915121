import React, { useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import './Admin.scss';
import AdminFooter from '../../component/AdminFooter'
import logo from '../../assets/images/logo.png'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
const PasswordResetSuccessfull = () => {



    return (
        <>
            <div className='adminTop d-flex align-items-center justify-content-center'>
                <div className='w-100'>
                    <div className='mb-4 mb-md-5 text-center'><img alt="" src={logo} /></div>
                    <div className='loginForm'>
                        <div className='inner text-center'>
                            <div className='sucessfullIcon mb-2'><TaskAltOutlinedIcon /></div>
                            <div className='loginHd mb-4'>Password Reset Successfully!</div>
                            <div className='hdText'><NavLink to="/login" className='grayClr'>Login to account</NavLink></div>
                        </div>
                    </div>
                </div>
            </div>
            <AdminFooter />
        </>

    )

}
export default PasswordResetSuccessfull;