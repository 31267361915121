import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector, useDispatch } from 'react-redux';
import { getCollectionDetail, getUserCollectionsList } from '../pages/redux/action/collectionAction';

const Collections = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userCollectionList } = useSelector((state) => state.collectionDetail)

    useEffect(() => {
        dispatch(getUserCollectionsList());
    }, [getUserCollectionsList])



    const collectionDetails = (id) => {
        dispatch(getCollectionDetail(id))
        localStorage.setItem('collectionId', JSON.stringify(id));
        navigate(`/collection-details`)
    }


    return (

        <>
            <div className='mb-4'><NavLink to="/create-new-collection"><Button>Create New Collection</Button></NavLink></div>
            <ul className='p-0 collectionList d-flex flex-wrap'>
                {
                    (userCollectionList.data && userCollectionList.data.length > 0) ?
                        userCollectionList.data.map((data, i) => (

                            <li>
                                <a className='rounded-3' onClick={() => collectionDetails(data._id)}>
                                    <span className='img rounded-3 overflow-hidden d-block'><img alt="" src={data.profileImage} className="rounded-3" /></span>
                                    <span className='name d-flex align-items-center pt-3 pb-2'><img alt="" src={data.profileImg} className="rounded-circle me-2" /> {data.collectionName} {data.authentication=='enable'? <CheckCircleIcon className='check ms-1' /> : ''} </span>
                                </a>
                            </li>
                        )) : <div>
                            <div className='text-center pt-3 pb-4 noResult'>Nothing Found</div>
                        </div>


                }
            </ul>

            {/* <ul className='p-0 collectionList d-flex flex-wrap'>
                <li>
                    <a className='rounded-3'>
                        <span className='img rounded-3 overflow-hidden d-block'><img alt="" src={CollectionImg1} className="rounded-3" /></span>
                        <span className='name d-flex align-items-center pt-3 pb-2'><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here </span>
                    </a>
                </li>
                <li>
                    <a className='rounded-3'>
                        <span className='img rounded-3 overflow-hidden d-block'><img alt="" src={CollectionImg2} className="rounded-3" /></span>
                        <span className='name d-flex align-items-center pt-3 pb-2'><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here </span>
                    </a>
                </li>
                <li>
                    <a className='rounded-3'>
                        <span className='img rounded-3 overflow-hidden d-block'><img alt="" src={CollectionImg3} className="rounded-3" /></span>
                        <span className='name d-flex align-items-center pt-3 pb-2'><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here </span>
                    </a>
                </li>
                <li>
                    <a className='rounded-3'>
                        <span className='img rounded-3 overflow-hidden d-block'><img alt="" src={CollectionImg4} className="rounded-3" /></span>
                        <span className='name d-flex align-items-center pt-3 pb-2'><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here </span>
                    </a>
                </li>
                <li>
                    <a className='rounded-3'>
                        <span className='img rounded-3 overflow-hidden d-block'><img alt="" src={CollectionImg5} className="rounded-3" /></span>
                        <span className='name d-flex align-items-center pt-3 pb-2'><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here </span>
                    </a>
                </li>
                <li>
                    <a className='rounded-3'>
                        <span className='img rounded-3 overflow-hidden d-block'><img alt="" src={CollectionImg6} className="rounded-3" /></span>
                        <span className='name d-flex align-items-center pt-3 pb-2'><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here </span>
                    </a>
                </li>
                <li>
                    <a className='rounded-3'>
                        <span className='img rounded-3 overflow-hidden d-block'><img alt="" src={CollectionImg7} className="rounded-3" /></span>
                        <span className='name d-flex align-items-center pt-3 pb-2'><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here </span>
                    </a>
                </li>
                <li>
                    <a className='rounded-3'>
                        <span className='img rounded-3 overflow-hidden d-block'><img alt="" src={CollectionImg8} className="rounded-3" /></span>
                        <span className='name d-flex align-items-center pt-3 pb-2'><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here </span>
                    </a>
                </li>
            </ul> */}
        </>

    )

}
export default Collections;