import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    status: '',
    message: '',
    favoriteData:{},
    favList: []
};

const favouriteDetail = createSlice({
    name: 'favouriteDetail',
    initialState,
    reducers: {
        createFavouritePending: (state) => {
            state.isLoading = true;
        },
        createFavouriteSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload
        },
        createFavouriteError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        createFavouriteResetMessage: (state) => {
            state.message = '';
        },
        getFavouriteListPending: (state) => {
            state.isLoading = true;
        },
        getFavouriteListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.favList = payload
        },
        getFavouriteListError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
           
        },
        getFavouriteListResetMessage: (state) => {
            state.message = '';
        },



       favoriteDeatailPending: (state) => {
            state.isLoading = true;
        },
       favoriteDeatailSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.favoriteData = payload
        },
       favoriteDeatailError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.favoriteData = payload;
            //state.favoriteData=''
        },
       favoriteDeatailResetMessage: (state) => {
            state.message = '';
        },

    },
});

const { reducer, actions } = favouriteDetail;
export const { createFavouritePending, createFavouriteSuccess, createFavouriteError, createFavouriteResetMessage,
    getFavouriteListPending, getFavouriteListSuccess, getFavouriteListError, getFavouriteListResetMessage,
    favoriteDeatailPending,favoriteDeatailSuccess,favoriteDeatailError,favoriteDeatailResetMessage
} = actions;

export default reducer;