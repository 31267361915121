import React, { useEffect } from 'react';
import './MyProfile.scss';
import { NavLink, Link, } from "react-router-dom";
import Header from '../component/Header.js'; 
import Footer from '../component/Footer.js';
import Button from 'react-bootstrap/Button';
import EmptyBox from '../assets/images/empty_box.png';
import UserImg from '../assets/images/user_img.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getNftList } from './redux/action/nftAction';

const UserNft = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoding, status, message, nftList } = useSelector((state) => state.nftItemDetail)
    console.log('collectionList------>', nftList.data)
    useEffect(() => {
        dispatch(getNftList());
    }, [getNftList])


    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-md-5 pb-md-5 pt-4 pb-4'>
                    <div className='container'>
                        <div className='mainHd mb-2 mb-md-2 text-center'>My Collections</div>
                        <div className='noteText mb-5 text-center'>Create, curate, and manage collections of unique NFTs to share and sell.</div>
                        {/* <div className='text-center'><img alt="" src={EmptyBox} /></div>
                        <div className='text-center pt-3 pb-4 noResult'>Nothing Found</div> */}
                        <div className='text-center'><NavLink to="/create-new-collection" activeClassName="active"><Button type="button">Create New Collection</Button></NavLink></div>
                    </div>

                    <div className='container'>
                        <div className='mainHd mb-4 mb-md-5 text-center'></div>
                        <div className='table'>
                            <table>
                                <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Medium</th>
                                    <th>Payment Tokens</th>
                                </tr>
                                {
                                    (nftList.data && nftList.data.length > 0) ?
                                        nftList.data.map((data, i) => (
                                            <tr onClick={() => navigate("/nftList")}>
                                                <td>{i + 1}</td>

                                                {/* <td> <div className='text-nowrap collection'><a><img alt="" src={data.profileImage} className="rounded-circle me-2" /></a></div></td>
                                                <td>{data.collectionName}</td>
                                                <td>{data.category}</td>
                                                <td>{data.medium}</td>
                                                <td>{data.paymentTokens}</td> */}
                                            </tr>
                                        )) : <div>
                                            <div className='text-center'><img alt="" src={EmptyBox} /></div>
                                            <div className='text-center pt-3 pb-4 noResult'>Nothing Found</div>
                                        </div>


                                }









                                {/* 
                                <tr>
                                    <td>1</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /></a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here</a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here</a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here</a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here</a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>
                                        <div className='text-nowrap collection'><a><img alt="" src={UserImg} className="rounded-circle me-2" /> Account name goes here</a></div>
                                    </td>
                                    <td className='text-nowrap'>5,489.46 HBAR</td>
                                    <td className='text-nowrap'>18.56 HBAR</td>
                                    <td className='text-nowrap'>14</td>
                                    <td className='text-nowrap'>204</td>
                                </tr> */}
                            </table>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default UserNft;