import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import './EditProfile.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import { useSelector, useDispatch } from 'react-redux';
import ProfileMenu from '../component/ProfileMenu';
import Button from 'react-bootstrap/Button';
import _ from 'lodash'
import { getUserDetail, updateUserDetail } from './redux/action/userAction';

const initialState = {
    userName: '',
    twitterUsername: '',
    email: '',
    customUrl: '',
    walletAddress: '',
    imageReview: '',
    coverImageReview: '',
    coverImg: '',
    bio: '',
    profileImg: '',
    updatedData: {},
    editEnable: false,
    errors: {},
}
const EditProfile = () => {
    const dispatch = useDispatch();
    const [iState, updateState] = useState(initialState)
    const { profileData } = useSelector((state) => state.userDetail)
    const {
        userName,
        twitterUsername,
        email,
        customUrl,
        walletAddress,
        coverImageReview,
        bio,
        updatedData,
        imageReview,
        profileImg,
        coverImg,
        errors,
    } = iState

    useEffect(() => {
        dispatch(getUserDetail());
    }, [getUserDetail])

    useEffect(() => {
        if (!_.isEmpty(profileData)) {
            const updateData = _.cloneDeep(iState)
            updateData.userName = profileData.data.userName || ''
            updateData.email = profileData.data.email || ''
            updateData.imageReview = profileData.data.profileImg || ''
            updateData.coverImageReview = profileData.data.coverImg || ''
            updateData.bio = profileData.data.bio || ''
            updateData.walletAddress = profileData.data.walletAddress || ''
            updateData.customUrl = profileData.data.customUrl || ''
            updateData.twitterUsername = profileData.data.twitterUsername || ''
            updateState(
                updateData
            )
        }

    }, [profileData])


    const handleInputChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState, [name]: value,
            updatedData: {
                ...iState.updatedData, [name]: value
            }
        })
    }


    const onFileHandler = (e) => {
        const file = e.target.files[0]

        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                previewsFile(file)

            } else {
                toast.error('Only formats are allowed: jpeg, jpg, png files', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }


    const previewsFile = (file) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
            updateState({
                ...iState, imageReview: reader.result, profileImg: file
            })
        }, false)
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const onFileHandler1 = (e) => {
        const file = e.target.files[0]

        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                previewsFile1(file)

            } else {
                toast.error('Only formats are allowed: jpeg, jpg, png files', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }


    const previewsFile1 = (file) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
            updateState({
                ...iState, coverImageReview: reader.result, coverImg: file
            })
        }, false)
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const handleValidation = () => {
        let userNameEmpty = ''
        let emailIdError = '';
        let bioEmpty = ''
        let customUrlEmpty = ''
        let twitterUsernameEmpty = ''
        let formIsValid = true;
        if (!userName.trim()) {
            userNameEmpty = "Please insert name.";
            formIsValid = false;
        }
        if (!twitterUsername.trim()) {
            twitterUsernameEmpty = "Twitter Name can't be empty";
            formIsValid = false;
        }

        if (!email) {
            emailIdError = 'Please enter email ID.';
            formIsValid = false
        } else if (!/^.+?@.+?\..+$/.test(email)) {
            emailIdError = "Email format is not valid";
            formIsValid = false;
        }

        if (!bio.trim()) {
            bioEmpty = "Bio can't be empty";
            formIsValid = false;
        }
        if (!customUrl.trim()) {
            customUrlEmpty = "Custom Url can't be empty"
            formIsValid = false;
        }
        updateState({
            ...iState, errors: { userNameEmpty, emailIdError, bioEmpty, customUrlEmpty, twitterUsernameEmpty }

        })
        return formIsValid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = handleValidation();
        // if (formIsValid) {
            const data = { userName, twitterUsername, email, customUrl, walletAddress, bio, profileImg, coverImg }
            dispatch(updateUserDetail(data)).then(res => {
                if (res.payload.code == 200) {
                    toast.success(res.payload.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    updateState({
                        ...iState, errorMsg: res.message,
                        errors: ''
                    })
                }
            }).catch(err => {
                console.log('err--->', err)
            })
        //}
    }

    return (
        <>
            <div className="mainCon">
                <ToastContainer />
                <Header />

                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='d-flex flex-wrap'>
                            <ProfileMenu />
                            <div className='profileDet'>
                                <div className='mainHd mb-3 mb-md-4'>Edit Profile</div>
                                <div className='d-flex justify-content-between flex-column flex-md-row-reverse'>
                                    {/* <form className='d-flex justify-content-between flex-column flex-md-row-reverse'> */}
                                    <div className='profileImg'>
                                        <ul className='form p-0'>
                                            <li>
                                                <label className='fieldHd d-block pb-2 ps-1'>Profile Image</label>
                                                <div className='imgBox' style={{ maxWidth: "200px", height: "200px", borderRadius: "10px", }}><img src={imageReview} /></div>
                                                <div className='note pt-2'>We recommend an image of at least 300x300. Gifts work too. Max 5mb.</div>
                                                <div className='pt-3'><Button type="button" className='chhoseFile'>Choose File
                                                    <input type='file' className='file' id="imageUpload"
                                                        onChange={onFileHandler}
                                                        onClick={e => e.target.value == null}
                                                        accept=".png, .jpg, .jpeg" /></Button></div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='profileForm'>
                                        <ul className='form p-0'>

                                            <li>
                                                <label className='fieldHd d-block pb-2 ps-1'>Cover Image</label>
                                                <div className='imgBox' style={{ maxWidth: "600px", height: "200px", borderRadius: "10px", }}><img src={coverImageReview} /></div>
                                                <input type='file' className='file' id="imageUpload"
                                                    onChange={onFileHandler1}
                                                    onClick={e => e.target.value == null}
                                                    accept=".png, .jpg, .jpeg" />
                                                {/* <span style={{ color: "red" }}>{errors.featuredImageEmpty}</span> */}

                                            </li>
                                            <li>

                                                <label className='fieldHd d-block pb-2 ps-1'>Name <span className='small'></span></label>
                                                <input type="text" className='formInput' name="userName" value={userName} onChange={handleInputChange} />
                                                <span style={{ color: "red" }}>{errors.userNameEmpty}</span>
                                            </li>

                                            <li>
                                                <label className='fieldHd d-block pb-2 ps-1'>Your email address</label>
                                                <input type="text" className='formInput' name='email'
                                                    disabled={profileData.data && profileData.data.email ? true : false}
                                                    value={email} onChange={handleInputChange} />
                                                <span style={{ color: "red" }}>{errors.emailIdError}</span>

                                            </li>
                                            <li>
                                                <label className='fieldHd d-block pb-2 ps-1'>Custom URL</label>
                                                <input type="text" className='formInput' name="customUrl" value={customUrl} onChange={handleInputChange} />
                                                <span style={{ color: "red" }}>{errors.customUrlEmpty}</span>
                                            </li>
                                            <li>
                                                <label className='fieldHd d-block pb-2 ps-1'>Bio</label>
                                                <textarea rows="3" cols="3" className='formInput textarea' name="bio" value={bio} onChange={handleInputChange}></textarea>
                                                <span style={{ color: "red" }}>{errors.bioEmpty}</span>
                                            </li>
                                            <li>
                                                <label className='fieldHd d-block pb-2 ps-1'>Twitter Username <span className='small'>(Link your twitter account to gain more trust on the marketplace)</span></label>
                                                <input type="text" className='formInput' name="twitterUsername" value={twitterUsername} onChange={handleInputChange} />
                                                <span style={{ color: "red" }}>{errors.twitterUsernameEmpty}</span>
                                            </li>

                                            <li>
                                                <label className='fieldHd d-block pb-2 ps-1'>Wallet Address</label>
                                                <input type="text" className='formInput' disabled value={walletAddress} />
                                            </li>
                                            <li><Button type="button" onClick={handleSubmit}>Update Profile</Button></li>
                                        </ul>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default EditProfile;