import React from "react";
import Slider from "react-slick";
import './RelatedItems.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rotate from '../assets/images/rotate.png';
import itemImg1 from '../assets/images/item-img1.jpg';
import itemImg2 from '../assets/images/item-img2.jpg';
import comingsoonImg from '../assets/images/comingsoon.png';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'



const RelatedItems = () => {

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <>
            <div className='mainHd mb-3 mb-md-4'>Related Items</div>
            <Slider className="relatedItem bottomArrow" {...settings}>
                <div className="itemBox">
                    <div className='img'><a href='#'><img alt="" src={comingsoonImg} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Item: 5</span></div>
                    <div className='userName'>
                        <a>Murano Handcraft 1</a>
                        <span className='d-block pt-1'>Creator Name</span>
                    </div>
                    <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                </div>
                <div className="itemBox">
                    <div className='img'><a href='#'><img alt="" src={comingsoonImg} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Item: 5</span></div>
                    <div className='userName'>
                        <a>Murano Handcraft 2</a>
                        <span className='d-block pt-1'>Creator Name</span>
                    </div>
                    <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                </div>
                <div className="itemBox">
                    <div className='img'><a href='#'><img alt="" src={comingsoonImg} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Item: 5</span></div>
                    <div className='userName'>
                        <a>Murano Handcraft 3</a>
                        <span className='d-block pt-1'>Creator Name</span>
                    </div>
                    <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                </div>
                <div className="itemBox">
                    <div className='img'><a href='#'><img alt="" src={comingsoonImg} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Item: 5</span></div>
                    <div className='userName'>
                        <a>Murano Handcraft 4</a>
                        <span className='d-block pt-1'>Creator Name</span>
                    </div>
                    <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                </div>
                <div className="itemBox">
                    <div className='img'><a href='#'><img alt="" src={comingsoonImg} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Item: 5</span></div>
                    <div className='userName'>
                        <a>Murano Handcraft 5</a>
                        <span className='d-block pt-1'>Creator Name</span>
                    </div>
                    <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                </div>
                <div className="itemBox">
                    <div className='img'><a href='#'><img alt="" src={comingsoonImg} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                    <div className='userName'>
                        <a>Tesst Sample 6</a>
                        <span className='d-block pt-1'>Creator Name</span>
                    </div>
                    <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                </div>
            </Slider>
        </>

    )

}
export default RelatedItems;