import React, { useState, useEffect } from 'react';
import rotate from '../assets/images/rotate.png';
import watchImg1 from '../assets/images/watch_img1.png';
import watchImg2 from '../assets/images/watch_img2.png';
import watchImg3 from '../assets/images/watch_img3.png';
import watchImg4 from '../assets/images/watch_img4.png';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFavList } from '../pages/redux/action/favouriteAction';
import { getNftDetail } from '../pages/redux/action/nftAction';

const Favorite = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { favList } = useSelector((state) => state.favouriteDetail)
    useEffect(() => {
        dispatch(getFavList());
    }, [getFavList])

    const nftDetail = (id, userid) => {
        dispatch(getNftDetail({ nftItemId: id, userId: userid }))
        localStorage.setItem('nftItemId', JSON.stringify(id));
        localStorage.setItem('userId', JSON.stringify(userid));
        navigate(`/product-detail`)
    }

    return (

        <>
            <ul className='p-0 productList d-flex flex-wrap'>

                {
                    (favList.data && favList.data.length > 0) ?
                        favList.data.map((data, i) => (

                            <li>
                                <div className='inner'>
                                    <div className='img'><a><img alt="" src={data.itemProfile} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: {data.nftItemCount}</span></div>
                                    <div className='userName'>
                                        <a>{data.itemName}</a>
                                        <span className='d-block pt-1'>{data.userName}</span>
                                    </div>
                                    {
                                        data.amount ? <div className='price'>Price <span className='d-block mt-1'>{data.amount} HBAR</span></div> : ''
                                    }
                                </div>
                            </li>
                        )) : ""


                }
            </ul>

            {/* <ul className='p-0 productList d-flex flex-wrap'>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg1} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg2} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg3} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg4} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
            </ul> */}
        </>

    )

}
export default Favorite;