import React, { } from 'react';
import './About.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import Video from '../assets/images/video_img.png';
import PlayIcon from '../assets/images/play_icon.png';
import VideoLeft from '../assets/images/video_left.png';
import VideoRight from '../assets/images/video_right.png';
import aboutImg from '../assets/images/about_img.png';
import Founded from '../assets/images/founded.png';
import Employee from '../assets/images/employee.png';
import Collections from '../assets/images/collections.png';
import Nft from '../assets/images/nft.png';
import Volume from '../assets/images/volume.png';
import OurStory from '../assets/images/our-story.png';
import BrileyBarnett from '../assets/images/Briley-Barnett.png';
import NathaliaMata from '../assets/images/Nathalia-Mata.png';
import JaylynnMurphy from '../assets/images/Jaylynn-Murphy.png';
import ChaimMcgee from '../assets/images/Chaim-Mcgee.png';
import LiviaAndrews from '../assets/images/Livia-Andrews.png';
import LiviaAndrews2 from '../assets/images/Livia-Andrews2.png';
import KaitlynEsparza from '../assets/images/Kaitlyn-Esparza.png';
import blogImg1 from '../assets/images/blog_img1.jpg';
import blogImg2 from '../assets/images/blog_img2.jpg';
import blogImg3 from '../assets/images/blog_img3.jpg';
import blogImg4 from '../assets/images/blog_img4.jpg';
const About = () => {



    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='grayBg d-flex flex-wrap'>
                    <div className='aboutTopLeft d-flex justify-content-end align-items-center'>
                        <div className='inner'>
                            <div className='largeHd mb-2 mb-md-4'>Building Antique<br /> NFT World</div>
                            <div className='aboutText'>
                                <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus BonorMalorum for use in a type specimen book. It usually begins with </p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod teincididunt ut labore et dolore magna aliqua. The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout. A practice not controversy, laying out pages with meaningless filler text can be very useful when the focus is meant to be on design, not content.</p>
                                <p>The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.</p>
                            </div>
                        </div>
                    </div>
                    <div className='aboutTopRight d-none pt-2 ps-4 d-lg-flex align-items-end'><img alt="" src={aboutImg} /></div>
                </div>
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <ul className='aboutStatic text-center d-flex flex-wrap p-0 m-0'>
                            <li><span className='icon d-block'><img alt="" src={Founded} /></span> <span className='d-block pt-2'>2022</span> Founded</li>
                            <li><span className='icon d-block'><img alt="" src={Employee} /></span> <span className='d-block pt-2'>100+</span> Employees</li>
                            <li><span className='icon d-block'><img alt="" src={Collections} /></span> <span className='d-block pt-2'>2M+</span> Collections</li>
                            <li><span className='icon d-block'><img alt="" src={Nft} /></span> <span className='d-block pt-2'>80M+</span> NFTs</li>
                            <li><span className='icon d-block'><img alt="" src={Volume} /></span> <span className='d-block pt-2'>$20B+</span> Volume</li>
                        </ul>
                    </div>
                </div>
                <div className='pb-4 pb-sm-5 overflow-hidden'>
                    <div className='container'>
                        <div className='mainHd mb-3 mb-md-4 text-center'>Meet Ledex Protocol</div>
                        <div className='videoBox position-relative'>
                            <div className='video'><img alt="" src={Video} /> <span className='play'><img alt="" src={PlayIcon} /></span></div>
                            <div className='videoLeftImg'><img alt="" src={VideoLeft} /></div>
                            <div className='videoRightImg'><img alt="" src={VideoRight} /></div>
                        </div>
                    </div>
                </div>
                <div className='pb-4 pb-sm-5'>
                    <div className='container'>
                        <div className='d-flex flex-wrap align-items-center'>
                            <div className='col-12 col-lg-6'>
                                <div className="mainHd mb-3 mb-md-4">Our Story</div>
                                <div className='aboutText'>
                                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus BonorMalorum for use in a type specimen book. It usually begins with </p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod teincididunt ut labore et dolore magna aliqua. The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout. A practice not controversy, laying out pages with meaningless filler text can be very useful when the focus is meant to be on design, not content.</p>
                                    <p>The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.</p>
                                </div>
                            </div>
                            <div className='col-6 ps-5 d-none d-lg-block'><img alt="" src={OurStory} /></div>
                        </div>
                    </div>
                </div>
                <div className='grayBg pb-4 pb-sm-5 pt-4 pt-sm-5'>
                    <div className='container'>
                        <div className='mainHd  mb-3 mb-md-4 text-center'>Meet Our Team</div>
                        <ul className='teamList d-flex flex-wrap p-0 text-center justify-content-center'>
                            <li>
                                <img className='mb-3' alt="" src={BrileyBarnett} /> Briley Barnett <span className='d-block pt-1'>Designation</span>
                            </li>
                            <li>
                                <img className='mb-3' alt="" src={NathaliaMata} /> Nathalia Mata <span className='d-block pt-1'>Designation</span>
                            </li>
                            <li>
                                <img className='mb-3' alt="" src={JaylynnMurphy} /> Jaylynn Murphy <span className='d-block pt-1'>Designation</span>
                            </li>
                            <li>
                                <img className='mb-3' alt="" src={ChaimMcgee} /> Chaim Mcgee <span className='d-block pt-1'>Designation</span>
                            </li>
                            <li>
                                <img className='mb-3' alt="" src={LiviaAndrews} /> Livia Andrews <span className='d-block pt-1'>Designation</span>
                            </li>
                            <li>
                                <img className='mb-3' alt="" src={LiviaAndrews2} /> Livia Andrews <span className='d-block pt-1'>Designation</span>
                            </li>
                            <li>
                                <img className='mb-3' alt="" src={KaitlynEsparza} /> Kaitlyn Esparza <span className='d-block pt-1'>Designation</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='pb-4 pb-sm-5 pt-4 pt-sm-5'>
                    <div className='container'>
                        <div className='mainHd mb-3 mb-md-4 text-center'>From The News</div>
                        <ul className='blogList d-flex flex-wrap p-0'>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg1} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg2} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg3} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <figure className='m-0'>
                                        <span className='img d-block mb-2'><img alt="" src={blogImg4} /></span>
                                        <span className='date d-block mb-2'>Jul 27, 2022 &bull; 3 min read</span>
                                        <span className='hd'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</span>
                                    </figure>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default About;