import React, { } from 'react';
import './HomeBanner.scss';
import bannerImg from '../assets/images/banner_bg.jpg';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import video from '../assets/video.mp4';
import { NavLink, Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeBanner = () => {
    AOS.init({
        once: true,
        duration: 1200,
        });



    return (
        <>
            {/* <div className='bannerCon' style={{ backgroundImage: 'url(' + bannerImg + ')', backgroundPosition: 'center' }}>
                <div className='container d-flex align-items-center'>
                    <div className='bannerIner'>
                        <div className='bannerHd mb-3'>Discover The Extraordinary NFTs</div>
                        <div className='bannerText mb-4'>Discover the best & limited collection of NFTs in the world easily and without stress and with full access to our site mega NFTs.</div>
                        <NavLink to="/explore-collections"><div><a  className='exploreLink'>Explore Collections <ArrowForwardSharpIcon className='icon' /></a></div></NavLink>
                    </div>
                </div>
            </div> */}

            <div className='bannerCon' style={{ backgroundImage: 'url(' + bannerImg + ')', backgroundPosition: 'center' }}>
                <div className='bannerVideoBox'>
                    <div className='videoInner'>
                        <video width="320" height="240" loop autoPlay muted>
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div className='container d-flex align-items-center'>
                    <div className='bannerIner' data-aos="fade-up">
                        <div className='bannerHd mb-3' data-aos="fade-up">Discover The Extraordinary NFTs</div>
                        <div className='bannerText mb-4' data-aos="fade-up">Discover the best & limited collection of NFTs in the world easily and without stress and with full access to our site mega NFTs.</div>
                        <div data-aos="fade-up"><Link to='/explore-collections' className='exploreLink'>Explore Collections <ArrowForwardSharpIcon className='icon' /></Link></div>
             
                    </div>
                </div>
            </div>
        </>

    )

}
export default HomeBanner;