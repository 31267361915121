import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import './Admin.scss';
import AdminFooter from '../../component/AdminFooter'
import logo from '../../assets/images/logo.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { adminVeriyOtps, resendOtps } from '../redux/action/adminAction';
const initialState = {
    otp: '',
    otpError: '',
    errorMsg: ''
}
const Verification = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [iState, updateState] = useState(initialState)
    const {
        otp,
        otpError,
        errorMsg
    } = iState

    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState, [name]: value

        })
    }

    let handleValidation = () => {
        let otpError = '';
        let formIsValid = true;

        if (!otp) {
            otpError = 'Please enter otp.';
            formIsValid = false
        }
        updateState({
            ...iState, otpError, errorMsg
        });
        return formIsValid

    }

    const ResendOtp = (e) => {
        e.preventDefault()
        const emailPhone = localStorage.getItem('emailM');
        const type = localStorage.getItem('types');
        let data = { emailPhone, type }
        dispatch(resendOtps(data)).then(res => {
            if (res.payload.code == 200) {
                localStorage.setItem('adminEmail', res.payload.data.email);
                localStorage.setItem('adminid', res.payload.data._id);
                toast.success(res.payload.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.payload.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                updateState({
                    ...iState, errorMsg: res.message,
                    errors: ''
                })
            }
        }).catch(err => {
            console.log(`error>>>> ${err}`)
        })
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = handleValidation();
        if (formIsValid) {
            let data = { otp }
            dispatch(adminVeriyOtps(data)).then(res => {
                if (res.payload.code == 200) {
                    localStorage.setItem('adminEmail', res.payload.data.email);
                    localStorage.setItem('adminid', res.payload.data._id);
                    toast.success(res.payload.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    navigate('/reset-password')
                } else {
                    toast.error(res.payload.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    updateState({
                        ...iState, errorMsg: res.message,
                        errors: ''
                    })
                }
            })
        }
    }



    return (
        <>
            <div className='adminTop d-flex align-items-center justify-content-center'>
                <div className='w-100'>
                    <div className='mb-4 mb-md-5 text-center'><img alt="" src={logo} /></div>
                    <div className='loginForm'>
                        <div className='inner'>
                            <div className='loginHd text-center mb-3'>Verification</div>
                            <div className='hdText text-center mb-4'>Enter the code that have been sent to the registered email address.</div>
                            <ul className='form p-0'>
                                <li>
                                    <input type="text" className='formInput' placeholder='Enter Code' name='otp' value={otp} onChange={handleChange} />
                                    <span style={{ color: "red" }}>{otpError}</span>
                                </li>
                                <li><NavLink><Button type="button" className='full' onClick={handleSubmit}>Verify</Button></NavLink></li>
                            </ul>
                            <div className='mt-4 text-center hdText'>Didn't receive the code? <Link onClick={ResendOtp}>Resend</Link></div>
                        </div>
                    </div>
                    <div className='text-center pt-4'><NavLink to="/forgot-password" className='back'>Back</NavLink></div>
                </div>
            </div>
            <AdminFooter />
        </>

    )

}
export default Verification;