import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import './Admin.scss';
import AdminFooter from '../../component/AdminFooter'
import logo from '../../assets/images/logo.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { resetAdminPass } from '../redux/action/adminAction';

const initialState = {
    password: '',
    cpassword: '',
    passwordEmpty:'',
    cpasswordEmpty:'',
    errorMsg: ''
}

const ResetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [iState, updateState] = useState(initialState)
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const email = localStorage.getItem('adminEmail');
    const adminId = localStorage.getItem('adminid');

    const {
        password,
        cpassword,
        passwordEmpty,
        cpasswordEmpty,
        errorMsg,
    } = iState

    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }

    const [passwordType2, setPasswordType2] = useState("password");
    const [passwordInput2, setPasswordInput2] = useState("");
    const handlePasswordChange2 =(evnt)=>{
        setPasswordInput2(evnt.target.value);
    }
    const togglePassword2 =()=>{
      if(passwordType2==="password")
      {
       setPasswordType2("text")
       return;
      }
      setPasswordType2("password")
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState, [name]: value,
            updatedData: {
                ...iState, [name]: value
            }
        })
    }

    const handleValidation = () => {
        let passwordEmpty = ''
        let cpasswordEmpty = ''
        let formIsValid = true;

        if (!password.trim()) {
            passwordEmpty = "Please insert password.";
            formIsValid = false;
        }
        if (!cpassword.trim()) {
            cpasswordEmpty = "Please insert confirm password.";
            formIsValid = false;
        }
        if (password && cpassword && (password !== cpassword)) {
            cpasswordEmpty = "Confirm password must be same as new password.";
            formIsValid = false;
        }
        updateState({
              ...iState, passwordEmpty,cpasswordEmpty, errorMsg

        })
        return formIsValid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = handleValidation();
        if (formIsValid) {
            const data = {newPassword:password,email,adminId }
            dispatch(resetAdminPass(data)).then(res => {
              
                if (res.payload.code == 200) {
                    toast.success(res.payload.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    navigate('/password-reset-successfull')
                } else {
                    toast.error(res.payload.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    updateState({
                        ...iState, errorMsg: res.message,
                        errors: ''
                    })
                }
            })
        }
    }

    return (
        <>
            <div className='adminTop d-flex align-items-center justify-content-center'>
                <div className='w-100'>
                    <div className='mb-4 mb-md-5 text-center'><img alt="" src={logo} /></div>
                    <div className='loginForm'>
                        <div className='inner'>
                            <div className='loginHd text-center mb-4'>Reset Password</div>
                            <ul className='form p-0'>
                                <li>
                                    <div className='position-relative'>
                                    <input type={passwordType} onChange={handleInputChange} name="password" value={password} className='formInput' placeholder='New Password'  />
                                    <a className='eye' onClick={togglePassword}>{ passwordType==="password"? <RemoveRedEyeOutlinedIcon /> :<VisibilityOffOutlinedIcon /> }</a>
                                    </div>
                                    <span style={{ color: "red" }}>{passwordEmpty}</span>
                                </li>
                                <li>
                                    <div className='position-relative'>
                                    <input type={passwordType2} onChange={handleInputChange} name="cpassword" value={cpassword} className='formInput' placeholder='Confirm Password' />
                                    
                                    <a className='eye' onClick={togglePassword2}>{ passwordType2==="password"? <RemoveRedEyeOutlinedIcon /> :<VisibilityOffOutlinedIcon /> }</a>
                                    </div>
                                    <span style={{ color: "red" }}>{cpasswordEmpty}</span>
                                </li>
                                <li><NavLink ><Button type="button" className='full' onClick={handleSubmit}>Reset Password</Button></NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <AdminFooter />
        </>

    )

}
export default ResetPassword;