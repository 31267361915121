import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Header from './Header';
import Footer from './Footer';
import { useSelector, useDispatch } from 'react-redux';
import { getCollectionsList, getCollectionDetail } from '../pages/redux/action/collectionAction';

const Utility = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoding, status, message, nftList } = useSelector((state) => state.nftItemDetail)
    const { collectionList } = useSelector((state) => state.collectionDetail)
    useEffect(() => {
        dispatch(getCollectionsList('Utility'));
    }, [getCollectionsList])


    const collectionDetail = (id) => {
        dispatch(getCollectionDetail())
        localStorage.setItem('collectionId', JSON.stringify(id));
        navigate(`/collection-details`)
    }

    return (

        <>
            <div className="mainCon">
                <Header />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='mainHd pb-4'>Utility Collections</div>
                        <ul className='p-0 collectionList d-flex flex-wrap'>
                            {
                                (collectionList.data && collectionList.data.length > 0) ?
                                    collectionList.data.map((data, i) => (

                                        <li >
                                            <a className='rounded-3' onClick={() => collectionDetail(data._id)} >
                                                <span className='img rounded-3 overflow-hidden d-block'><img alt="" src={data.profileImage} className="rounded-3" /></span>
                                                <span className='name d-flex align-items-center pt-3 pb-2'><img alt="" src={data.profileImg} className="rounded-circle me-2" />{data.collectionName}  {data.authentication == 'enable' ? <CheckCircleIcon className='check ms-1' /> : ''}</span>
                                            </a>
                                        </li>


                                    )) : ""


                            }
                        </ul>

                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default Utility;