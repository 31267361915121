import axios from 'axios';
import config from '../config/config';
const url = `${config.Url}user/`


export const createBiding = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(`${url}createBiding`, frmData, {
                headers: {
                    token: localStorage.getItem('accessJWT'),
                },
            });

            resolve(res.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getAllBidingList = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${url}getBidingList?nftItemId=${frmData}`, {
                headers: {
                    token: localStorage.getItem('accessJWT'),
                },
            });
            resolve(result);
            console.log('result-------->', result.data)
        } catch (error) {
            reject(error);
        }
    });
};

export const getBidingDetail = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${url}getBidingDetails?nftItemId=${frmData}`, {
                headers: {
                    token: localStorage.getItem('accessJWT'),
                },
            });
            resolve(result);
            console.log('result-------->', result.data)
        } catch (error) {
            reject(error);
        }
    });
};

export const claimStatus = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.put(`${url}updateClaimStatus`, frmData, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};
