
import { createFavourite, getAllFavourite, fetchFavorite } from '../../../api/favouriteApi';
import {
    createFavouritePending, createFavouriteSuccess, createFavouriteError, createFavouriteResetMessage,
    getFavouriteListPending, getFavouriteListSuccess, getFavouriteListError, getFavouriteListResetMessage,
    favoriteDeatailPending, favoriteDeatailSuccess, favoriteDeatailError, favoriteDeatailResetMessage
} from '../reducer/favouriteReducer';
import { multiPartData } from '../../../utils';



export const createFav = (frmDt) => async (dispatch) => {
    try {
        dispatch(createFavouritePending());
        const result = await createFavourite(frmDt);

        if (result.code == 200) {
            return dispatch(createFavouriteSuccess(result))
        } else {
            return dispatch(createFavouriteError(result));
        }
    } catch (error) {
        dispatch(createFavouriteResetMessage(error.message));
    }
};

export const getFavList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getFavouriteListPending());
        const result = await getAllFavourite();
        result.data.code === 200
            ? dispatch(getFavouriteListSuccess(result.data))
            : dispatch(getFavouriteListError(result.message));


    } catch (error) {
        dispatch(getFavouriteListResetMessage(error.message));
    }
};

export const getFavoriteDetail = (frmDt) => async (dispatch) => {
    try {
        dispatch(favoriteDeatailPending());
        const result = await fetchFavorite(frmDt);
        result.code === 200
            ? dispatch(favoriteDeatailSuccess(result))
            : dispatch(favoriteDeatailError(result.message));


    } catch (error) {
        dispatch(favoriteDeatailResetMessage(error.message));
    }
};