import axios from 'axios';
import config from '../config/config';
const url = `${config.Url}user/`
export const getAllSearch = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			let data = frmData ? frmData : ""
			const result = await axios.get(`${url}allSearchList?search=${data}`, {
				headers: {
				},
			});

			resolve(result);
			console.log('result-------->', result.data)
		} catch (error) {
			reject(error);
		}
	});
};