import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import logo from '../assets/images/logo.png'
import UserImg from '../assets/images/user_img.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { getAdminDetail } from '../pages/redux/action/adminAction';

const AdminHeader = () => {
    const dispatch = useDispatch();
    const { profileData } = useSelector((state) => state.admin)

    useEffect(() => {
        dispatch(getAdminDetail());

    }, [getAdminDetail])

    return (
        <>
            <header className='dbHeader d-flex justify-content-between'>
                <div className='dbLogo d-flex justify-content-center align-items-center'><img alt="" src={logo} /></div>
                <NavLink activeClassName="active"><div className='dbUser position-relative d-flex align-items-center ms-3'><a className='position-relative d-block'><img alt="" src={profileData.data && profileData.data.profileImg} className="rounded-circle me-2" /> <span className='d-sm-inline d-none'>{profileData.data && profileData.data.userName}</span></a></div></NavLink>
            </header>
        </>

    )

}
export default AdminHeader;