import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Switch from '@mui/material/Switch';
import { useSelector, useDispatch } from 'react-redux';
import './CreateNewCollection.scss';
import Modal from 'react-bootstrap/Modal';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AddIcon from '@mui/icons-material/Add';
import ReactLoading from 'react-loading';
import { useNavigate, Link } from "react-router-dom";
import { Mint } from '../helpers/hashconnect';
import { createNft, uploadImages } from './redux/action/nftAction';
import { getUserCollectionsList, getTokenAdd } from './redux/action/collectionAction';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const initialState = {
    itemName: '',
    externalLink: '',
    collectionId: '',
    description: '',
    propertyList: [{ properties: [""], levels: [""], stats: [""] }],
    levelsList: [{ levels: '' }],
    statsList: [{ stats: '' }],
    Blockchain: '',
    itemImage: '',
    imageReview: '',
    featuredImg:'',
    featuredImgReview: '',
    showModel: false,
    levelsModel: false,
    statsModel: false,
    buttonDisable: false,
    updatedData: {},
    errors: {},
}
const CreateNewItem = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [iState, updateState] = useState(initialState)
    const [uploadImg, setUploadImg] = useState(false);
    const [uploadImg1, setUploadImg1] = useState(false);
    const [mediaType, setMediaType] = useState('')
    const [tokenAddress, setTokenAddress] = useState('')
    const { isLoding, status, message } = useSelector((state) => state.nftItemDetail)
    const { userCollectionList } = useSelector((state) => state.collectionDetail)
    let userName = localStorage.getItem('userName')
    const {
        itemName,
        externalLink,
        collectionId,
        description,
        propertyList,
        Blockchain,
        itemImage,
        updatedData,
        imageReview,
        featuredImg,
        featuredImgReview,
        showModel,
        levelsModel,
        statsModel,
        buttonDisable,
        errors,
    } = iState

    useEffect(() => {
        dispatch(getUserCollectionsList());
    }, [getUserCollectionsList])



    const openPropertiesModal = () => {
        updateState({
            ...iState, showModel: true
        })
    }
    const handleClose = () => {
        updateState({
            ...iState, showModel: false
        })
    }

    const openLevelsModal = () => {
        updateState({
            ...iState, levelsModel: true
        })
    }
    const handleLevelsClose = () => {
        updateState({
            ...iState, levelsModel: false
        })
    }

    const openStatsModal = () => {
        updateState({
            ...iState, statsModel: true
        })
    }
    const handleStatsClose = () => {
        updateState({
            ...iState, statsModel: false
        })
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        console.log('value----->', value)
        updateState({
            ...iState, [name]: value,
            updatedData: {
                ...iState.updatedData, [name]: value
            }
        })
    }

    const handleCoolectionInputChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState, collectionId: value,
        })
        dispatch(getTokenAdd(value)).then(res => {

            if (res.payload.code == 200) {
                setTokenAddress(res.payload.data.smartContract)

            }
        })

    }


    const handleInputChangeProperty1 = (e, index, pIndex) => {
        const { value } = e.target;
        const list = [...propertyList];
        let pItem = list[pIndex].properties;
        pItem[index] = value;
        updateState({
            ...iState,
            propertyList: list,
        });
    };

    const handleRemove1 = (e, index, pIndex) => {
        e.preventDefault();
        const list = [...propertyList];
        let pItem = list[pIndex].properties;
        pItem.splice(index, 1);
        updateState({
            ...iState,
            contents: list,
        });
    };

    const handleAdd1 = (index) => {
        console.log('indexqqq-------->', index)
        let arr = [...propertyList];
        console.log('arr----->', arr)
        let selectInd = arr[index];
        selectInd.properties.push("");
        updateState({
            ...iState,
            contents: arr,
        });
    };


    const handleInputChangeProperty2 = (e, index, pIndex) => {
        const { value } = e.target;
        const list = [...propertyList];
        let pItem = list[pIndex].levels;
        pItem[index] = value;
        updateState({
            ...iState,
            propertyList: list,
        });
    };

    const handleRemove2 = (e, index, pIndex) => {
        e.preventDefault();
        const list = [...propertyList];
        let pItem = list[pIndex].levels;
        pItem.splice(index, 1);
        updateState({
            ...iState,
            contents: list,
        });
    };

    const handleAdd2 = (index) => {
        let arr = [...propertyList];
        let selectInd = arr[index];
        selectInd.levels.push("");
        updateState({
            ...iState,
            contents: arr,
        });
    };
    const handleInputChangeProperty3 = (e, index, pIndex) => {
        const { value } = e.target;
        const list = [...propertyList];
        let pItem = list[pIndex].stats;
        pItem[index] = value;
        updateState({
            ...iState,
            propertyList: list,
        });
    };

    const handleRemove3 = (e, index, pIndex) => {
        e.preventDefault();
        const list = [...propertyList];
        let pItem = list[pIndex].stats;
        pItem.splice(index, 1);
        updateState({
            ...iState,
            contents: list,
        });
    };

    const handleAdd3 = (index) => {
        let arr = [...propertyList];
        let selectInd = arr[index];
        selectInd.stats.push("");
        updateState({
            ...iState,
            contents: arr,
        });
    };


    const addProperties = () => {
        const list = [propertyList.length = 1]
        updateState({
            ...iState, showModel: false
        })
    }


    const addLevels = () => {
        const list = [propertyList.length = 1]
        updateState({
            ...iState, levelsModel: false,
        })
    }

    const addStats = () => {
        const list = [propertyList.length = 1]
        updateState({
            ...iState, statsModel: false,
        })
    }



    const onFileHandler = (e) => {
        const file = e.target.files[0]
        if (file.type === 'video/mp4') {
            setMediaType('video')
        } else {
            setMediaType('image')
        }
        if (file) {
            previewsFile(file)
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'video/mp4' || file.type === 'image/gif') {
                previewsFile(file)
                const data = {
                    itemImages: file

                }
                dispatch(uploadImages(data)).then(res => {
                    if (res.payload.code == 200) {
                        setUploadImg(res.payload.data.image)

                    }
                })
            }
            else {
                toast.error('Only formats are allowed: jpeg, jpg, png,mp4 files', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }

    const onFileHandler1 = (e) => {
        const file = e.target.files[0]
        if (file.type === 'video/mp4') {
            setMediaType('video')
        } else {
            setMediaType('image')
        }
        if (file) {
            previewsFile1(file)
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'video/mp4' || file.type === 'image/gif') {
                previewsFile1(file)
                const data = {
                    itemImages: file

                }
                dispatch(uploadImages(data)).then(res => {
                    if (res.payload.code == 200) {
                        setUploadImg1(res.payload.data.image)

                    }
                })
            }
            else {
                toast.error('Only formats are allowed: jpeg, jpg, png,mp4 files', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }


    const previewsFile = (file) => {

        const reader = new FileReader()
        reader.addEventListener("load", () => {

            updateState({
                ...iState, imageReview: reader.result, itemImage: file,
            })



        }, false)
        if (file) {
            reader.readAsDataURL(file)

        }
    }

    const previewsFile1 = (file) => {

        const reader = new FileReader()
        reader.addEventListener("load", () => {

            updateState({
                ...iState, featuredImgReview: reader.result, featuredImg: file,
            })



        }, false)
        if (file) {
            reader.readAsDataURL(file)

        }
    }

    const handleValidation = () => {
        let itemNameEmpty = ''
        let externalLinkEmpty = ''
        let descriptionEmpty = ''
        let BlockchainEmpty = ''
        let collectionIdEmpty = ''
        let propertiesEmpty = ''
        let levelsEmpty = ''
        let formIsValid = true;


        if (!itemName.trim()) {
            itemNameEmpty = "Please insert NFT item name.";
            formIsValid = false;
        }
        if (!externalLink.trim()) {
            externalLinkEmpty = "Please insert external Link";
            formIsValid = false;
        }

        if (!collectionId.trim()) {
            collectionIdEmpty = "Please insert collection name.";
            formIsValid = false;
        }
        if (!description.trim()) {
            descriptionEmpty = "Please insert discription"
            formIsValid = false;
        }
        if (!Blockchain.trim()) {
            BlockchainEmpty = "Please select Block chain"
            formIsValid = false;
        }

        updateState({
            ...iState, errors: {
                itemNameEmpty, externalLinkEmpty, descriptionEmpty, BlockchainEmpty,
                collectionIdEmpty, propertiesEmpty, levelsEmpty,
            }

        })
        return formIsValid;
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = handleValidation();
        var timestamp = new Date().getUTCMilliseconds();
        if (formIsValid) {
            updateState({
                ...iState, buttonDisable: true
            })
            const mint = await Mint(uploadImg, tokenAddress, timestamp)
            console.log(mint,"mint")
            if (mint) {
                const data = {
                    itemName,
                    externalLink,
                    collectionId,
                    description,
                    properties: JSON.stringify(propertyList),
                    Blockchain,
                    itemImage: uploadImg,
                    featuredImg: uploadImg1, 
                    mediaType: mediaType,
                    transactionHas: mint.transactionHash,
                    transactionId: mint.transactionId,
                    tokenId: timestamp,
                    ownerName: userName
                }
                dispatch(createNft(data)).then(res => {
                    if (res.payload.code == 200) {
                        toast.success(res.payload.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        navigate('/product-listing')

                    } else {
                        updateState({
                            ...iState, buttonDisable: false, errorMsg: res.message,
                            errors: ''
                        })
                    }
                })

            }

        }
    }



    return (
        <>
            <div className="mainCon" >
                <Header />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='mainHd mb-3 mb-md-4 text-center'>Create New Item</div>
                        <div className='collectionForm'>
                            <ul className='form p-0'>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Image, Video, or 3D Model* <span className='small d-block mt-1 pb-1'>File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, WAV, OGG, GLB, GLTF. Max size: 100 MB</span></label>
                                    <div className='imgBox' style={{ maxWidth: "400px", height: "200px", borderRadius: "10px", }}><img src={imageReview} /></div>
                                    <div className='choosePhoto'>Choose Photo
                                        <input type='file' className='file' id="imageUpload"
                                            onChange={onFileHandler}
                                            onClick={e => e.target.value == null}
                                            accept=".png, .jpg, .jpeg" />
                                        {/* <span style={{ color: "red" }}>{errors.itemImageEmpty}</span> */}
                                    </div>
                                </li>
                                
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>NFT Featured Image*<span className='small d-block mt-1 pb-1'>File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, WAV, OGG, GLB, GLTF. Max size: 100 MB</span></label>
                                    <div className='imgBox' style={{ maxWidth: "400px", height: "200px", borderRadius: "10px", }}><img src={featuredImgReview} /></div>
                                    <div className='choosePhoto'>Choose Photo
                                        <input type='file' className='file' id="imageUpload1"
                                            onChange={onFileHandler1}
                                            onClick={e => e.target.value == null}
                                            accept=".png, .jpg, .jpeg" />
                                        {/* <span style={{ color: "red" }}>{errors.itemImageEmpty}</span> */}
                                    </div>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Name*</label>
                                    <input type="text" className='formInput' name="itemName" value={itemName} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.itemNameEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>External Link <span className='small d-block mt-1 pb-1'>Platform will include a link to this URL on this item's detail page, so that users can click to learn more about it. You are welcome to link to your own webpage with more details.</span></label>
                                    <input type="text" className='formInput' name="externalLink" value={externalLink} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.externalLinkEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Description <span className='small d-block mt-1 pb-1'>The description will be included on the item's detail page underneath its image. Markdown syntax is supported.</span></label>
                                    <textarea rows="4" cols="4" className='formInput textarea' name="description" value={description} onChange={handleInputChange}></textarea>
                                    <span style={{ color: "red" }}>{errors.descriptionEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Collection <span className='small d-block mt-1 pb-1'>This is the collection where your item will appear.</span></label>
                                    <div className='selectBox'>
                                        <select className='formInput' name="collectionId" value={collectionId} onChange={handleCoolectionInputChange}>
                                            <option>Select Collection</option>
                                            {userCollectionList.data && userCollectionList.data.length > 0 && userCollectionList.data.map((collection, i) =>
                                                <option key={i} value={collection._id}>{collection.collectionName}</option>
                                            )}

                                        </select>
                                    </div>
                                    <span style={{ color: "red" }}>{errors.collectionIdEmpty}</span>
                                </li>
                                <li>
                                    <div className='itemBox d-flex align-items-center justify-content-between'>
                                        <div className='fieldHd'>Properties <span className='small d-block mt-2'>Textual traits that show up as rectangles</span></div>
                                        <div className='ms-2'><a className='AddIcon d-flex align-items-center justify-content-center' onClick={() => openPropertiesModal()}><AddIcon /></a></div>
                                    </div>
                                    {/* <span style={{ color: "red" }}>{errors.discordEmpty}</span> */}
                                </li>
                                <li>
                                    <div className='itemBox d-flex align-items-center justify-content-between'>
                                        <div className='fieldHd'>Levels <span className='small d-block mt-2'>Numerical traits that show as a progress bar</span></div>
                                        <div className='ms-2'><a className='AddIcon d-flex align-items-center justify-content-center' onClick={() => openLevelsModal()}><AddIcon /></a></div>
                                    </div>
                                    {/* <span style={{ color: "red" }}>{errors.discordEmpty}</span> */}
                                </li>
                                <li>
                                    <div className='itemBox d-flex align-items-center justify-content-between'>
                                        <div className='fieldHd'>Stats <span className='small d-block mt-2'>Numerical traits that just show as numbers</span></div>
                                        <div className='ms-2'><a className='AddIcon d-flex align-items-center justify-content-center' onClick={() => openStatsModal()}><AddIcon /></a></div>
                                    </div>
                                    {/* <span style={{ color: "red" }}>{errors.discordEmpty}</span> */}
                                </li>

                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Blockchain</label>
                                    <div className='selectBox'>
                                        <select className='formInput' name="Blockchain" value={Blockchain} onChange={handleInputChange}>
                                            <option>Select blockchain</option>
                                            <option value="HBAR">HBAR</option>
                                        </select>
                                    </div>
                                    <span style={{ color: "red" }}>{errors.BlockchainEmpty}</span>
                                </li>
                                <li><Button type="button" onClick={handleSubmit} disabled={buttonDisable}>Create</Button></li>
                            </ul>
                            {
                                buttonDisable ? <div className='loader'>
                                    <ReactLoading type="spin" color="#fff" height={'20%'} width={'20%'} />
                                </div> : ""
                            }


                            <Modal show={showModel} onHide={handleClose} >

                                <Modal.Header closeButton>
                                    <Modal.Title style={{ color: "black" }}>Add Properties</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ color: "black" }}>
                                    <div className='statsHd'> Properties show up underneath your item, are clickable, and can be filtered in your collection's sidebar.</div>

                                    <div>
                                        {
                                            propertyList.map((data, pIndex) => {
                                                return (


                                                    <div className='formBox'>
                                                        <label className='fieldHd d-block pb-2 ps-1'>Name*</label>
                                                        <div>
                                                            {
                                                                data.properties.map((x, index) => {
                                                                    return (
                                                                        <div className='inner mb-3'>
                                                                            <input type="text"
                                                                                className='formInput'
                                                                                name="properties"
                                                                                value={x}
                                                                                onChange={(e) => handleInputChangeProperty1(e, index, pIndex)} />

                                                                            {
                                                                                data.properties.length !== 1 && (
                                                                                    <span className='remove'><button onClick={(e) => handleRemove1(e, index, pIndex)}>
                                                                                        <span class="material-icons">remove</span></button></span>
                                                                                )}

                                                                            {
                                                                                data.properties.length - 1 === index && (
                                                                                    <span className='add'><button onClick={() => handleAdd1(pIndex)} ><span class="material-icons">add </span></button></span>
                                                                                )}


                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>

                                                );
                                            })}

                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={addProperties}> Save </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={levelsModel} onHide={handleLevelsClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ color: "black" }}>Add Levels</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ color: "black" }}>
                                    <div className='statsHd'>Levels show up underneath your item, are clickable, and can be filtered in your collection's sidebar.</div>

                                    <div>
                                        {
                                            propertyList.map((data, pIndex) => {
                                                return (


                                                    <div className='formBox'>
                                                        <label className='fieldHd d-block pb-2 ps-1'>Name*</label>
                                                        <div >
                                                            {
                                                                data.levels.map((x, index) => {
                                                                    return (
                                                                        <div className='inner mb-3'>
                                                                            <input type="text"
                                                                                className='formInput'
                                                                                name="levels"
                                                                                value={x}
                                                                                onChange={(e) => handleInputChangeProperty2(e, index, pIndex)} />

                                                                            {
                                                                                data.levels.length !== 1 && (
                                                                                    <span className='remove'><button onClick={(e) => handleRemove2(e, index, pIndex)}>
                                                                                        <span class="material-icons">remove</span></button></span>
                                                                                )}

                                                                            {
                                                                                data.levels.length - 1 === index && (
                                                                                    <span className='add'><button onClick={() => handleAdd2(pIndex)} ><span class="material-icons">add </span></button></span>
                                                                                )}


                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>

                                                );
                                            })}

                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={addLevels}> Save </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={statsModel} onHide={handleStatsClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ color: "black" }}>Add Stats</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ color: "black" }}>
                                    <div className='statsHd'>Stats show up underneath your item, are clickable, and can be filtered in your collection's sidebar.</div>
                                    <div>
                                        {
                                            propertyList.map((data, pIndex) => {
                                                return (


                                                    <div className='formBox'>
                                                        <label className='fieldHd d-block pb-2 ps-1'>Name*</label>
                                                        <div>
                                                            {
                                                                data.stats.map((x, index) => {
                                                                    return (
                                                                        <div className='inner mb-3'>
                                                                            <input type="text"
                                                                                className='formInput'
                                                                                name="stats"
                                                                                value={x}
                                                                                onChange={(e) => handleInputChangeProperty3(e, index, pIndex)} />

                                                                            {
                                                                                data.stats.length !== 1 && (
                                                                                    <span className='remove'><button onClick={(e) => handleRemove3(e, index, pIndex)}>
                                                                                        <span class="material-icons">remove</span></button></span>
                                                                                )}

                                                                            {
                                                                                data.stats.length - 1 === index && (
                                                                                    <span className='add'><button onClick={() => handleAdd3(pIndex)} ><span class="material-icons">add </span></button></span>
                                                                                )}


                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>

                                                );
                                            })}
                                    </div>


                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={addStats}> Save </Button>
                                </Modal.Footer>
                            </Modal>

                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default CreateNewItem;