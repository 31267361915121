import axios from 'axios';
import config from '../config/config';
const url = `${config.Url}user/`


export const buyNft = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(`${url}buyNft`, frmData, {
                headers: {
                    token: localStorage.getItem('accessJWT'),
                },
            });

            resolve(res.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getAllTransactionList = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${url}getTransactionList`, {
                headers: {
                    token: localStorage.getItem('accessJWT'),
                },
            });
            resolve(result);
            console.log('result-------->', result.data)
        } catch (error) {
            reject(error);
        }
    });
};