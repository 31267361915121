
import {
    createNftItem, getAllNftItem, fetchNftDetail, getAllUserNftItem, getAllCollectionNftItem,
    uploadImage, saleStatus, auctionStatus, getAllSellNftItem, getAllOwnerNftItem,
    getAllBestLimitedEditionList, approvalNFTRequest
} from '../../../api/nftApi';
import {
    createNftItemPending, createNftItemSuccess, createNftItemError, createNftItemResetMessage,
    getNftItemListPending, getNftItemListSuccess, getNftItemListError, getNftItemListResetMessage,
    nftDeatailPending, nftDeatailSuccess, nftDeatailError, nftDeatailResetMessage, getUserNftItemListPending,
    getUserNftItemListSuccess, getUserNftItemListError, getUserNftItemListResetMessage,
    getCollectionNftItemListPending, getCollectionNftItemListSuccess, getCollectionNftItemListError, getCollectionNftItemListResetMessage,
    uploadImagePending, uploadImageSuccess, uploadImageError, uploadImageResetMessage,
    salePending, saleSuccess, saleError, saleResetMessage,
    auctionPending, auctionSuccess, auctionError, auctionResetMessage,
    getSellNftItemListPending, getSellNftItemListSuccess, getSellNftItemListError, getSellNftItemListResetMessage,
    getOwnerNftItemListPending, getOwnerNftItemListSuccess, getOwnerNftItemListError, getOwnerNftItemListResetMessage,
    bestLimitedPending, bestLimitedSuccess, bestLimitedError, bestLimitedResetMessage, NFTApprovalPending,
    NFTApprovalSuccess, NFTApprovalError, NFTApprovalResetMessage
} from '../reducer/nftReducer';
import { multiPartData } from '../../../utils';



export const createNft = (frmDt) => async (dispatch) => {
    try {
        let data = multiPartData(frmDt)
        dispatch(createNftItemPending());

        const result = await createNftItem(data);
        if (result.code == 200) {
            return dispatch(createNftItemSuccess(result))
        } else {
            return dispatch(createNftItemError(result.message));
        }

    } catch (error) {
        dispatch(createNftItemResetMessage(error.message));
    }
};

export const getNftList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getNftItemListPending());
        const result = await getAllNftItem();
        result.data.code === 200
            ? dispatch(getNftItemListSuccess(result.data))
            : dispatch(getNftItemListError(result.message));


    } catch (error) {
        dispatch(getNftItemListResetMessage(error.message));
    }
};

export const getUserNftList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getUserNftItemListPending());
        const result = await getAllUserNftItem(frmDt);
        result.data.code === 200
            ? dispatch(getUserNftItemListSuccess(result.data))
            : dispatch(getUserNftItemListError(result.message));


    } catch (error) {
        dispatch(getUserNftItemListResetMessage(error.message));
    }
};

export const getCollectionNftList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getCollectionNftItemListPending());
        const result = await getAllCollectionNftItem();
        result.data.code === 200
            ? dispatch(getCollectionNftItemListSuccess(result.data))
            : dispatch(getCollectionNftItemListError(result.message));


    } catch (error) {
        dispatch(getCollectionNftItemListResetMessage(error.message));
    }
};

export const getNftDetail = (frmDt) => async (dispatch) => {
    try {
        console.log('frmDt1233-------->', frmDt)
        dispatch(nftDeatailPending());
        const result = await fetchNftDetail(frmDt);
        result.code === 200
            ? dispatch(nftDeatailSuccess(result))
            : dispatch(nftDeatailError(result.message));


    } catch (error) {
        dispatch(nftDeatailResetMessage(error.message));
    }
};

export const uploadImages = (frmDt) => async (dispatch) => {
    try {
        let data = multiPartData(frmDt)
        dispatch(uploadImagePending());

        const result = await uploadImage(data);
        if (result.code == 200) {
            return dispatch(uploadImageSuccess(result))
        } else {
            return dispatch(uploadImageError(result.message));
        }

    } catch (error) {
        dispatch(uploadImageResetMessage(error.message));
    }
};

export const saleStatusUpdate = (frmDt) => async (dispatch) => {
    try {
        dispatch(salePending());

        const result = await saleStatus(frmDt);
        if (result.code == 200) {
            return dispatch(saleSuccess(result))
        } else {
            return dispatch(saleError(result.message));
        }

    } catch (error) {
        dispatch(saleResetMessage(error.message));
    }
};

export const auctionStatusUpdate = (frmDt) => async (dispatch) => {
    try {
        dispatch(auctionPending());

        const result = await auctionStatus(frmDt);
        if (result.code == 200) {
            return dispatch(auctionSuccess(result))
        } else {
            return dispatch(auctionError(result.message));
        }

    } catch (error) {
        dispatch(auctionResetMessage(error.message));
    }
};


export const getSellNftList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getSellNftItemListPending());
        const result = await getAllSellNftItem(frmDt);
        result.data.code === 200
            ? dispatch(getSellNftItemListSuccess(result.data))
            : dispatch(getSellNftItemListError(result.message));


    } catch (error) {
        dispatch(getSellNftItemListResetMessage(error.message));
    }
};
export const getOwnerNftList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getOwnerNftItemListPending());
        const result = await getAllOwnerNftItem(frmDt);
        result.data.code === 200
            ? dispatch(getOwnerNftItemListSuccess(result.data))
            : dispatch(getOwnerNftItemListError(result.message));


    } catch (error) {
        dispatch(getOwnerNftItemListResetMessage(error.message));
    }
};


export const getBestLimitedEditionList = () => async (dispatch) => {
    try {
        dispatch(bestLimitedPending());
        const result = await getAllBestLimitedEditionList();
        result.data.code === 200
            ? dispatch(bestLimitedSuccess(result.data))
            : dispatch(bestLimitedError(result.message));


    } catch (error) {
        dispatch(bestLimitedResetMessage(error.message));
    }
};

export const approvalNFT = (frmDt) => async (dispatch) => {
    try {
        let data = multiPartData(frmDt)
        dispatch(NFTApprovalPending());

        const result = await approvalNFTRequest(data);
        if (result.code === 200) {
            return dispatch(NFTApprovalSuccess(result))
        } else {
            return dispatch(NFTApprovalError(result.message));
        }
    } catch (error) {
        dispatch(NFTApprovalResetMessage(error.message));
    }
};