import {
    adminLoginPending, adminLoginSuccess, adminLoginError, adminLoginResetMessage,
    adminDeatailPending, adminDeatailSuccess, adminDeatailError, adminDeatailResetMessage,
    adminUpdatePending, adminUpdateSuccess, adminUpdateError, adminUpdateResetMessage,
    adminChangePassPending, adminChangePassSuccess, adminChangePassError, adminChangePassResetMessage,
    adminDashboardDeatailPending, adminDashboardDeatailSuccess, adminDashboardDeatailError,
    adminDashboardDeatailResetMessage,adminFogotPending,adminFogotSuccess,adminFogotError,adminFogotResetMessage,
    adminVeriyOtpPending,adminVeriyOtpSuccess,adminVeriyOtpError,adminVeriyOtpResetMessage,
    adminResetPending,adminResetSuccess,adminResetError,adminResetResetMessage,adminResendOtpPending,
    adminResendOtpSuccess,adminResendOtpError,adminResendOtpResetMessage
} from '../reducer/adminReducer';
import { adminLogin, fetchAdmin, updateAdminProfile, adminChangePassword, fetchAdminDashboard, resetAdminPassword, adminVeriyOtp, adminFogotPass,adminResendOtp } from '../../../api/adminApi';
import { multiPartData } from '../../../utils'

// export const loginAdmin = (frmDt) => async (dispatch) => {
//     try {
//         dispatch(adminLoginPending());
//         const result = await adminLogin(frmDt);
//         console.log('resulwwwwt------>', result)
//         result.code === 200 ? dispatch(adminLoginSuccess(result)) : dispatch(adminLoginError(result.message));
//     } catch (error) {
//         dispatch(adminLoginResetMessage(error.message));
//     }
// };

export const getAdminDetail = (frmDt) => async (dispatch) => {
    try {
        dispatch(adminDeatailPending());
        const result = await fetchAdmin();
        result.code === 200
            ? dispatch(adminDeatailSuccess(result))
            : dispatch(adminDeatailError(result.message));


    } catch (error) {
        dispatch(adminDeatailResetMessage(error.message));
    }
};

export const updateAdminDetail = (frmDt) => async (dispatch) => {
    try {
        const updatedData = multiPartData(frmDt)
        dispatch(adminUpdatePending());
        const result = await updateAdminProfile(updatedData);
        if (result.code === 200) {
            return dispatch(adminUpdateSuccess(result))
        } else {
            return dispatch(adminUpdateError(result.message));
        }

    } catch (error) {
        dispatch(adminUpdateResetMessage(error.message));
    }
};

export const changeAdminPassword = (frmDt) => async (dispatch) => {
    try {
        dispatch(adminChangePassPending());
        const result = await adminChangePassword(frmDt);
        if (result.code === 200) {
            return dispatch(adminChangePassSuccess(result))
        } else {
            return dispatch(adminChangePassError(result));
        }
    } catch (error) {
        dispatch(adminChangePassResetMessage(error.message));
    }
};


export const getAdminDashboardDetail = (frmDt) => async (dispatch) => {
    try {
        dispatch(adminDashboardDeatailPending());
        const result = await fetchAdminDashboard(frmDt);
        result.code === 200
            ? dispatch(adminDashboardDeatailSuccess(result))
            : dispatch(adminDashboardDeatailError(result.message));


    } catch (error) {
        dispatch(adminDashboardDeatailResetMessage(error.message));
    }
};


export const adminFogotPassword = (frmDt) => async (dispatch) => {
    try {
        dispatch(adminFogotPending());
        const result = await adminFogotPass(frmDt);
        if (result.code === 200) {
            return dispatch(adminFogotSuccess(result))
        } else {
            return dispatch(adminFogotError(result));
        }
    } catch (error) {
        dispatch(adminFogotResetMessage(error.message));
    }
};



export const adminVeriyOtps = (frmDt) => async (dispatch) => {
    try {
        dispatch(adminVeriyOtpPending());
        const result = await adminVeriyOtp(frmDt);
        if (result.code === 200) {
            return dispatch(adminVeriyOtpSuccess(result))
        } else {
            return dispatch(adminVeriyOtpError(result));
        }
    } catch (error) {
        dispatch(adminVeriyOtpResetMessage(error.message));
    }
};

export const resetAdminPass = (frmDt) => async (dispatch) => {
    try {
        dispatch(adminResetPending());
        const result = await resetAdminPassword(frmDt);
        if (result.code === 200) {
            return dispatch(adminResetSuccess(result))
        } else {
            return dispatch(adminResetError(result));
        }
    } catch (error) {
        dispatch(adminResetResetMessage(error.message));
    }
};

export const resendOtps = (frmDt) => async (dispatch) => {
    try {
        dispatch(adminResendOtpPending());
        const result = await adminResendOtp(frmDt);
        if (result.code === 200) {
            return dispatch(adminResendOtpSuccess(result))
        } else {
            return dispatch(adminResendOtpError(result));
        }
    } catch (error) {
        dispatch(adminResendOtpResetMessage(error.message));
    }
};
