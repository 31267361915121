import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import './Header.scss';
import logo from '../assets/images/logo.png'
import Button from 'react-bootstrap/Button';

const HeaderSubmitRequest = () => {


    return (
        <>
            <header className='header d-flex justify-content-between'>
                <div className='logo d-flex justify-content-center align-items-center'><NavLink to="/"><img alt="" src={logo} /></NavLink></div>
                <div className='headRight d-flex justify-content-center align-items-center'><Button>Submit Request</Button></div>
            </header>
        </>

    )

}
export default HeaderSubmitRequest;