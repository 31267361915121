import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import './TopCollections.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import UserImg from '../assets/images/user_img.jpg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getTopCollectionsList, getCollectionDetail } from './redux/action/collectionAction';


const TopCollections = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { topCollectionList } = useSelector((state) => state.collectionDetail)

    useEffect(() => {
        dispatch(getTopCollectionsList());
    }, [getTopCollectionsList])



    const collectionDetails = (id) => {
        dispatch(getCollectionDetail(id))
        localStorage.setItem('collectionId', JSON.stringify(id));
        navigate(`/collection-details`)
    }
    const filterRecord=(query)=>{
       dispatch(getTopCollectionsList(query));
    }


    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-md-5 pb-md-5 pt-4 pb-4'>
                    <div className='container'>
                        <div className='mainHd mb-3 mb-md-4 text-center'>Top Collections</div>
                        <div className='d-flex justify-content-center'>
                            <ul className="d-flex mb-4 mb-md-5 dayTab">
                                <li><a  className="active" onClick={()=>filterRecord('Day')}>1 day</a></li>
                                <li><a onClick={()=>filterRecord('Week')}>7 day</a></li>
                                <li><a onClick={()=>filterRecord('Month')}>30 day</a></li>
                            </ul>
                        </div>
                        <div className='d-flex tcHdBox align-items-end'>
                            <div className='col-6 col-sm-4 pb-2'>Collection</div>
                            <div className='col-3 col-sm-4 pb-2 text-end'>Volume</div>
                            <div className='col-3 col-sm-4 pb-2 text-end'>Floor Price</div>
                        </div>
                        <ul className='topCollList m-0 p-0'>

                        {
                            (topCollectionList && topCollectionList.data && topCollectionList.data.length > 0) ?
                                topCollectionList.data.map((data, i) => (



                                       <li className='d-flex align-items-center'>
                                    <div className='collectionInfo col-6 col-sm-4 d-flex align-items-center' onClick={() => collectionDetails(data.collectionId)}>
                                        <div className='me-3 d-md-block d-none'>{i+1}</div>
                                        <div className='img me-2 me-md-3'><img src={data.profileImage} alt='' /> <CheckCircleIcon className='check' /></div>
                                        <div>{data.collectionName}</div>
                                    </div>
                                    <div className='col-3 col-sm-4 text-end'>
                                        <span className='d-block pb-2'>{data.totalVolumes} HBAR</span>
                                        <span className='grayClr me-1'>$1,117,285</span> <span className='greenClr'>+552.9%</span>
                                    </div>
                                    <div className='col-3 col-sm-4 text-end'>
                                        <span className='d-block pb-2'>{data.florPrice} HBAR</span>
                                        <span className='d-block grayClr'>$31,258</span>
                                    </div>
                                </li>
                           

                                    


                                )) : <div>
                                    <div className='text-center pt-3 pb-4 noResult'>Nothing Found</div>
                                </div>


                        }


                         
                            {/* <NavLink to="/collection-details" >  <li className='d-flex align-items-center'>
                                <div className='collectionInfo col-6 col-sm-4 d-flex align-items-center'>
                                    <div className='me-3 d-md-block d-none'>2</div>
                                    <div className='img me-2 me-md-3'><img src={UserImg} alt='' /> <CheckCircleIcon className='check' /></div>
                                    <div>Allen Waltker</div>
                                </div>
                                <div className='col-3 col-sm-4 text-end'>
                                    <span className='d-block pb-2'>718.37 HBAR</span>
                                    <span className='grayClr me-1'>$1,117,285</span> <span className='greenClr'>+552.9%</span>
                                </div>
                                <div className='col-3 col-sm-4 text-end'>
                                    <span className='d-block pb-2'>19.99 ETH</span>
                                    <span className='d-block grayClr'>$31,258</span>
                                </div>
                            </li></NavLink>
                            <NavLink to="/collection-details" >  <li className='d-flex align-items-center'>
                                <div className='collectionInfo col-6 col-sm-4 d-flex align-items-center'>
                                    <div className='me-3 d-md-block d-none'>3</div>
                                    <div className='img me-2 me-md-3'><img src={UserImg} alt='' /> <CheckCircleIcon className='check' /></div>
                                    <div>Allen Waltker</div>
                                </div>
                                <div className='col-3 col-sm-4 text-end'>
                                    <span className='d-block pb-2'>718.37 HBAR</span>
                                    <span className='grayClr me-1'>$1,117,285</span> <span className='greenClr'>+552.9%</span>
                                </div>
                                <div className='col-3 col-sm-4 text-end'>
                                    <span className='d-block pb-2'>19.99 ETH</span>
                                    <span className='d-block grayClr'>$31,258</span>
                                </div>
                            </li></NavLink>
                            <NavLink to="/collection-details" > <li className='d-flex align-items-center'>
                                <div className='collectionInfo col-6 col-sm-4 d-flex align-items-center'>
                                    <div className='me-3 d-md-block d-none'>4</div>
                                    <div className='img me-2 me-md-3'><img src={UserImg} alt='' /> <CheckCircleIcon className='check' /></div>
                                    <div>Allen Waltker</div>
                                </div>
                                <div className='col-3 col-sm-4 text-end'>
                                    <span className='d-block pb-2'>718.37 HBAR</span>
                                    <span className='grayClr me-1'>$1,117,285</span> <span className='greenClr'>+552.9%</span>
                                </div>
                                <div className='col-3 col-sm-4 text-end'>
                                    <span className='d-block pb-2'>19.99 ETH</span>
                                    <span className='d-block grayClr'>$31,258</span>
                                </div>
                            </li></NavLink>
                            <NavLink to="/collection-details" > <li className='d-flex align-items-center'>
                                <div className='collectionInfo col-6 col-sm-4 d-flex align-items-center'>
                                    <div className='me-3 d-md-block d-none'>5</div>
                                    <div className='img me-2 me-md-3'><img src={UserImg} alt='' /> <CheckCircleIcon className='check' /></div>
                                    <div>Allen Waltker</div>
                                </div>
                                <div className='col-3 col-sm-4 text-end'>
                                    <span className='d-block pb-2'>718.37 HBAR</span>
                                    <span className='grayClr me-1'>$1,117,285</span> <span className='greenClr'>+552.9%</span>
                                </div>
                                <div className='col-3 col-sm-4 text-end'>
                                    <span className='d-block pb-2'>19.99 ETH</span>
                                    <span className='d-block grayClr'>$31,258</span>
                                </div>
                            </li></NavLink>
                            <NavLink to="/collection-details" > <li className='d-flex align-items-center'>
                                <div className='collectionInfo col-6 col-sm-4 d-flex align-items-center'>
                                    <div className='me-3 d-md-block d-none'>6</div>
                                    <div className='img me-2 me-md-3'><img src={UserImg} alt='' /> <CheckCircleIcon className='check' /></div>
                                    <div>Allen Waltker</div>
                                </div>
                                <div className='col-3 col-sm-4 text-end'>
                                    <span className='d-block pb-2'>718.37 HBAR</span>
                                    <span className='grayClr me-1'>$1,117,285</span> <span className='greenClr'>+552.9%</span>
                                </div>
                                <div className='col-3 col-sm-4 text-end'>
                                    <span className='d-block pb-2'>19.99 ETH</span>
                                    <span className='d-block grayClr'>$31,258</span>
                                </div>
                            </li></NavLink> */}
                        </ul>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default TopCollections;