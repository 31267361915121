import React, { useState, useEffect } from 'react';
import './CreateNewCollection.scss';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import Button from 'react-bootstrap/Button';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { createCollectionContract } from '../helpers/hashconnect';
import { useSelector, useDispatch } from 'react-redux';
import ReactLoading from 'react-loading';
import { createCollections } from './redux/action/collectionAction';

const initialState = {
    collectionName: '',
    url: '',
    category: '',
    description: '',
    discord: '',
    website: '',
    medium: '',
    instagram: '',
    twitter: '',
    paymentTokens: '',
    profileImage: '',
    featuredImage: '',
    bannerImage: '',
    imageReview: '',
    featuredImageReview: '',
    bannerImageReview: '',
    CollectibleType: '',
    buttonDisable: false,
    updatedData: {},
    errors: {},
}

const CreateNewCollection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [iState, updateState] = useState(initialState)
    const { isLoding, status, message } = useSelector((state) => state.collectionDetail)

    const {
        collectionName,
        url,
        category,
        description,
        discord,
        website,
        medium,
        instagram,
        twitter,
        paymentTokens,
        profileImage,
        featuredImage,
        bannerImage,
        updatedData,
        imageReview,
        featuredImageReview,
        bannerImageReview,
        CollectibleType,
        buttonDisable,
        errors,
    } = iState

    const handleInputChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState, [name]: value,
            updatedData: {
                ...iState.updatedData, [name]: value
            }
        })
    }


    const onFileHandler = (e) => {
        const file = e.target.files[0]

        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                previewsFile(file)

            } else {
                toast.error('Only formats are allowed: jpeg, jpg, png files', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }


    const previewsFile = (file) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
            updateState({
                ...iState, imageReview: reader.result, profileImage: file
            })
        }, false)
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const onFileHandler1 = (e) => {
        const file = e.target.files[0]

        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                previewsFile1(file)

            } else {
                toast.error('Only formats are allowed: jpeg, jpg, png files', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }


    const previewsFile1 = (file) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
            updateState({
                ...iState, featuredImageReview: reader.result, featuredImage: file
            })
        }, false)
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const onFileHandler2 = (e) => {
        const file = e.target.files[0]

        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                previewsFile2(file)

            } else {
                toast.error('Only formats are allowed: jpeg, jpg, png files', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }


    const previewsFile2 = (file) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
            updateState({
                ...iState, bannerImageReview: reader.result, bannerImage: file
            })
        }, false)
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const handleValidation = () => {
        let collectionNameEmpty = ''
        let urlEmpty = ''
        let categorylEmpty = ''
        let descriptionEmpty = ''
        let discordEmpty = ''
        let mediumEmpty = ''
        let websiteEmpty = ''
        let instagramEmpty = ''
        let twitterEmpty = ''
        let paymentTokensEmpty = ''
        let profileImageEmpty = ''
        let featuredImageEmpty = ''
        let bannerImageEmpty = ''
        let formIsValid = true;


        if (!collectionName.trim()) {
            collectionNameEmpty = "Please insert collection name.";
            formIsValid = false;
        }
        if (!url.trim()) {
            urlEmpty = "Please insert URL";
            formIsValid = false;
        }
        if (!category.trim()) {
            categorylEmpty = "Please select cotegory";
            formIsValid = false;
        }
        if (!description.trim()) {
            descriptionEmpty = "Please insert discription"
            formIsValid = false;
        }
        if (!discord.trim()) {
            discordEmpty = "Please insert discord.";
            formIsValid = false;
        }
        if (!website.trim()) {
            websiteEmpty = "Please insert website name";
            formIsValid = false;
        }
        if (!medium.trim()) {
            mediumEmpty = "Please insert medium";
            formIsValid = false;
        }
        if (!instagram.trim()) {
            instagramEmpty = "Please insert instagram url"
            formIsValid = false;
        }
        if (!twitter.trim()) {
            twitterEmpty = "Please insert twitter url.";
            formIsValid = false;
        }
        if (!paymentTokens.trim()) {
            paymentTokensEmpty = "Please select payment token";
            formIsValid = false;
        }
        if (imageReview === '') {
            profileImageEmpty = "Please select profile picture";
            formIsValid = false;
        }
        if (featuredImageReview === '') {
            featuredImageEmpty = "Please select featured picture"
            formIsValid = false;
        }
        if (bannerImageReview === '') {
            bannerImageEmpty = "Please select banner picture.";
            formIsValid = false;
        }

        updateState({
            ...iState, errors: {
                collectionNameEmpty, urlEmpty, categorylEmpty, descriptionEmpty,
                discordEmpty, mediumEmpty, websiteEmpty, instagramEmpty, twitterEmpty,
                paymentTokensEmpty, profileImageEmpty, featuredImageEmpty, bannerImageEmpty
            }

        })
        return formIsValid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = handleValidation();
        if (formIsValid) {
            updateState({ ...iState, buttonDisable: true })
            let crtCol = await createCollectionContract(CollectibleType)
            if (crtCol) {
                const data = {
                    collectionName,
                    url,
                    category,
                    description,
                    discord,
                    website,
                    medium,
                    instagram,
                    twitter,
                    paymentTokens,
                    profileImage,
                    featuredImage,
                    bannerImage,
                    CollectibleType: CollectibleType == true ? 'Collectible' : 'Non Collectible',
                    smartContract: crtCol
                }
                dispatch(createCollections(data)).then(res => {
                    if (res.payload.code == 200) {
                        toast.success(res.payload.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        navigate('/my-collections')
                    } else {
                        updateState({
                            ...iState, buttonDisable: false, errorMsg: res.message,
                            errors: ''
                        })
                    }
                })


            }
        }
    }


    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='mainHd mb-3 mb-md-4 text-center'>Create New Collection</div>
                        <div className='collectionForm'>
                            <ul className='form p-0'>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Type</label>
                                    <div className='d-flex flex-column grayClr ps-1 pt-1'>
                                        <label className='d-flex align-items-center pb-3'><input type='radio' name='CollectibleType' className='radioBox me-2' value={true} onChange={handleInputChange} /> Collectible NFT</label>
                                        <label className='d-flex align-items-center pb-3'><input type='radio' name='CollectibleType' className='radioBox me-2' value={false} onChange={handleInputChange} /> Non-Collectible NFT</label>
                                    </div>
                                    {/* <span style={{ color: "red" }}>{errors.CollectibleTypeEmpty}</span> */}
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Profile Image* <span className='small d-block mt-1 pb-2'>This image will also be used for navigation. 350 x 350 recommended.</span></label>
                                    <div className='imgBox' style={{ maxWidth: "200px", height: "200px", borderRadius: "100%", }}>
                                        <img src={imageReview} />
                                    </div>
                                    <div className='choosePhoto'>Choose Photo
                                        <input type='file' className='file' id="imageUpload"
                                            onChange={onFileHandler}
                                            onClick={e => e.target.value == null}
                                            accept=".png, .jpg, .jpeg" />

                                    </div>
                                    <span style={{ color: "red" }}>{errors.profileImageEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Featured Image<span className='small d-block mt-1 pb-2'>This image will be used for featuring your collection on the homepage, category pages, or other promotional areas of Ledex. 600 x 400 recommended.</span></label>
                                    <div className='imgBox' style={{ maxWidth: "600px", height: "200px", borderRadius: "10px", }}><img src={featuredImageReview} /></div>
                                    <div className='choosePhoto'>Choose Photo
                                        <input type='file' className='file' id="imageUpload"
                                            onChange={onFileHandler1}
                                            onClick={e => e.target.value == null}
                                            accept=".png, .jpg, .jpeg" />
                                    </div>
                                    <span style={{ color: "red" }}>{errors.featuredImageEmpty}</span>

                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Banner Image<span className='small d-block mt-1 pb-2'>This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 350 recommended.</span></label>
                                    <div className='imgBox' style={{ maxWidth: "1000px", height: "250px", borderRadius: "10px", }}><img style={{ maxWidth: "1000px", height: "250px", borderRadius: "10px", }} src={bannerImageReview} /></div>
                                    <div className='choosePhoto'>Choose Photo
                                        <input type='file' className='file' id="imageUpload"
                                            onChange={onFileHandler2}
                                            onClick={e => e.target.value == null}
                                            accept=".png, .jpg, .jpeg" />

                                    </div>
                                    <span style={{ color: "red" }}>{errors.bannerImageEmpty}</span>

                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Name*</label>
                                    <input type="text" className='formInput' name="collectionName" value={collectionName} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.collectionNameEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>URL</label>
                                    <input type="text" className='formInput' name="url" value={url} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.urlEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Description</label>
                                    <textarea rows="3" cols="3" className='formInput textarea' name="description" value={description} onChange={handleInputChange}></textarea>
                                    <span style={{ color: "red" }}>{errors.descriptionEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Category</label>
                                    <div className='selectBox'>
                                        <select className='formInput' name="category" value={category} onChange={handleInputChange}>
                                            <option>Select</option>
                                            <option value="Art">Art</option>
                                            <option value="Collectibles">Collectibles</option>
                                            {/* <option value="Music">Music</option>*/}
                                            <option value="Photography">Design</option> 
                                            {/* <option value="Sports">Sports</option> */}
                                            <option value="Watches">Watches</option>
                                            <option value="Utility">Jewellery</option>
                                            {/* <option value="Trading Cards">Trading Cards</option> */}

                                        </select>
                                    </div>
                                    <span style={{ color: "red" }}>{errors.categorylEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Your Website</label>
                                    <input type="text" className='formInput' placeholder='yoursite.io' name="website" value={website} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.websiteEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Discord</label>
                                    <input type="text" className='formInput' placeholder='https://discord.gg/' name="discord" value={discord} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.discordEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Medium</label>
                                    <input type="text" className='formInput' placeholder='https://www.medium.com/@' name="medium" value={medium} onChange={handleInputChange} />

                                    <span style={{ color: "red" }}>{errors.mediumEmpty}</span></li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Instagram</label>
                                    <input type="text" className='formInput' placeholder='https://www.instagram.com/' name="instagram" value={instagram} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.instagramEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Twitter</label>
                                    <input type="text" className='formInput' placeholder='https://t.me/' name="twitter" value={twitter} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.twitterEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Payment Tokens<span className='small d-block mt-1 pb-2'>Select the blockchain where you'd like new items from this collection to be added by default.</span></label>
                                    <div className='selectBox'>
                                        <select className='formInput' name="paymentTokens" value={paymentTokens} onChange={handleInputChange}>
                                            <option>Select</option>
                                            <option value="HBAR">HBAR</option>

                                        </select>
                                    </div>
                                    <span style={{ color: "red" }}>{errors.paymentTokensEmpty}</span>
                                </li>
                                <li><Button type="button" onClick={handleSubmit} disabled={buttonDisable}>Create</Button></li>
                            </ul>
                            {
                                buttonDisable ? <div className='loader'>
                                    <ReactLoading type="spin" color="#fff" height={'20%'} width={'20%'} />
                                </div> : ""
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default CreateNewCollection;