import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import rotate from '../assets/images/rotate.png';
import watchImg1 from '../assets/images/watch_img1.png';
import watchImg2 from '../assets/images/watch_img2.png';
import watchImg3 from '../assets/images/watch_img3.png';
import watchImg4 from '../assets/images/watch_img4.png';
import watchImg5 from '../assets/images/watch_img5.png';
import watchImg6 from '../assets/images/watch_img6.png';
import watchImg7 from '../assets/images/watch_img7.png';
import watchImg8 from '../assets/images/watch_img8.png';
import { useSelector, useDispatch } from 'react-redux';
import { getCollectionNftList, getNftDetail } from '../pages/redux/action/nftAction';

const Items = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { nftCollectionList } = useSelector((state) => state.nftItemDetail)


    useEffect(() => {
        dispatch(getCollectionNftList());
    }, [getCollectionNftList])

    const nftDetail = (id,userid) => {
        dispatch(getNftDetail({nftItemId:id,userId:userid}))
        localStorage.setItem('nftItemId', JSON.stringify(id));
        localStorage.setItem('userId', JSON.stringify(userid));
        navigate(`/product-detail`)
    }

    return (    
        <>
            <ul className='p-0 productList d-flex flex-wrap'>

                {
                    (nftCollectionList.data && nftCollectionList.data.length > 0) ?
                        nftCollectionList.data.map((data, i) => (

                            <li onClick={() => nftDetail(data._id,data.userId)}>
                                <NavLink>  <div className='inner'>
                                    <div className='img'><a ><img alt="" src={data.featuredImg} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Item:{data.nftItemCount}</span></div>
                                    <div className='userName'>
                                        <a>{data.itemName}</a>
                                        <span className='d-block pt-1'>{data.userName}</span>
                                    </div>
                                    {
                                     data.amount? <div className='price'>Price <span className='d-block mt-1'>{data.amount} HBAR</span></div>:''  
                                    }
                                    
                                </div></NavLink>
                            </li>
                        )) : ""


                }
            </ul>

            {/* <ul className='p-0 productList d-flex flex-wrap'>
                <li>
                    <NavLink to="/product-detail" >  <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg1} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div></NavLink>
                </li>
               */}
        </>

    )

}
export default Items;