import React, { } from 'react';

const DetailNft = () => {
    return (
        <>
            <div className='detList'>
                <ul className='d-flex flex-wrap p-0'>
                    <li>
                        <div className='inner rounded-3 p-3 text-center'>
                            <div className='pb-1 blueClr'>Background</div>
                            <div className='pb-1 mid'>Yellowish Sky</div>
                            <div className='grayClr'>85% have this trait</div>
                        </div>
                    </li>
                    <li>
                        <div className='inner rounded-3 p-3 text-center'>
                            <div className='pb-1 blueClr'>Background</div>
                            <div className='pb-1 mid'>Yellowish Sky</div>
                            <div className='grayClr'>85% have this trait</div>
                        </div>
                    </li>
                    <li>
                        <div className='inner rounded-3 p-3 text-center'>
                            <div className='pb-1 blueClr'>Background</div>
                            <div className='pb-1 mid'>Yellowish Sky</div>
                            <div className='grayClr'>85% have this trait</div>
                        </div>
                    </li>
                    <li>
                        <div className='inner rounded-3 p-3 text-center'>
                            <div className='pb-1 blueClr'>Background</div>
                            <div className='pb-1 mid'>Yellowish Sky</div>
                            <div className='grayClr'>85% have this trait</div>
                        </div>
                    </li>
                    <li>
                        <div className='inner rounded-3 p-3 text-center'>
                            <div className='pb-1 blueClr'>Background</div>
                            <div className='pb-1 mid'>Yellowish Sky</div>
                            <div className='grayClr'>85% have this trait</div>
                        </div>
                    </li>
                    <li>
                        <div className='inner rounded-3 p-3 text-center'>
                            <div className='pb-1 blueClr'>Background</div>
                            <div className='pb-1 mid'>Yellowish Sky</div>
                            <div className='grayClr'>85% have this trait</div>
                        </div>
                    </li>
                </ul>
            </div>
        </>

    )

}
export default DetailNft;