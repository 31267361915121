import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import './Admin.scss';
import Switch from '@mui/material/Switch';
import { ToastContainer, toast } from 'react-toastify';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import SearchIcon from '@mui/icons-material/Search';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-js-pagination';
import { useSelector, useDispatch } from 'react-redux';
import { getNftList } from '../redux/action/adminNftAction';
import { createBestEdition, getBestLimitedEditionList } from '../redux/action/adminUserAction';
const label = { inputProps: { 'aria-label': 'Switch demo' } };
const Besteditions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [nftId, setNftId] = useState("");
    const itemsPerPage = 10;
    const [serach, setSearch] = useState("")
    const { nftList } = useSelector((state) => state.adminNftDetail)
    const { bestEditionList } = useSelector((state) => state.adminUserDetail)

    useEffect(() => {
        let obj = { page: currentPage, search: serach }
        dispatch(getBestLimitedEditionList(obj));
    }, [currentPage])

    useEffect(() => {
        let obj = { page: currentPage, search: serach }
        dispatch(getNftList(obj));
    }, [currentPage])

    const handleChange = (e) => {
        const { name, value } = e.target
        setSearch(value)
        let obj = { page: currentPage, search: value }
        dispatch(getBestLimitedEditionList(obj))
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setNftId(value)
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        // let formIsValid = handleValidation();
        // if (formIsValid) {
        const data = {
            nftItemId: nftId
        }
        dispatch(createBestEdition(data)).then(res => {
            if (res.payload.code == 200) {
                toast.success(res.payload.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                let obj = { page: currentPage, search: serach }
                dispatch(getBestLimitedEditionList(obj));

            } else {
                console.log("error")
            }
        })

        //}
    }


    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className='mb-3 d-flex align-items-center'>

                            <div className="dbMainHd">Add Best Limited Editions NFT</div>
                        </div>
                        <div className='mb-3 userDetBox d-block d-xl-flex justify-content-between'>
                            <div className='left d-flex align-items-center me-2'>
                                <div className='dbRight'>

                                    <div className='settingForm p-3 p-md-4 mb-4 rounded-3'>
                                        <ul className='form p-0'>
                                            <li>
                                                <label className='fieldHd d-block pb-2 ps-1'>NFT </label>
                                                <div className='selectBox'>
                                                    <select className='formInput' name="collectionId" value={nftId} onChange={handleInputChange}>
                                                        <option>Select NFT</option>
                                                        {nftList.data && nftList.data.nftList.length > 0 && nftList.data.nftList.map((data, i) =>
                                                            <option key={i} value={data._id}>{data.itemName}</option>
                                                        )}

                                                    </select>
                                                </div>
                                                {/* <span style={{ color: "red" }}>{errors.collectionIdEmpty}</span> */}
                                            </li>


                                        </ul>
                                    </div>
                                    <div><Button type="button" onClick={handleSubmit}>Save</Button></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center'>
                            <div className="dbMainHd d-flex align-items-center mb-3">Best Limited Editions</div>
                            <div className='d-flex mb-3'>
                                <div className='search me-2'>
                                    <input type='text' className='input' name='search' value={serach} onChange={handleChange} placeholder='Search...' />
                                    <SearchIcon className='searchIcon' />
                                </div>

                            </div>
                        </div>

                        <div className='dbTable rounded-3'>
                            <table>
                                <tr>
                                    <th>Image</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Amount</th>
                                    <th>Created</th>
                                    <th>Action</th>
                                </tr>


                                {
                                    (bestEditionList.data && bestEditionList.data.bseteditionList.length > 0) ?
                                        bestEditionList.data.bseteditionList.map((data, i) => (

                                            <tr className='cursor-pointer'>
                                                <td><img src={data.itemProfile} alt='' className='itemImg' /></td>
                                                <td >{data._id}</td>
                                                <td >{data.itemName}</td>
                                                <td >{data.amount ? data.amount : 0}</td>
                                                <td>{moment(data.createdAt).format("DD-MM-YYYY")}</td>

                                                <td> Delete</td>

                                            </tr>

                                        )) : ""


                                }
                            </table>
                        </div>
                        <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={bestEditionList.data && bestEditionList.data.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                </div>
            </div>
        </>

    )

}

export default Besteditions;