import axios from 'axios';
import config from '../config/config';
const url = `${config.Url}user/`

export const createNftItem = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(`${url}createNftItem`, frmData, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getAllNftItem = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.get(`${url}getNftItemList`, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(result);
			console.log('result-------->', result.data)
		} catch (error) {
			reject(error);
		}
	});
};


export const getAllUserNftItem = (frmData) => {
	return new Promise(async (resolve, reject) => {
		console.log('frmData----->', frmData)
		try {
			const result = await axios.post(`${url}getUserNftItemList`, frmData, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(result);
			console.log('result-------->', result.data)
		} catch (error) {
			reject(error);
		}
	});
};

export const getAllCollectionNftItem = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const collectionId = JSON.parse(localStorage.getItem('collectionId'));
			const result = await axios.get(`${url}getCollectionNftItemList?collectionId=${collectionId}`, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(result);
			console.log('result-------->', result.data)
		} catch (error) {
			reject(error);
		}
	});
};



export const fetchNftDetail = (frmData) => {
	return new Promise(async (resolve, reject) => {
		console.log('frmDt456466----->',frmData)
		try {
			const res = await axios.get(`${url}getNftItemDetail?nftItemId=${frmData.nftItemId}&userId=${frmData.userId}`);
			resolve(res.data);
		} catch (error) {
			console.log(error);
			reject(error.message);
		}
	});
};


export const uploadImage = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(`${url}uploadImage`, frmData, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const saleStatus = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.put(`${url}updateNftItemStatus`, frmData, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};


export const auctionStatus = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.put(`${url}auctionStatus`, frmData, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getAllSellNftItem = (frmData) => {
	return new Promise(async (resolve, reject) => {
		let data = frmData ? frmData : ""
		try {
			const result = await axios.get(`${url}sellNftItemList?type=${data}`, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(result);
			console.log('result-------->', result.data)
		} catch (error) {
			reject(error);
		}
	});
};

export const getAllOwnerNftItem = (frmData) => {
	return new Promise(async (resolve, reject) => {
		let data = frmData ? frmData : ""
		try {
			const result = await axios.get(`${url}ownerNftItemList?type=${data}`, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(result);
			console.log('result-------->', result.data)
		} catch (error) {
			reject(error);
		}
	});
};

export const getAllBestLimitedEditionList = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.get(`${url}getBestLimitedEditionList`, {

				headers: {},
			});


			resolve(result);
		} catch (error) {
			reject(error);
		}
	});
};

export const approvalNFTRequest = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.put(`${url}approvalNFT`, frmData, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};
