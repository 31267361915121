import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    status: '',
    message: '',
    transactionList: []
};

const transactionDetail = createSlice({
    name: 'transactionDetail',
    initialState,
    reducers: {
        buyNftPending: (state) => {
            state.isLoading = true;
        },
        buyNftSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload
        },
        buyNftError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        buyNftResetMessage: (state) => {
            state.message = '';
        },
        getTransactionListPending: (state) => {
            state.isLoading = true;
        },
        getTransactionListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.transactionList = payload
        },
        getTransactionListError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getTransactionListResetMessage: (state) => {
            state.message = '';
        },
    },
});

const { reducer, actions } = transactionDetail;
export const { buyNftPending, buyNftSuccess, buyNftError, buyNftResetMessage,
    getTransactionListPending,getTransactionListSuccess,getTransactionListError,getTransactionListResetMessage

} = actions;

export default reducer;