import axios from 'axios';
import config from '../config/config';
const url = `${config.Url}admin/`

export const getAllNft = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${url}getAdminNftList?pageNumber=${frmData.page}&search=${frmData.search}`, {

                headers: {
                    token: localStorage.getItem('adminToken'),
                },
            });


            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};

export const getAllUserNftItem = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data = frmData ? frmData : ""
            const userId = JSON.parse(localStorage.getItem('userId'));
            const result = await axios.get(`${url}getAdminUserNftList?userId=${userId}&pageNumber=${frmData.page}&search=${frmData.search}`, {
                headers: {
                    token: localStorage.getItem('adminToken'),
                },
            });
            resolve(result);
            console.log('result-------->', result.data)
        } catch (error) {
            reject(error);
        }
    });
};


export const fetchNftDetails = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const nftItemId = JSON.parse(localStorage.getItem('nftItemId'));

            const res = await axios.get(`${url}getAdminNftItemDetail?nftItemId=${nftItemId}`, {
                headers: {
                    token: localStorage.getItem('adminToken'),
                },
            });

            resolve(res.data);
        } catch (error) {
            console.log(error);
            reject(error.message);
        }
    });
};

export const getAllCollectibleNft = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${url}getCollectibleNftList?pageNumber=${frmData.page}search=${frmData.search}`, {
                headers: {
                    token: localStorage.getItem('adminToken'),
                },
            });
            console.log('result------>', result)

            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};

export const updateNftStatus = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${url}updateNftStatus?nftItemId=${frmData}`, {
                headers: {
                    token: localStorage.getItem('adminToken'),
                },
            });

            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};

export const getAllApprovalNft = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${url}getApprovalNftLists?pageNumber=${frmData.page}&search=${frmData.search}`, {

                headers: {
                    token: localStorage.getItem('adminToken'),
                },
            });


            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};