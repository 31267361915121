import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    status: '',
    message: '',
    saleNftList: []
};

const saleNftDetail = createSlice({
    name: 'transactionDetail',
    initialState,
    reducers: {
        saleNftPending: (state) => {
            state.isLoading = true;
        },
        saleNftSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload
        },
        saleNftError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        saleNftResetMessage: (state) => {
            state.message = '';
        },
        getSaleNftListPending: (state) => {
            state.isLoading = true;
        },
        getSaleNftListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.saleNftList = payload
        },
        getSaleNftListError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getSaleNftListResetMessage: (state) => {
            state.message = '';
        },
    },
});

const { reducer, actions } = saleNftDetail;
export const { saleNftPending, saleNftSuccess, saleNftError, saleNftResetMessage,
    getSaleNftListPending,getSaleNftListSuccess,getSaleNftListError,getSaleNftListResetMessage

} = actions;

export default reducer;