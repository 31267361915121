import axios from 'axios';
import config from '../config/config';
const url = `${config.Url}user/`


export const saleNft = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(`${url}saleNft`, frmData, {
                headers: {
                    token: localStorage.getItem('accessJWT'),
                },
            });

            resolve(res.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getAllSaleNftList = (frmData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${url}getSaleNftList?nftItemId=${frmData}`, {
                headers: {
                    token: localStorage.getItem('accessJWT'),
                },
            });
            resolve(result);
            console.log('result-------->', result.data)
        } catch (error) {
            reject(error);
        }
    });
};