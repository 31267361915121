import { buyNft,getAllTransactionList } from '../../../api/transactionApi';
import { buyNftPending, buyNftSuccess, buyNftError, buyNftResetMessage,
    getTransactionListPending,getTransactionListSuccess,getTransactionListError,getTransactionListResetMessage
 } from '../reducer/transactionReducer';




export const buyNftItme = (frmDt) => async (dispatch) => {
    try {
        dispatch(buyNftPending());
        const result = await buyNft(frmDt);
        if (result.code == 200) {
            return dispatch(buyNftSuccess(result))
        } else {
            return dispatch(buyNftError(result.message));
        }

    } catch (error) {
        dispatch(buyNftResetMessage(error.message));
    }
};

export const getTransactionList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getTransactionListPending());
        const result = await getAllTransactionList();
        result.data.code === 200
            ? dispatch(getTransactionListSuccess(result.data))
            : dispatch(getTransactionListError(result.message));


    } catch (error) {
        dispatch(getTransactionListResetMessage(error.message));
    }
};

