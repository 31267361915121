import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import './Header.scss';
import logo from '../assets/images/logo.png'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CloseIcon from '@mui/icons-material/Close';

const HeaderBlog = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <header className='header d-flex justify-content-between'>
                <div className='logo d-flex justify-content-center align-items-center'><NavLink to="/"><img alt="" src={logo} /></NavLink></div>
                <div className='headRight d-flex justify-content-center align-items-center'><a className="btn btn-primary" onClick={handleShow}>Subscribe</a></div>
            </header>

            <Modal className="popupBox" show={show}>
                <Modal.Body>
                    <a className='close' onClick={handleClose}><CloseIcon /></a>
                    <div className='popupHd text-center mb-4'>Ledex Protocol Blog</div>
                    <ul className='form p-0'>
                        <li>
                            <label className='fieldHd d-block pb-2 ps-1'>Username</label>
                            <input type="text" className='formInput' />
                        </li>
                        <li>
                            <label className='fieldHd d-block pb-2 ps-1'>Emaill Address</label>
                            <input type="text" className='formInput' />
                        </li>
                        <li><Button type="button" className='full'>Subscribe</Button></li>
                    </ul>
                </Modal.Body>
            </Modal>
        </>

    )

}
export default HeaderBlog;