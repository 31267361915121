import React from "react";
import Slider from "react-slick";
import './BrowseByCategory.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Art from '../assets/images/art.png';
import Photography from '../assets/images/photography.png';
import Music from '../assets/images/music.png';
import Sports from '../assets/images/sports.png';
import Dise from '../assets/images/dise.png';
import { NavLink, Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import video1 from '../assets/lamps.mp4';
import video2 from '../assets/watch.mp4';
import video3 from '../assets/neck_piece.mp4';
import video4 from '../assets/liquor.mp4';
import globeVideo from '../assets/The glob 3d .mp4'
import partnerImg from '../assets/Partners.png'
import partnerImg2 from '../assets/Pulsify-Partner.jpeg'
import partnetImg3 from '../assets/Gamesphere-Partner.png'
import partnerImg4 from '../assets/Momentum-Partner.webp'
import partnerImg5 from '../assets/gmtpartner.png'

const BrowseByCategory = () => {

    AOS.init({
        once: true,
        duration: 1200,
    });

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <>
            <div className='pb-large pt-4 pt-md-5'>
                <div className="container position-relative">
                    {/* <img alt="" src={Dise} className="diseImg" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500" /> */}
                    <div className='mainHd text-center mb-3 mb-md-4' data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">Browse By Category</div>
                    <Slider className="browseCategory bottomArrow mb-5" {...settings}>
                        <NavLink to="/art" >  <div className="item text-center" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500">
                            <div className="img">
                                <a>
                                    <video width="100%" height="auto" loop autoPlay muted>
                                        <source src={globeVideo} type="video/mp4" />
                                    </video>
                                </a>
                            </div>
                            <div className="name"><a>Art</a></div>
                        </div></NavLink>
                        <NavLink to="/watches" >  <div className="item text-center" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500">
                            <div className="img">
                                <a>
                                    <video width="100%" height="auto" loop autoPlay muted>
                                        <source src={video2} type="video/mp4" />
                                    </video>
                                </a>
                            </div>
                            <div className="name"><a>Watch</a></div>
                                      </div></NavLink>
                        <NavLink to="/utility" >  <div className="item text-center" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500">
                            <div className="img">
                                <a>
                                    <video width="100%" height="auto" loop autoPlay muted>
                                        <source src={video3} type="video/mp4" />
                                    </video>
                                </a>
                            </div>
                            <div className="name"><a>Jewellery</a></div>
                        </div></NavLink>
                        <NavLink to="/photography" >  <div className="item text-center" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500">
                            <div className="img">
                                <a>
                                    <video width="100%" height="auto" loop autoPlay muted>
                                        <source src={video4} type="video/mp4" />
                                    </video>
                                </a>
                            </div>
                            <div className="name"><a>Design</a></div>
                        </div></NavLink>
                    </Slider>
                </div>
            </div>

            {/* <div className='pb-large pt-4 pt-md-5'>
    <div className="container position-relative">
        <div className='mainHd text-center mb-3 mb-md-4' data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">Our Partners</div>
            <a href="https://www.instagram.com/3dmini.lab?igsh=MTQwYnRvdWt3YjZoNg==" target="_blank" rel="noopener noreferrer">
                <div className="item text-center" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500">
                    <div className="img" style={{ borderRadius: '10px', overflow: 'hidden', width: '250px', height: '250px', margin: '0 auto' }}>
                        <a>
                            <img src={partnerImg} alt="Partner Image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </a>
                    </div>
                    <div className="name" style={{ marginTop: '10px', fontFamily: 'Arial, sans-serif', fontSize: '18px', fontWeight: 'bold', color: '#ffffff' }}><a style={{ textDecoration: 'none', color: '#ffffff' }}>3d MiniLabs</a></div>
                </div>
            </a>  
    </div>
</div> */}
        <div className='pb-large pt-4 pt-md-5'>
    <div className="container position-relative">
        <div className='mainHd text-center mb-3 mb-md-4' data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">Our Partners</div>
        <Slider className="browseCategory bottomArrow mb-5" {...settings}>
            <div className="item text-center" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500">
                <div className="img">
                <a href="https://www.instagram.com/3dmini.lab?igsh=MTQwYnRvdWt3YjZoNg==" target="_blank" rel="noopener noreferrer">
                        <img src={partnerImg} alt="3dminilabs" style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 'auto' }}/>
                        </a>
                </div>
                <div className="name"><a href="https://www.instagram.com/3dmini.lab?igsh=MTQwYnRvdWt3YjZoNg==" target="_blank" rel="noopener noreferrer">3D Minilabs</a></div>        
            </div>
            
            <div className="item text-center" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500">
                <div className="img">
                    <a href="#">
                        <img src={partnerImg2} alt="pulsify consultancy" style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 'auto' }}/>
                    </a>
                </div>
                <div className="name"><a href="#">Pulsify Consultancy</a></div>
            </div>
            <div className="item text-center" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500">
                <div className="img">
                    <a href="https://game-sphere.io/" target="_blank" rel="noopener noreferrer">
                        <img src={partnetImg3} alt="Gamesphere.io" style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 'auto' }}/>
                    </a>
                </div>
                <div className="name"><a href="https://game-sphere.io/" target="_blank" rel="noopener noreferrer">Gamesphere</a></div>
            </div>
            <div className="item text-center" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500">
                <div className="img">
                <a href="https://momentum-dubai.com/" target="_blank" rel="noopener noreferrer">
                        <img src={partnerImg4} alt="Momentum Dubai" style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 'auto' }}/>
                    </a>
                </div>
                <div className="name"><a href="https://momentum-dubai.com/">Momentum Dubai</a></div> 
            </div>
            <div className="item text-center" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500">
                <div className="img">
                <a href="https://gmtwatches.ae/" target="_blank" rel="noopener noreferrer">
                        <img src={partnerImg5} alt="GMTwatches & Jewellery dubai" style={{ width: '100%', height: '100%', objectFit: 'cover', margin: 'auto' }}/>
                    </a>
                </div>
                <div className="name"><a href="https://gmtwatches.ae/">GMTwatches & Jewellery dubai</a></div> 
            </div>
        </Slider>
    </div>
</div>

        </>

    )

}
export default BrowseByCategory;