import React, { useState, useEffect } from 'react';
import './ExploreCollections.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Trending from '../component/Trending.js';
import Art from '../component/Art'
import UserImg from '../assets/images/user_img.jpg';
import CollectionImg1 from '../assets/images/collection_im1.jpg';
import CollectionImg2 from '../assets/images/collection_im2.jpg';
import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector, useDispatch } from 'react-redux';
import { getCollectionsList, getCollectionDetail } from './redux/action/collectionAction'

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ExploreCollections = () => {
    let query = useQuery();

    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-4 pt-md-5'>
                    <div className='container'>
                        <NavLink to="/explore-collections"><div className='mainHd'>Explore Collections</div></NavLink>
                    </div>
                </div>
                <Tab.Container defaultActiveKey="Trending">
                    <div className='topCon'>
                        <div className='container'>

                            <Nav className='d-flex m-0 ps-0 pt-4 tabs'>
                                <Link to="/explore-collections">Trending</Link>
                                <Link to="/explore-collections?tab=Top" >Top</Link>
                                <Link to="/explore-collections?tab=Art">Art</Link>
                                <Link to="/explore-collections?tab=Collectibles">Collectibles</Link>
                                {/* <Link to="/explore-collections?tab=Music">Music</Link> */}
                                <Link to="/explore-collections?tab=Photography">Design</Link>
                                {/* <Link to="/explore-collections?tab=Sports">Sports</Link>
                                <Link to="/explore-collections?tab=Watches">Watches</Link> */}
                                <Link to="/explore-collections?tab=Utility">Jewellery</Link>
                            </Nav>
                            <Child name={query.get("tab")} />

                        </div>
                    </div>
                </Tab.Container>
                <Footer />
            </div>
        </>

    )

}
function Child({ name }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { collectionList } = useSelector((state) => state.collectionDetail)
    useEffect(() => {
        dispatch(getCollectionsList(name));
    }, [name])


    const collectionDetail = (id) => {
        dispatch(getCollectionDetail(id))
        localStorage.setItem('collectionId', JSON.stringify(id));
        navigate(`/collection-details`)
    }


    return (
        <div className='pt-md-5 pb-md-5 pt-4 pb-4'>
            <div className='container'>
                <Tab.Content>
                    <Tab.Pane eventKey="Trending">
                        <ul className='p-0 collectionList d-flex flex-wrap'>
                            {
                                (collectionList.data && collectionList.data.length > 0) ?
                                    collectionList.data.map((data, i) => (

                                        <li >
                                            <a className='rounded-3' onClick={() => collectionDetail(data._id)}>
                                                <span className='img rounded-3 overflow-hidden d-block'><img alt="" src={data.profileImage} className="rounded-3" /></span>
                                                <span className='name d-flex align-items-center pt-3 pb-2'><img alt="" src={data.profileImg} className="rounded-circle me-2" />{data.collectionName} {data.authentication == 'enable' ? <CheckCircleIcon className='check ms-1' /> : ''}</span>
                                            </a>
                                        </li>


                                    )) : ""


                            }
                        </ul>

                    </Tab.Pane>
                </Tab.Content>
            </div>
        </div>

    );
}
export default ExploreCollections;