import React, { useState,useEffect } from 'react';
import './ProfileMenu.scss';
import { NavLink, Link } from "react-router-dom";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const AccountMenu = () => {

    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };
    
    return (
        <>
            <div className="profileMenu">
                <a className={isActive ? 'showMenu active' : 'showMenu'} 
      onClick={toggleClass} ><span className="show">Show</span> <span className="hide">Hide</span> Menu</a>
                <div onClick={toggleClass} className={isActive ? 'bgCover active' : 'bgCover'}></div>
                <ul className={isActive ? 'p-0 m-0 active' : 'p-0 m-0'}>
                    <li><NavLink to="/submit-request" activeClassName="active"><RadioButtonCheckedIcon /> My Account</NavLink></li>
                    <li><a><RadioButtonCheckedIcon /> Wallet and Transaction Issues</a></li>
                    <li><a><RadioButtonCheckedIcon /> Buying and Selling NFTs</a></li>
                    <li><a><RadioButtonCheckedIcon /> Developer Help</a></li>
                    <li><a><RadioButtonCheckedIcon /> Report an Error Message</a></li>
                    <li><a><RadioButtonCheckedIcon /> Report Fraudulent Activity</a></li>
                </ul>
            </div>
        </>

    )

}
export default AccountMenu;