import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import './ProductDetail.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import DetailImg from '../assets/images/detail-img.jpg'
import rotate from '../assets/images/rotate.png';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import creatorImg from '../assets/images/item_img.jpg';
import UserImg from '../assets/images/user_img.jpg';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import DetailNft from '../component/DetailNft.js';
import DetailBid from '../component/DetailBid.js';
import DetailHistory from '../component/DetailHistory.js';
import HBar from '../assets/images/hedera_logo.png';
import Button from 'react-bootstrap/Button';
import RelatedItems from '../component/RelatedItems.js';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import CheckoutPopup from '../component/CheckoutPopup.js';
import { useSelector, useDispatch } from 'react-redux';
import { getNftDetail, saleStatusUpdate, auctionStatusUpdate, approvalNFT } from './redux/action/nftAction';
import { getCollectionDetail } from './redux/action/collectionAction';
import { createFav, getFavoriteDetail } from './redux/action/favouriteAction';
import { buyNftItme } from './redux/action/transactionAction';
import { createBid, getBidingDetals, claimStatusUpdate, getBidingList } from './redux/action/bidingAction';
import { saleNftItem, getSaleNftList } from './redux/action/saleNftAction';
import { FiHeart } from "react-icons/fi";
import { sellNft, startAuction, buyNft, placeBidInAuction, cancelAuction, claimNftFromEnglishAuction, claimFundsFromEnglishAuction, withdrawAuctionBalance } from '../helpers/hashconnect';
import _ from 'lodash'
import moment from 'moment';

const initialState = {
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
    approvalAnswer: '',
    NFTIntroductionFile: '',
    NFTDetailsFiles: '',
    NFTLicenseFile: ''


}

const ProductDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [licenseModel, setLicenseModel] = useState(false)
    const [iState, updateState] = useState(initialState)
    const [show, setShow] = useState(false);
    const [bidShow, setBidShow] = useState(false);
    const [bidPrice, setBidPrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [auctionPrice, setAuctionPrice] = useState('');
    const [duration, setDuration] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { nftData } = useSelector((state) => state.nftItemDetail)
    const { status, favoriteData } = useSelector((state) => state.favouriteDetail)
    const { BidingData } = useSelector((state) => state.bidingDetail)
    const [saleType, setSaleType] = useState('')
    const nftItemId = JSON.parse(localStorage.getItem('nftItemId'));
    const userId = JSON.parse(localStorage.getItem('userId'));
    let wallet = localStorage.getItem('walletAddress')
    let favData = favoriteData.data && favoriteData.data.length > 0 ? favoriteData.data[0].favoriteStatus : 0
    const {
        days,
        hours,
        minutes,
        seconds,
        approvalAnswer,
        NFTIntroductionFile,
        NFTDetailsFiles,
        NFTLicenseFile

    } = iState


    const handleBidClose = () => {
        setBidShow(false)
    }
    const handleBidShow = () => {
        setBidShow(true)
    }


    useEffect(() => {
        dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
    }, [getNftDetail])

    useEffect(() => {
        dispatch(getBidingDetals(nftItemId));
    }, [getBidingDetals])

    useEffect(() => {
        dispatch(getFavoriteDetail({ nftItemId: nftItemId }));
    }, [getFavoriteDetail])

    useEffect(() => {
        let endDate = nftData.data && nftData.data[0].durationTime
        if (endDate) {
            var countDownDate = endDate * 1000
            var x = setInterval(function () {
                var now = new Date().getTime();
                var distance = countDownDate - now;
                var getDays = Math.floor((distance % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                updateState({
                    ...iState,
                    days: getDays, hours: hours, minutes: minutes, seconds: seconds
                })
                if (distance < 0) {
                    clearInterval(x);
                    updateState({
                        ...iState,
                        days: 0, hours: 0, minutes: 0, seconds: 0
                    })
                    console.log("EXPIRED");
                }
            }, 1000);

        }

    }, [nftData.data && nftData.data[0].durationTime]);

    const handleInputChangeType = (e) => {
        const { name, value } = e.target
        setSaleType(value)
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target
        setSalePrice(value)
    }
    const handleInputChange1 = (e) => {
        const { name, value } = e.target
        setAuctionPrice(value)
    }
    const handleInputChangeDuration = (e) => {
        const { name, value } = e.target
        setDuration(value)
    }
    const handleInputBid = (e) => {
        const { name, value } = e.target
        let auctionAmount = nftData.data && nftData.data[0].auctionAmount
        if (value < auctionAmount) {
            toast.error('Amount should be grater than previous value', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setBidPrice(value)
    }

    const collectionDetail = (id) => {
        dispatch(getCollectionDetail())
        localStorage.setItem('collectionId', JSON.stringify(id));
        navigate(`/collection-details`)
    }

    const favouriteNft = (e, favoriteStatus, nftItemId) => {
        e.preventDefault();
        const data = { favoriteStatus, nftItemId }
        dispatch(createFav(data)).then(res => {
            if (res.payload.code == 200) {
                dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                dispatch(getFavoriteDetail({ nftItemId: nftItemId }));

            } else {
                dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                dispatch(getFavoriteDetail({ nftItemId: nftItemId }));
                console.log('error')
            }
        })

    }
    const handleSale = async () => {
        let tokenId = nftData.data && nftData.data[0].tokenId
        let tokenAdd = nftData.data && nftData.data[0].smartContract
        let creator = nftData.data && nftData.data[0].userName
        let collectionId = nftData.data && nftData.data[0].collectionId
        var timestamp = new Date().getUTCMilliseconds();
        let sell = await sellNft(timestamp, tokenId, tokenAdd, salePrice)

        if (sell) {
            setShow(false)
            let data = {
                nftItemId, nftItemStatus: 'sell', saletokenId: timestamp, amount: salePrice,
                creatorName: creator,
                transactionHash: sell.transactionHash,
                transactionId: sell.transactionId,
                price: salePrice,
                collectionId: collectionId
            }
            dispatch(saleNftItem(data)).then(res => {
                if (res.payload.code == 200) {
                    dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                    dispatch(getFavoriteDetail({ nftItemId: nftItemId }));
                    dispatch(getSaleNftList(nftItemId))

                } else {
                    console.log('error')
                }
            })
        }


    }

    const handleBuy = async () => {
        let saletokenId = nftData.data && nftData.data[0].saletokenId
        let amount = nftData.data && nftData.data[0].amount
        let creator = nftData.data && nftData.data[0].userName
        let ownerName = nftData.data && nftData.data[0].ownerName
        let collectionId = nftData.data && nftData.data[0].collectionId
        let buy = await buyNft(saletokenId, amount)
        if (buy) {
            let data = {
                nftItemId,
                nftItemStatus: 'created',
                creatorName: creator,
                transactionHash: buy.transactionHash,
                transactionId: buy.transactionId,
                price: amount,
                collectionId: collectionId,
                ownerName: ownerName
            }
            dispatch(buyNftItme(data)).then(res => {
                if (res.payload.code == 200) {
                    dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                    dispatch(getFavoriteDetail({ nftItemId: nftItemId }));

                } else {
                    console.log('error')
                }
            })

        }

    }
    const handleAuction = async () => {
        let tokenId = nftData.data && nftData.data[0].tokenId
        let tokenAdd = nftData.data && nftData.data[0].smartContract
        var timestamp = new Date().getUTCMilliseconds();
        var now = new Date().getTime();
        let second = now / 1000
        let durationTime = (duration * 7 * 24 * 60 * 60)
        let Dtime = second + durationTime

        let autions = await startAuction(timestamp, tokenId, tokenAdd, auctionPrice, durationTime)
        if (autions) {
            setShow(false)
            let data = { nftItemId, nftItemStatus: 'auction', auctionTokenId: timestamp, durationTime: Dtime, amount: auctionPrice, auctionAmount: auctionPrice }
            dispatch(saleStatusUpdate(data)).then(res => {
                if (res.payload.code == 200) {
                    dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                    dispatch(getFavoriteDetail({ nftItemId: nftItemId }));

                } else {
                    console.log('error')
                }
            })
        }


    }


    const handleBid = async () => {
        let auctionTokenId = nftData.data && nftData.data[0].auctionTokenId
        let amount = nftData.data && nftData.data[0].amount
        let creator = nftData.data && nftData.data[0].userName
        let durationTime = nftData.data && nftData.data[0].durationTime
        let FlorAmt = bidPrice - amount
        let floorAmt = (FlorAmt * 100 / amount)
        let bid = await placeBidInAuction(auctionTokenId, bidPrice)
        if (bid) {
            setBidShow(false)
            let data = {
                nftItemId,
                nftItemStatus: 'auction',
                creatorName: creator,
                transactionHash: bid.transactionHash,
                transactionId: bid.transactionId,
                price: bidPrice,
                Expiration: durationTime.toString(),
                bidingStatus: true,
                usdPrice: 123,
                floorDifferences: floorAmt


            }
            dispatch(createBid(data)).then(res => {
                if (res.payload.code == 200) {
                    dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                    dispatch(getFavoriteDetail({ nftItemId: nftItemId }));
                    dispatch(getBidingList(nftItemId))
                } else {
                    console.log('error')
                }
            })
        }



    }

    const auctionStatus = () => {
        let bidStatus = nftData.data && nftData.data[0].bidingStatus
        let durationTimes = moment.unix(nftData.data && nftData.data[0].durationTime).format('YYYY-MM-DD HH:mm:ss')
        let newdDate = new Date();
        let av = moment(newdDate).format('YYYY-MM-DD HH:mm:ss')
        if (av >= durationTimes && bidStatus == 'false') {
            let data = { nftItemId, nftItemStatus: 'cancel' }
            dispatch(saleStatusUpdate(data)).then(res => {
                if (res.payload.code == 200) {
                    dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                    dispatch(getFavoriteDetail({ nftItemId: nftItemId }));

                } else {
                    console.log('error')
                }
            })
        }

    }

    const claimStatus = () => {
        let bidStatus = nftData.data && nftData.data[0].bidingStatus
        let durationTimes = moment.unix(nftData.data && nftData.data[0].durationTime).format('YYYY-MM-DD HH:mm:ss')
        let newdDate = new Date();
        let av = moment(newdDate).format('YYYY-MM-DD HH:mm:ss')

        if (av >= durationTimes && bidStatus == 'true') {
            let data = { nftItemId, nftItemStatus: 'claim', claimFundStatus: 'true', claimNftStatus: 'true' }
            dispatch(claimStatusUpdate(data)).then(res => {
                if (res.payload.code == 200) {
                    dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                    dispatch(getFavoriteDetail({ nftItemId: nftItemId }));

                } else {
                    console.log('error')
                }
            })
        }

    }
    useEffect(() => {
        setInterval(() => {
            if (nftData.data && nftData.data[0].nftItemStatus == 'auction') {
                auctionStatus()
                claimStatus()
            }
        }, 10000)

    }, [])



    const handleClaimNft = async () => {
        let auctionTokenId = nftData.data && nftData.data[0].auctionTokenId
        let claimNft = await claimNftFromEnglishAuction(auctionTokenId)
        console.log('claimNft------------>', claimNft)
        if (claimNft) {
            let data = { nftItemId, nftItemStatus: 'buy', claimNftStatus: 'false' }
            dispatch(claimStatusUpdate(data)).then(res => {
                if (res.payload.code == 200) {
                    dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                    dispatch(getFavoriteDetail({ nftItemId: nftItemId }));

                } else {
                    console.log('error')
                }
            })
        }



    }

    const handleClaimFund = async () => {
        let auctionTokenId = nftData.data && nftData.data[0].auctionTokenId
        let claimFund = await claimFundsFromEnglishAuction(auctionTokenId)
        if (claimFund) {
            let data = { nftItemId, nftItemStatus: 'claim', claimFundStatus: 'false' }
            dispatch(claimStatusUpdate(data)).then(res => {
                if (res.payload.code == 200) {
                    dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                    dispatch(getFavoriteDetail({ nftItemId: nftItemId }));

                } else {
                    console.log('error')
                }
            })
        }



    }

    const handleCancelAuction = async () => {
        let auctionTokenId = nftData.data && nftData.data[0].auctionTokenId
        let cancelAuc = await cancelAuction(auctionTokenId)
        if (cancelAuc) {
            let data = { nftItemId, nftItemStatus: 'created', amount: 0 }
            dispatch(saleStatusUpdate(data)).then(res => {
                if (res.payload.code == 200) {
                    dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                    dispatch(getFavoriteDetail({ nftItemId: nftItemId }));

                } else {
                    console.log('error')
                }
            })
        }


    }


    const handleWithdrawAuction = async () => {
        let auctionTokenId = nftData.data && nftData.data[0].auctionTokenId
        let Withdraw = await withdrawAuctionBalance(auctionTokenId)
        console.log('Withdraw------------>', Withdraw)
        // if (Withdraw) {
        //     let data = { nftItemId, nftItemStatus: 'bid' }
        //     dispatch(saleStatusUpdate(data)).then(res => {
        //         if (res.payload.code == 200) {
        //             dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
        //             dispatch(getFavoriteDetail({ nftItemId: nftItemId }));

        //         } else {
        //             console.log('error')
        //         }
        //     })
        // }



    }

    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState, [name]: value,
        })
    }


    const onFileHandler = (e) => {
        const file = e.target.files[0]

        if (file) {
            // if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
            updateState({
                ...iState, NFTIntroductionFile: file
            })

            // } else {
            //     toast.error('Only formats are allowed: jpeg, jpg, png files', {
            //         position: toast.POSITION.TOP_RIGHT
            //     })
            // }
        }
    }

    const onFileHandler1 = (e) => {
        const file = e.target.files[0]

        if (file) {
            // if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
            updateState({
                ...iState, NFTDetailsFiles: file
            })
            // } else {
            //     toast.error('Only formats are allowed: jpeg, jpg, png files', {
            //         position: toast.POSITION.TOP_RIGHT
            //     })
            // }
        }
    }
    const onFileHandler2 = (e) => {
        const file = e.target.files[0]

        if (file) {
            // if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
            updateState({
                ...iState, NFTLicenseFile: file
            })

            // } else {
            //     toast.error('Only formats are allowed: jpeg, jpg, png files', {
            //         position: toast.POSITION.TOP_RIGHT
            //     })
            // }
        }
    }

    const openModal = () => {
        setLicenseModel(true)
    }
    const handleCloseModel = () => {
        setLicenseModel(false)
    }

    const approveNftRequest = (e) => {
        e.preventDefault();
        // let formIsValid = handleValidation();
        // if (formIsValid) {
        let nftItemId = nftData.data && nftData.data[0]._id
        let userId = nftData.data && nftData.data[0].userId
        const data = { nftItemId, userId, approvalAnswer, NFTIntroductionFile, NFTDetailsFiles, NFTLicenseFile }
        dispatch(approvalNFT(data)).then(res => {
            if (res.payload.code == 200) {
                toast.success(res.payload.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLicenseModel(false)
                dispatch(getNftDetail({ nftItemId: nftItemId, userId: userId }));
                dispatch(getFavoriteDetail({ nftItemId: nftItemId }));

            } else {
                console.log('error')
            }
        }).catch(err => {
            console.log('err--->', err)
        })
        //}

    }
    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>

                    <div className='container'>
                        <div className='d-flex flex-wrap detailBox'>


                            <div className='left position-relative'>
                                {
                                    nftData && nftData.data && nftData.data[0].mediaType === "image" ? <div> <span className='rotate'><img alt="" src={rotate} /></span><img src={nftData.data && nftData.data[0].itemProfile} alt='' /> </div> :
                                        <video src={nftData.data && nftData.data[0].itemProfile} width="600" height="300" controls="controls" autoplay="true" />
                                }


                                <span className='favLink'>{!wallet ? <Link to="/connect-wallet"> <FiHeart /></Link> : <Link onClick={(e) => favouriteNft(e, favData == 1 ? 0 : 1, nftData.data && nftData.data[0]._id)}>{favData === 1 ? <FiHeart style={{ color: 'red' }} /> : <FiHeart />}</Link>} <span>{nftData.data && nftData.data[0].favoriteCount}</span></span>

                            </div>
                            <div className='right'>
                                <div className='hd mb-4'>{nftData.data && nftData.data[0].itemName}</div>
                                {


                                    BidingData.data && BidingData.data.claimNftStatus == 'false' && nftData.data[0].nftItemStatus == 'claim' ? "" :
                                        nftData.data && nftData.data[0].durationTime ? <div>
                                            <div className='d-flex flex-wrap align-items-center info'>
                                                <span className='id me-3 mb-2'>Sale Ends: {moment.unix(nftData.data && nftData.data[0].durationTime).format('MMMM Do YYYY, h:mm:ss a')}
                                                </span>
                                            </div>

                                            <div className='d-flex flex-wrap align-items-center info'>
                                                <ul className='timer d-flex'>
                                                    <li>{days} <span>Days</span></li>
                                                    <li>{hours} <span>Hours</span></li>
                                                    <li>{minutes} <span>Minutes</span></li>
                                                    <li>{seconds} <span>Seconds</span></li>
                                                </ul>

                                            </div>
                                        </div> : ""
                                }


                                <div className='d-flex flex-wrap align-items-center info'>
                                    <span className='id me-3 mb-2'>#{nftData.data && nftData.data[0].nftId}</span>
                                    <span className='item d-flex align-items-center rounded-1 me-2 mb-2'>Item: {nftData.data && nftData.data[0].nftItemCount}</span>
                                    <span className='view d-flex align-items-center rounded-1 me-2 mb-2'><RemoveRedEyeOutlinedIcon className='me-2' /> {nftData.data && nftData.data[0].viewCount}</span>
                                    <span className='fav d-flex align-items-center rounded-1 me-2 mb-2'><FavoriteBorderOutlinedIcon className='me-2' /> {nftData.data && nftData.data[0].favoriteCount}</span>
                                    {nftData.data && nftData.data[0].authStatus == 'Inactive' ? <span className='item d-flex align-items-center rounded-1 me-2 mb-2' onClick={openModal}>Approve NFT</span> : ''}

                                </div>
                                <div className='desc mt-3'>{nftData.data && nftData.data[0].description}</div>
                                <div className='d-flex flex-wrap'>
                                    <NavLink to='/creator-page' className='d-flex align-items-center mt-4 me-5 createrBox'>
                                        <div className='img'><img src={nftData.data && nftData.data[0].profileImg} alt='' className='rounded-circle' /></div>
                                        <div className='det ms-3'><span className='d-block mb-1'>Creator</span> {nftData.data && nftData.data[0].userName}</div>
                                    </NavLink>
                                    <a onClick={() => collectionDetail(nftData.data && nftData.data[0].collectionId)}>
                                        <NavLink className='d-flex align-items-center mt-4 createrBox' >
                                            <div className='img'><img src={nftData.data && nftData.data[0].profileImage[0]} alt='' className='rounded-circle' /></div>
                                            <div className='det ms-3'><span className='d-block mb-1'>Collection</span>{nftData.data && nftData.data[0].collectionName}</div>
                                        </NavLink>
                                    </a>
                                </div>
                                <div className='mt-4'>
                                    <Tab.Container defaultActiveKey="Details">
                                        <Nav className='productDetTab mb-3' defaultActiveKey="Details">
                                            <Nav.Link eventKey="Details" class="active">Details</Nav.Link>
                                            <Nav.Link eventKey="Bids">Bids</Nav.Link>
                                            <Nav.Link eventKey="History">History</Nav.Link>

                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Details">
                                                <div>
                                                    <a className='d-flex align-items-center mb-3 createrBox'>
                                                        <div className='img'><img src={UserImg} alt='' className='rounded-circle' /></div>
                                                        <div className='det ms-3'><span className='d-block mb-1'>Owner</span>{nftData.data && nftData.data[0].ownerName}</div>
                                                    </a>
                                                    <div className='detList'>


                                                        <ul className='d-flex flex-wrap p-0'>


                                                            {
                                                                (nftData.data && nftData.data[0].properties.length > 0) ?
                                                                    nftData.data[0].properties.map((data, i) => (

                                                                        <li>


                                                                            <div className='inner rounded-3 p-3 text-center'>
                                                                                <div className='pb-1 blueClr'>{data.properties}</div>
                                                                                <div className='pb-1 mid'>{data.levels}</div>
                                                                                <div className='grayClr'>{data.stats}</div>


                                                                            </div>
                                                                        </li>
                                                                    )) : ""


                                                            }


                                                        </ul>
                                                    </div>

                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Bids">
                                                <DetailBid />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="History">
                                                <DetailHistory />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                                {nftData.data && nftData.data[0].amount ? <div className='detPrice d-flex align-items-center mt-4'><i className='hBar d-flex align-items-center justify-content-center rounded-circle me-2'><img src={HBar} alt='' /></i> {nftData.data && nftData.data[0].amount} <span className='ms-2'>${0.0640 * Number(nftData.data && nftData.data[0].amount)}</span></div> : ''}
                                {nftData.data && nftData.data[0].authentication == "enable" ? <div className='mt-4'>
                                    {(nftData.data && nftData.data[0].ownerId == localStorage.getItem('userid')) && (nftData.data && nftData.data[0].nftItemStatus == 'created') ? <Button className='me-3' onClick={handleShow}>Sell</Button> : ""}
                                    {(nftData.data && nftData.data[0].userId == localStorage.getItem('userid')) ? "" : nftData.data && nftData.data[0].nftItemStatus == 'sell' ? <Button className='me-3' onClick={handleBuy}>Buy Now</Button> : ""}
                                    {(nftData.data && nftData.data[0].userId == localStorage.getItem('userid')) ? "" : nftData.data && nftData.data[0].nftItemStatus == 'auction' ? <Button className='me-3' onClick={handleBidShow}>Place a Bid</Button> : ""}
                                    {(nftData.data && nftData.data[0].userId == localStorage.getItem('userid')) ? ((nftData.data && nftData.data[0].nftItemStatus == 'auction' || nftData.data && nftData.data[0].nftItemStatus == 'cancel') && nftData.data && nftData.data[0].bidingStatus == 'false') ? <Button className='me-3' onClick={handleCancelAuction}>Cancel Auction</Button> : "" : ""}
                                    {(nftData.data && nftData.data[0].userId == localStorage.getItem('userid')) ? "" : BidingData.data && BidingData.data.claimNftStatus == 'true' && nftData.data[0].nftItemStatus == 'claim' ? <Button className='me-3' onClick={handleClaimNft}>Claim Nft</Button> : ""}
                                    {(nftData.data && nftData.data[0].userId == localStorage.getItem('userid')) ? "" : BidingData.data && BidingData.data.claimFundStatus == 'true' && nftData.data[0].nftItemStatus == 'claim' ? <Button className='me-3' onClick={handleClaimFund}>Claim Fund</Button> : ""}
                                    {(nftData.data && nftData.data[0].userId == localStorage.getItem('userid')) ? nftData.data && nftData.data[0].bidingStatus == 'true' ? <Button className='me-3' onClick={handleWithdrawAuction}>Withdraw Acction</Button> : "" : ""}
                                </div> : ''}

                            </div>
                        </div>
                        <div className='pt-4 pt-md-5'>
                            <RelatedItems />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <Modal className="checkoutPopup" show={show}>
                <Modal.Body>
                    <a className='close' onClick={handleClose}><CloseIcon /></a>
                    <div className='dbMainHd text-center mb-3'>List For Sale</div>
                    <div className='checkoutTable'>
                        <div className='collectionForm'>
                            <ul className='form p-0 d-flex justify-content-between'>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Choose a Type Sale</label>
                                    <div className='d-flex flex-column grayClr ps-1 pt-1'>
                                        <label className='d-flex align-items-center radioBx'><input type='radio' name='saleType' className='radioBox me-2' value={true} onChange={handleInputChangeType} /> Fixed Price</label>
                                        <label className='d-flex align-items-center radioBx'><input type='radio' name='saleType' className='radioBox me-2' value={false} onChange={handleInputChangeType} /> Timed Auction</label>
                                    </div>
                                    {/* <span style={{ color: "red" }}>{errors.CollectibleTypeEmpty}</span> */}
                                </li>
                                <li>

                                    <div className='productImg'>
                                        {
                                            nftData && nftData.data && nftData.data[0].mediaType === "image" ? <div> <img src={nftData.data && nftData.data[0].itemProfile} alt='' /> </div> :
                                                <video src={nftData.data && nftData.data[0].itemProfile} width="600" height="300" controls="controls" autoplay="true" />
                                        }




                                    </div>

                                </li>
                            </ul>
                        </div>

                        {saleType == 'true' ? <div><ul className='form p-0'>
                            <li>
                                <label className='fieldHd d-block pb-2 ps-1'>Set a price*</label>
                                <input type="text" className='formInput' name="salePrice" value={salePrice} onChange={handleInputChange} />
                                {/* <span style={{ color: "red" }}>{errors.collectionNameEmpty}</span> */}
                            </li>
                        </ul>

                            <div className='text-center mt-4'><Button onClick={handleSale}>Complete Listing</Button></div>
                        </div> : <div>
                            <ul className='form p-0'>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Starting price*</label>
                                    <input type="text" className='formInput' name="auctionPrice" value={auctionPrice} onChange={handleInputChange1} />
                                    {/* <span style={{ color: "red" }}>{errors.collectionNameEmpty}</span> */}
                                </li>

                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Duration</label>
                                    <div className='selectBox'>
                                        <select className='formInput' name="duration" value={duration} onChange={handleInputChangeDuration}>
                                            <option>Select Duration</option>
                                            <option value={1}>One Week</option>
                                            <option value={2}>Two Week</option>
                                            <option value={3}>Three Week</option>
                                            <option value={4}>Four Week</option>


                                        </select>
                                    </div>

                                </li>
                            </ul>
                            <div className='text-center mt-4'><Button onClick={handleAuction}>Complete Listing</Button></div>
                        </div>}




                    </div>
                    {/* <div className='mt-3 grayClr'>*you can get this item on your door step</div> */}

                </Modal.Body>
            </Modal>

            <Modal className="checkoutPopup" show={bidShow}>
                <Modal.Body>
                    <a className='close' onClick={handleBidClose}><CloseIcon /></a>
                    <div className='dbMainHd text-center mb-3'>List For Sale</div>
                    <div className='checkoutTable'>
                        <div className='collectionForm'>
                            <ul className='form p-0'>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Bid price*</label>
                                    <input type="text" className='formInput' name="bidPrice" value={bidPrice} onChange={handleInputBid} />
                                    {/* <span style={{ color: "red" }}>{errors.collectionNameEmpty}</span> */}
                                </li>
                            </ul>
                        </div>



                        <div className='text-center mt-4'><Button onClick={handleBid}>Complete Listing</Button></div>
                    </div>
                    {/* <div className='mt-3 grayClr'>*you can get this item on your door step</div> */}

                </Modal.Body>
            </Modal>


            <Modal show={licenseModel} onHide={handleCloseModel}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: "black" }}></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: "black" }}>

                    <div>

                        <ul className='popupForm'>
                            <li>
                                <label className='subHd d-block pb-2 ps-1'>Why do you need License? How can we trust you? </label>
                                <label className='fieldHd d-block pb-2 ps-1'>Answer </label>
                                <textarea rows="4" cols="4" className='formInput textarea' name="approvalAnswer" value={approvalAnswer} onChange={handleChange}></textarea>
                                {/* <span style={{ color: "red" }}>{answerEmpty}</span> */}
                            </li>

                            <li>
                                <label className='subHd d-block pb-2 ps-1'>NFT Introduction File</label>

                                <input type='file' className='file' id="imageUpload"
                                    onChange={onFileHandler}
                                    onClick={e => e.target.value == null}
                                    accept=".png, .jpg, .jpeg" />
                            </li>
                            <li>
                                <label className='subHd d-block pb-2 ps-1'>NFT Details Files</label>

                                <input type='file' className='file' id="imageUpload"
                                    onChange={onFileHandler1}
                                    onClick={e => e.target.value == null}
                                    accept=".png, .jpg, .jpeg" />
                            </li>
                            <li>
                                <label className='subHd d-block pb-2 ps-1'>NFT License File
                                </label>

                                <input type='file' className='file' id="imageUpload"
                                    onChange={onFileHandler2}
                                    onClick={e => e.target.value == null}
                                    accept=".png, .jpg, .jpeg" />
                            </li>
                        </ul>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={approveNftRequest} > Save </Button>
                </Modal.Footer>
            </Modal>
        </>

    )

}
export default ProductDetail;