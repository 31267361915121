import axios from 'axios';
import config from '../config/config';

const url = `${config.Url}admin/`
export const adminLogin = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(`${url}adminLogin`, frmData);
			resolve(res.data);

			if (res.data.code === 200) {
				localStorage.setItem('adminToken', res.data.data.jwtToken);
			}
		} catch (error) {
			reject(error);
		}
	});
};

export const fetchAdmin = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const accessJWT = localStorage.getItem('adminToken');

			if (!accessJWT) {
				reject('Token not found!');
			}

			const res = await axios.get(`${url}getAdminDetail`, {
				headers: {
					token: accessJWT,
				},
			});

			resolve(res.data);
		} catch (error) {
			console.log(error);
			reject(error.message);
		}
	});
};

export const adminLogout = async () => {
	try {
		await axios.get(`${url}adminLogout`, {
			headers: {
				token: localStorage.getItem('adminToken'),
			},
		});
	} catch (error) {
		console.log(error);
	}
};

export const updateAdminProfile = (frmData) => {
	console.log('from api', frmData);
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.put(`${url}updateAdminProfile`, frmData,
				{
					headers: {
						token: localStorage.getItem('adminToken'),
					},
				}
			);
			console.log('RESULT', result.data);
			resolve(result.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const adminChangePassword = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.post(`${url}changeAdminPassword`, frmData,
				{
					headers: {
						token: localStorage.getItem('adminToken'),
					},
				}
			);
			resolve(result.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getAllUser = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.get(`${url}getAdminUserList`, {
				headers: {
					token: localStorage.getItem('adminToken'),
				},
			});

			resolve(result);
		} catch (error) {
			reject(error);
		}
	});
};

export const fetchAdminDashboard = (frmDt) => {
	return new Promise(async (resolve, reject) => {
		try {
			const accessJWT = localStorage.getItem('adminToken');

			if (!accessJWT) {
				reject('Token not found!');
			}

			const res = await axios.get(`${url}adminDashboard?pageNumber=${frmDt.page}&search=${frmDt.serach}`, {
				headers: {
					token: accessJWT,
				},
			});

			resolve(res.data);
		} catch (error) {
			console.log(error);
			reject(error.message);
		}
	});
};

export const adminFogotPass = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(`${url}adminForgetPassword`, frmData);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const adminVeriyOtp = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(`${url}adminVerifyOtp`, frmData);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const adminResendOtp = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(`${url}resendOtp`, frmData);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const resetAdminPassword = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(`${url}resetAdminPassword`, frmData);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};