import { getAllSearch } from '../../../api/allSearchApi';
import { allSearchPending, allSearchSuccess, allSearchError, allSearchResetMessage } from '../reducer/allsearchReducer';


export const allSearch = (frmDt) => async (dispatch) => {
    try {
        dispatch(allSearchPending());
        const result = await getAllSearch(frmDt);
        result.data.code === 200
            ? dispatch(allSearchSuccess(result.data))
            : dispatch(allSearchError(result.message));


    } catch (error) {
        dispatch(allSearchResetMessage(error.message));
    }
};