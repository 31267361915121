import { getAllWatchList, createWatchlist } from '../../../api/watchListApi';
import {
    getWatchListPending, getWatchListSuccess, getWatchListError, getWatchListResetMessage,
    createWatchlistPending, createWatchlistSuccess, createWatchlistError, createWatchlistResetMessage
} from '../reducer/watchListReducer';
import { multiPartData } from '../../../utils';



export const createWatchLists = (frmDt) => async (dispatch) => {
    try {
        dispatch(createWatchlistPending());
        const result = await createWatchlist(frmDt);
        if (result.code == 200) {
            return dispatch(createWatchlistSuccess(result))
        } else {
            return dispatch(createWatchlistError(result.message));
        }

    } catch (error) {
        dispatch(createWatchlistResetMessage(error.message));
    }
};

export const getWatchList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getWatchListPending());
        const result = await getAllWatchList();
        result.data.code === 200
            ? dispatch(getWatchListSuccess(result.data))
            : dispatch(getWatchListError(result.message));


    } catch (error) {
        dispatch(getWatchListResetMessage(error.message));
    }
};

