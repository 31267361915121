import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactionList } from '../pages/redux/action/transactionAction';
import { getSaleNftList } from '../pages/redux/action/saleNftAction';
import moment from 'moment';
const DetailHistory = () => {
    const dispatch = useDispatch();
    const { transactionList } = useSelector((state) => state.transactionDetail)
    const { saleNftList } = useSelector((state) => state.saleNftDetail)
    const nftItemId = JSON.parse(localStorage.getItem('nftItemId'));
    const getTime = (createdAt) => {
        let toDate = moment(createdAt).format("YYYY-MM-DD")
        var countDownDate = new Date(createdAt).getTime();
        var now = new Date().getTime();
        let distance = now - countDownDate
        var getDays = Math.floor((distance % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if (hours < 0 && minutes < 59 && seconds < 59) {
            return (`${minutes} Minutes Ago`)
        } else if (getDays == 0 && hours < 59 && minutes < 59) {
            return (`${hours}:${minutes} Hours Ago`)
        } else {
            return toDate
        }


    }

    useEffect(() => {
        dispatch(getSaleNftList(nftItemId));
    }, [getSaleNftList])

    return (
        <>
            <div className='dbTable rounded-3'>
                <table>
                    <tr>
                        <th>Event</th>
                        <th>Price</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Date</th>
                    </tr>

                    {
                        (saleNftList.data && saleNftList.data.length > 0) ?
                            saleNftList.data.map((data, i) => (

                                <tr>
                                    <td>Offer</td>
                                    <td>{data.price}</td>
                                    <td>{data.creatorName}</td>
                                    <td>{data.userName}</td>
                                    <td>
                                        <span data-tip data-for="time1"> {getTime(data.createdAt)}</span>
                                        <ReactTooltip className='tooltipBox' id="time1" place="top" effect="solid">
                                            {data.createdAt}
                                        </ReactTooltip>
                                    </td>
                                </tr>
                            )) : <div>
                                <div className='text-center pt-3 pb-4 noResult'>No Record Found</div>
                            </div>
                    }
                </table>
            </div>
        </>

    )

}
export default DetailHistory;