import { configureStore } from "@reduxjs/toolkit";
import logger from 'redux-logger'
import walletStatusSlice from './pages/connectWalletRedux/connectWalletSlice';
import getUsersDetail from './pages/redux/reducer/userReducer'
import adminDetail from './pages/redux/reducer/adminReducer'
import adminUserDetail from './pages/redux/reducer/adminUserReducer'
import adminNftDetail from './pages/redux/reducer/adminNftReducer'
import collectionDetail from './pages/redux/reducer/collectionReducer'
import watchListDetail from './pages/redux/reducer/watchListReducer'
import nftDetail from './pages/redux/reducer/nftReducer'
import favouriteDetail from './pages/redux/reducer/favouriteReducer'
import transactionDetail from './pages/redux/reducer/transactionReducer'
import bidingDetail from './pages/redux/reducer/bidingReducer'
import saleNftDetail from './pages/redux/reducer/saleNftReducer'
import allsearchDetail from './pages/redux/reducer/allsearchReducer'
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  walletStatus: walletStatusSlice,
  userDetail: getUsersDetail,
  admin: adminDetail,
  adminUserDetail: adminUserDetail,
  adminNftDetail: adminNftDetail,
  collectionDetail: collectionDetail,
  watchList: watchListDetail,
  nftItemDetail: nftDetail,
  favouriteDetail: favouriteDetail,
  transactionDetail:transactionDetail,
  bidingDetail:bidingDetail,
  saleNftDetail:saleNftDetail,
  allsearchDetail:allsearchDetail
});


const persistedReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;