import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import './Admin.scss';
import AdminFooter from '../../component/AdminFooter'
import logo from '../../assets/images/logo.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { adminLogin } from '../../api/adminApi';
import { adminLoginPending, adminLoginSuccess, adminLoginError, adminLoginResetMessage } from '../redux/reducer/adminReducer';
import { loginAdmin } from '../redux/action/adminAction';

const initialState = {
    email: '',
    password: '',
    // rememberMe: false,
    emailIdError: '',
    passwordError: '',
    errorMsg: ''
}
const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [rememberMe, setRememberMe] = useState(false);
    const [iState, updateState] = useState(initialState)
    const { isLoding, status, message } = useSelector((state) => state.admin)
    const {
        email,
        password,
        //rememberMe,
        emailIdError,
        passwordError,
        errorMsg
    } = iState


    

    let handleValidation = () => {
        let emailIdError = '';
        let passwordError = '';
        let formIsValid = true;

        if (!email) {
            emailIdError = 'Please enter email ID.';
            formIsValid = false
        } else if (!/^.+?@.+?\..+$/.test(email)) {
            emailIdError = "Email format is not valid";
            formIsValid = false;
        }
        if (!password) {
            passwordError = 'Please enter password.'
        }
        updateState({
            ...iState, emailIdError, passwordError, errorMsg
        });
        return formIsValid

    }

    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState, [name]: value

        })
    }

    const handleRememberMeChange = (e) => {
        const { name, checked } = e.target
        setRememberMe(checked)
    };

    useEffect(() => {
        if ( Cookies.get("rememberMe")=='checked') {
            const storedUsername = Cookies.get("email"); 
            const storedPassword = Cookies.get("password");
            updateState({
                ...iState, email: storedUsername || '', password: storedPassword || ''
            });
            setRememberMe(true)
        }
    }, [Cookies.get("rememberMe")=='checked']);
    const handleSubmit = async (e) => {
        e.preventDefault();
        let formIsValid = handleValidation()
        if (formIsValid) {
            if (rememberMe) {
                Cookies.set('email', email); 
                Cookies.set('password', password); 
                Cookies.set('rememberMe', 'checked'); 
            } else {
                Cookies.remove('email'); 
                Cookies.remove('password'); 
                Cookies.remove('rememberMe');
            }
            dispatch(adminLoginPending());
            try {
                const data = { email, password }
                const isAuth = await adminLogin(data);
                if (isAuth.code === 200) {
                    dispatch(adminLoginSuccess(isAuth.message));
                    toast.success(isAuth.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    navigate('/dashboard')
                } else {
                    dispatch(adminLoginError(isAuth.message));
                    toast.error(isAuth.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    updateState({
                        ...iState, errorMsg: message, emailIdError: '', passwordError: ''
                    })
                }
            } catch (error) {
                dispatch(adminLoginError(error.message));
            }
        }
    }

    return (
        <>
            <div className='adminTop d-flex align-items-center justify-content-center'>
                <div className='w-100'>
                    <div className='mb-4 mb-md-5 text-center'><img alt="" src={logo} /></div>
                    <div className='loginForm'>
                        <form >
                            <div className='inner'>

                                <div className='loginHd text-center mb-4'>Signin to Ledex Protocol Admin</div>

                                <ul className='form p-0'>
                                    <li>
                                        <input type="email" className='formInput' placeholder='User Name*' name="email" value={email} onChange={handleChange} />
                                        <span style={{ color: "red" }}>{emailIdError}</span>
                                    </li>
                                    <li>
                                        <input type="password" className='formInput' placeholder='Password' name="password" value={password} onChange={handleChange} />
                                        <span style={{ color: "red" }}>{passwordError}</span>
                                    </li>
                                    <li className='d-flex justify-content-between align-items-center'>
                                        <label className='rem d-flex align-items-center'><input type="checkbox" className='checkbox me-2' checked={rememberMe} onChange={handleRememberMeChange} /> Remember Me</label>
                                        <NavLink to="/forgot-password" className='fp'>Forgot Password?</NavLink>

                                    </li>
                                    <li><Button type="button" className='full' onClick={handleSubmit}>Sign in</Button></li>
                                </ul>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <AdminFooter />
        </>

    )

}
export default Login;