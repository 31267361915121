import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { getBidingList } from '../pages/redux/action/bidingAction';
import moment from 'moment';

const DetailBid = () => {

    const dispatch = useDispatch();
    const { BidingList } = useSelector((state) => state.bidingDetail)
    const nftItemId = JSON.parse(localStorage.getItem('nftItemId'));
    useEffect(() => {
        dispatch(getBidingList(nftItemId));
    }, [getBidingList])


    return (
        <>
            <div className='dbTable  rounded-3'>
                <table>
                    <tr>
                        <th>Price</th>
                        <th>USD Price</th>
                        <th>Floor Differences</th>
                        <th>Expiration</th>
                        <th>From</th>
                    </tr>

                    {
                        (BidingList.data && BidingList.data.length > 0) ?
                            BidingList.data.map((data, i) => (

                                <tr>
                                    <td>{data.price} HBAR</td>
                                    <td>${data.usdPrice}</td>
                                    <td>
                                        <span data-tip data-for="fd2">{data.floorDifferences}% below</span>
                                        <ReactTooltip className='tooltipBox' id="fd2" place="top" effect="solid">
                                            Collection floor price: 78.45 ETH
                                        </ReactTooltip>
                                    </td>
                                    <td>{moment.unix(data.Expiration).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                    <td>
                                        {data.userName}
                                    </td>
                                </tr>
                            )) : 
                            <tr>
                                <td colSpan="5"><div className='text-center pt-3 pb-4 noResult'>No Record Found</div></td>
                            </tr>


                    }
                    
                </table>
            </div>
        </>

    )

}
export default DetailBid;