import React, { useState, useEffect } from 'react';
import './MyProfile.scss';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import bannerImg from '../assets/images/watch-banner.jpg';
import UserImg from '../assets/images/watch_img2.png';
import HBar from '../assets/images/hedera_logo.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InstagramIcon from '../assets/images/instagram.png';
import mediumIcon from '../assets/images/mediums.png';
import TiwtterIcon from '../assets/images/twitter.png';
import RedditIcon from '../assets/images/reddit.png';
import Interneted from '../assets/images/internetd.png';
import Share from '../assets/images/share.png';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HBarWht from '../assets/images/hbar_wht.png';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import QRCode from "react-qr-code";
import Items from '../component/Items.js';
import { useSelector, useDispatch } from 'react-redux';
import { createWatchLists } from './redux/action/watchListAction'
import { getCollectionDetail, createRequest } from './redux/action/collectionAction';

const initialState = {
    collectionIntroduction: "",
    collectionDetailsFile: "",
    CollectionLicenseNFTfile: "",
    filesReview: "",
    errors: {},
}

const CollectionDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [iState, updateState] = useState(initialState)
    const { collectionData } = useSelector((state) => state.collectionDetail)
    const { status } = useSelector((state) => state.watchList)
    const [licenseModel, setLicenseModel] = useState(false)
    const [answer, setAnswer] = useState("")
    const [answerEmpty, setAnswerEmpty] = useState("")
    let wallet = localStorage.getItem('walletAddress')
    const collectionId = JSON.parse(localStorage.getItem('collectionId'));
    const {
        collectionIntroduction,
        collectionDetailsFile,
        CollectionLicenseNFTfile,
        filesReview,
        errors,
    } = iState


    useEffect(() => {
        dispatch(getCollectionDetail(collectionId));
    }, [getCollectionDetail])


    const createWatchList = (e, collectionId) => {
        e.preventDefault();
        const data = { collectionId }
        dispatch(createWatchLists(data)).then(res => {

            if (res.payload.code == 200) {
                toast.success(res.payload.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                //navigate('/my-watchlist')
            } else {
                console.log('error')
            }
        }).catch(err => {
            console.log('err--->', err)
        })

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setAnswer(value)

    }

    const handleValidation = () => {
        let answEmpty = ''
        let formIsValid = true;


        if (!answer.trim()) {
            answEmpty = "Please insert answer"
            formIsValid = false;
        }
        setAnswerEmpty(answEmpty)
        return formIsValid;
    }

    const onFileHandler = (e) => {
        const file = e.target.files[0]

        if (file) {
            // if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
            previewsFile(file)

            // } else {
            //     toast.error('Only formats are allowed: jpeg, jpg, png files', {
            //         position: toast.POSITION.TOP_RIGHT
            //     })
            // }
        }
    }


    const previewsFile = (file) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
            updateState({
                ...iState, filesReview: reader.result, collectionIntroduction: file
            })
        }, false)
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const onFileHandler1 = (e) => {
        const file = e.target.files[0]

        if (file) {
            // if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
            updateState({
                ...iState, collectionDetailsFile: file
            })
            // } else {
            //     toast.error('Only formats are allowed: jpeg, jpg, png files', {
            //         position: toast.POSITION.TOP_RIGHT
            //     })
            // }
        }
    }
    const onFileHandler2 = (e) => {
        const file = e.target.files[0]

        if (file) {
            // if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
            updateState({
                ...iState, CollectionLicenseNFTfile: file
            })

            // } else {
            //     toast.error('Only formats are allowed: jpeg, jpg, png files', {
            //         position: toast.POSITION.TOP_RIGHT
            //     })
            // }
        }
    }

    const createAuthRequest = (e) => {
        e.preventDefault();
        let formIsValid = handleValidation();
        if (formIsValid) {
            let collectionId = collectionData.data.getCollection[0] && collectionData.data.getCollection[0]._id
            let userId = collectionData.data.getCollection[0] && collectionData.data.getCollection[0].userId
            const data = { collectionId, userId, answer, collectionIntroduction, collectionDetailsFile, CollectionLicenseNFTfile }
            dispatch(createRequest(data)).then(res => {
                if (res.payload.code == 200) {
                    toast.success(res.payload.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setLicenseModel(false)
                    dispatch(getCollectionDetail(collectionId));
                } else {
                    console.log('error')
                }
            }).catch(err => {
                console.log('err--->', err)
            })
        }

    }

    const openStatsModal = () => {
        setLicenseModel(true)
    }
    const handleStatsClose = () => {
        setLicenseModel(false)
    }



    return (
        <>
            <div className="mainCon">
                <Header />
                <div>
                    <div className='profileBanner' style={{ backgroundImage: 'url(' + (collectionData && collectionData.data && collectionData.data.getCollection.length > 0 ? collectionData && collectionData.data && collectionData.data.getCollection[0].bannerImage[0] : '') + ')', backgroundPosition: 'center' }}><img alt="" src={bannerImg} /></div>
                    <div className='container'>
                        <div className='d-flex flex-column flex-lg-row justify-content-between profileTop'>
                            <div className='left'>
                                <div className='userImg'><img alt="" src={collectionData.data && collectionData.data.getCollection.length > 0 ? collectionData.data && collectionData.data.getCollection[0].profileImage[0] : ''} /></div>
                                <div className='userName pt-2'>{collectionData.data && collectionData.data.getCollection[0].collectionName}{collectionData.data && collectionData.data.getCollection[0].authentication == 'enable' ? <CheckCircleIcon className='check ms-1' /> : ''}</div>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3 mt-3'><span className='grayClr'>Created by </span> {collectionData.data && collectionData.data.getCollection[0].userName}</div>
                                    <div className='d-inline-flex align-items-center mt-3 userId position-relative'>
                                        <span className='bar d-block text-center'><img alt="" src={HBar} /></span>
                                        <span className='d-block code ps-1 pe-2'>{collectionData.data && collectionData.data.getCollection[0].walletAddress}</span>
                                        <span className='copy pe-3'><a><ContentCopyIcon /></a></span>
                                    </div>
                                </div>

                                <ul className='info p-0 pt-4 d-flex flex-wrap'>
                                    <li><span className='d-block'>{collectionData.data && collectionData.data.collectionCount}</span> items</li>
                                    <li><span className='d-block'>{collectionData.data && collectionData.data.ownerCount}</span> owners</li>
                                    <li>
                                        <div className='position-relative barBox'><img src={HBarWht} alt='' /> <span className='d-block'>{collectionData.data && collectionData.data.totalVolumes.length > 0 ? collectionData.data.totalVolumes[0].total : 0}</span> total volumes</div>
                                    </li>
                                    <li>
                                        <div className='position-relative barBox'><img src={HBarWht} alt='' /> <span className='d-block'>{collectionData.data && collectionData.data.totalVolumes.length > 0 ? collectionData.data.totalVolumes[0].minAmount : 0}</span> floor price</div>
                                    </li>
                                    <li>
                                        <div className='position-relative barBox'><img src={HBarWht} alt='' /> <span className='d-block'>0</span> best offers</div>
                                    </li>


                                </ul>
                                <ul className='info p-0 pt-4 d-flex flex-wrap'>

                                    <li>
                                        <div className='position-relative'> <span className='d-block'></span>{(collectionData.data && collectionData.data.getCollection[0].authStatus == 'Inactive') ? <Link className='button' onClick={() => openStatsModal()}>Request Authentiction License</Link> : ""}</div>
                                    </li>
                                    <li>
                                        {/* <div className='position-relative'> <span className='d-block'></span><Link className='button' to="/create-new-item">Create NFT</Link></div> */}
                                    </li>
                                    {collectionData.data && collectionData.data.getCollection[0].authentication == 'enable' ? <li><div style={{ height: "auto", margin: "0 auto", maxWidth: 100, width: "100%" }}>
                                        {/* <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value="hey"
                                            viewBox={`0 0 256 256`}
                                        /> */}
                                    </div></li>
                                        : ''}


                                </ul>
                            </div>
                            <div className='right'>
                                <ul className='d-flex flex-wrap p-0 profileSocial'>
                                    <li><a href={collectionData.data && collectionData.data.getCollection[0].instagram} target="_blank"><img alt="" src={InstagramIcon} /></a></li>
                                    <li><a href={collectionData.data && collectionData.data.getCollection[0].twitter} target="_blank"><img alt="" src={TiwtterIcon} /></a></li>
                                    <li><a href={collectionData.data && collectionData.data.getCollection[0].discord} target="_blank"><img alt="" src={RedditIcon} /></a></li>
                                    <li><a href={collectionData.data && collectionData.data.getCollection[0].website} target="_blank"><img alt="" src={Interneted} /></a></li>
                                    <li><a href={collectionData.data && collectionData.data.getCollection[0].medium} target="_blank"><img alt="" src={mediumIcon} /></a></li>
                                    <li className='fav'>{!wallet ? <Link to="/connect-wallet"><StarBorderIcon /></Link> : <Link onClick={(e) => createWatchList(e, collectionData.data && collectionData.data.getCollection[0]._id)}><StarBorderIcon /></Link>}</li>
                                    {/* <li className='follow'><a>+ Follow</a></li>
                                    <li className='share'><a><img alt="" src={Share} /></a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Tab.Container defaultActiveKey="Items">
                        <div className='topCon'>
                            <div className='container'>
                                <Nav className='d-flex m-0 ps-0 pt-4 tabs' defaultActiveKey="Items">
                                    <Nav.Link eventKey="Items">Items</Nav.Link>
                                    {/* <Nav.Link eventKey="Activity">Activity</Nav.Link> */}
                                </Nav>
                            </div>
                        </div>
                        <div className='pt-md-5 pb-md-5 pt-4 pb-4'>
                            <div className='container'>
                                <Tab.Content>
                                    <Tab.Pane eventKey="Items">
                                        <Items />
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="Activity">
                                        Activity Detail Goes Here
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                    <Modal show={licenseModel} onHide={handleStatsClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: "black" }}></Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ color: "black" }}>

                            <div>

                                <ul className='popupForm'>
                                    <li>
                                        <label className='subHd d-block pb-2 ps-1'>Why do you need License? How can we trust you? </label>
                                        <label className='fieldHd d-block pb-2 ps-1'>Answer </label>
                                        <textarea rows="4" cols="4" className='formInput textarea' name="description" value={answer} onChange={handleInputChange}></textarea>
                                        <span style={{ color: "red" }}>{answerEmpty}</span>
                                    </li>

                                    <li>
                                        <label className='subHd d-block pb-2 ps-1'>Collection Introduction File</label>

                                        <input type='file' className='file' id="imageUpload"
                                            onChange={onFileHandler}
                                            onClick={e => e.target.value == null}
                                            accept=".png, .jpg, .jpeg" />
                                    </li>
                                    <li>
                                        <label className='subHd d-block pb-2 ps-1'>Collection Details Files</label>

                                        <input type='file' className='file' id="imageUpload"
                                            onChange={onFileHandler1}
                                            onClick={e => e.target.value == null}
                                            accept=".png, .jpg, .jpeg" />
                                    </li>
                                    <li>
                                        <label className='subHd d-block pb-2 ps-1'>Collection License
                                            NFT  </label>

                                        <input type='file' className='file' id="imageUpload"
                                            onChange={onFileHandler2}
                                            onClick={e => e.target.value == null}
                                            accept=".png, .jpg, .jpeg" />
                                    </li>
                                </ul>
                            </div>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={createAuthRequest}> Save </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default CollectionDetails;