import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    status: '',
    message: '',
    allSearchList: [],
};

const allsearchDetail = createSlice({
    name: 'allsearchDetail',
    initialState,
    reducers: {
        allSearchPending: (state) => {
            state.isLoading = true;
        },
        allSearchSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.allSearchList = payload
        },
        allSearchError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        allSearchResetMessage: (state) => {
            state.message = '';
        },



    },
});

const { reducer, actions } = allsearchDetail;
export const { allSearchPending, allSearchSuccess, allSearchError, allSearchResetMessage } = actions;

export default reducer;