import React, { } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import './Admin.scss';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Ticket from '../../assets/images/ticket_dark.png';


const SupportTickets = () => {
    const navigate = useNavigate();
    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className='d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center'>
                        <div className="dbMainHd d-flex align-items-center mb-3"><span className='d-flex align-items-center justify-content-center rounded-1 me-3'><img alt='' src={Ticket} /></span> Support Tickets</div>
                            <div className='d-flex mb-3'>
                                <div className='search me-2'>
                                    <input type='text' className='input' placeholder='Search...' />
                                    <SearchIcon className='searchIcon' />
                                </div>
                                <div><a className='filter d-flex justify-content-center align-items-center'><FilterAltIcon /></a></div>
                            </div>
                        </div>
                        
                        <div className='dbTable rounded-3'>
                        <table>
                                <tr>
                                    <th>ID</th>
                                    <th>Requester</th>
                                    <th>Subject</th>
                                    <th>Agent</th>
                                    <th>Status</th>
                                    <th>Create Date & Time</th>
                                    <th>Attachment</th>
                                </tr>
                                {/* <tr className='cursor-pointer' onClick={() => navigate("/support-tickets/detail")}>
                                    <td>#123456</td>
                                    <td>Javon Terry <span className='d-block mt-1 grayClr font14'>Javon_terry@email.com</span></td>
                                    <td>Transaction Issue</td>
                                    <td>Charlie Fernandez</td>
                                    <td><span className='status open d-inline-block'>Open</span></td>
                                    <td className='text-nowrap'>8/8/2022 l 11:25AM</td>
                                    <td>
                                        <div className='attachText text-nowrap d-flex'><AttachmentIcon className='me-1' />payment issue.jpg</div>
                                    </td>
                                </tr>
                                <tr className='cursor-pointer' onClick={() => navigate("/support-tickets/detail")}>
                                    <td>#123456</td>
                                    <td>Javon Terry <span className='d-block mt-1 grayClr font14'>Javon_terry@email.com</span></td>
                                    <td>Transaction Issue</td>
                                    <td>Charlie Fernandez</td>
                                    <td><span className='status solved d-inline-block'>Solved</span></td>
                                    <td className='text-nowrap'>8/8/2022 l 11:25AM</td>
                                    <td>
                                        <div className='attachText text-nowrap d-flex'><AttachmentIcon className='me-1' />payment issue.jpg</div>
                                    </td>
                                </tr>
                                <tr className='cursor-pointer' onClick={() => navigate("/support-tickets/detail")}>
                                    <td>#123456</td>
                                    <td>Javon Terry <span className='d-block mt-1 grayClr font14'>Javon_terry@email.com</span></td>
                                    <td>Transaction Issue</td>
                                    <td>Charlie Fernandez</td>
                                    <td><span className='status open d-inline-block'>Open</span></td>
                                    <td className='text-nowrap'>8/8/2022 l 11:25AM</td>
                                    <td>
                                        <div className='attachText text-nowrap d-flex'><AttachmentIcon className='me-1' />payment issue.jpg</div>
                                    </td>
                                </tr>
                                <tr className='cursor-pointer' onClick={() => navigate("/support-tickets/detail")}>
                                    <td>#123456</td>
                                    <td>Javon Terry <span className='d-block mt-1 grayClr font14'>Javon_terry@email.com</span></td>
                                    <td>Transaction Issue</td>
                                    <td>Charlie Fernandez</td>
                                    <td><span className='status open d-inline-block'>Open</span></td>
                                    <td className='text-nowrap'>8/8/2022 l 11:25AM</td>
                                    <td>
                                        <div className='attachText text-nowrap d-flex'><AttachmentIcon className='me-1' />payment issue.jpg</div>
                                    </td>
                                </tr>
                                <tr className='cursor-pointer' onClick={() => navigate("/support-tickets/detail")}>
                                    <td>#123456</td>
                                    <td>Javon Terry <span className='d-block mt-1 grayClr font14'>Javon_terry@email.com</span></td>
                                    <td>Transaction Issue</td>
                                    <td>Charlie Fernandez</td>
                                    <td><span className='status closed d-inline-block'>Closed</span></td>
                                    <td className='text-nowrap'>8/8/2022 l 11:25AM</td>
                                    <td>
                                        <div className='attachText text-nowrap d-flex'><AttachmentIcon className='me-1' />payment issue.jpg</div>
                                    </td>
                                </tr>
                                <tr className='cursor-pointer' onClick={() => navigate("/support-tickets/detail")}>
                                    <td>#123456</td>
                                    <td>Javon Terry <span className='d-block mt-1 grayClr font14'>Javon_terry@email.com</span></td>
                                    <td>Transaction Issue</td>
                                    <td>Charlie Fernandez</td>
                                    <td><span className='status open d-inline-block'>Open</span></td>
                                    <td className='text-nowrap'>8/8/2022 l 11:25AM</td>
                                    <td>
                                        <div className='attachText text-nowrap d-flex'><AttachmentIcon className='me-1' />payment issue.jpg</div>
                                    </td>
                                </tr>
                                <tr className='cursor-pointer' onClick={() => navigate("/support-tickets/detail")}>
                                    <td>#123456</td>
                                    <td>Javon Terry <span className='d-block mt-1 grayClr font14'>Javon_terry@email.com</span></td>
                                    <td>Transaction Issue</td>
                                    <td>Charlie Fernandez</td>
                                    <td><span className='status open d-inline-block'>Open</span></td>
                                    <td className='text-nowrap'>8/8/2022 l 11:25AM</td>
                                    <td>
                                        <div className='attachText text-nowrap d-flex'><AttachmentIcon className='me-1' />payment issue.jpg</div>
                                    </td>
                                </tr>
                                <tr className='cursor-pointer' onClick={() => navigate("/support-tickets/detail")}>
                                    <td>#123456</td>
                                    <td>Javon Terry <span className='d-block mt-1 grayClr font14'>Javon_terry@email.com</span></td>
                                    <td>Transaction Issue</td>
                                    <td>Charlie Fernandez</td>
                                    <td><span className='status open d-inline-block'>Open</span></td>
                                    <td className='text-nowrap'>8/8/2022 l 11:25AM</td>
                                    <td>
                                        <div className='attachText text-nowrap d-flex'><AttachmentIcon className='me-1' />payment issue.jpg</div>
                                    </td>
                                </tr>
                                <tr className='cursor-pointer' onClick={() => navigate("/support-tickets/detail")}>
                                    <td>#123456</td>
                                    <td>Javon Terry <span className='d-block mt-1 grayClr font14'>Javon_terry@email.com</span></td>
                                    <td>Transaction Issue</td>
                                    <td>Charlie Fernandez</td>
                                    <td><span className='status open d-inline-block'>Open</span></td>
                                    <td className='text-nowrap'>8/8/2022 l 11:25AM</td>
                                    <td>
                                        <div className='attachText text-nowrap d-flex'><AttachmentIcon className='me-1' />payment issue.jpg</div>
                                    </td>
                                </tr> */}
                            </table>
                        </div>
                        {/* <div className='paging d-flex mt-3'>
                            <ul className='d-flex m-0 p-0 rounded-3 overflow-hidden'>
                                <li><a href='#'>Previous</a></li>
                                <li className='active'><a href='#'>1</a></li>
                                <li><a href='#'>2</a></li>
                                <li><a href='#'>3</a></li>
                                <li><a href='#'>.</a></li>
                                <li><a href='#'>102</a></li>
                                <li><a href='#'>Next</a></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </>

    )

}
export default SupportTickets;