import React, { } from 'react';
import './EditProfile.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import ProfileMenu from '../component/ProfileMenu';
import Button from 'react-bootstrap/Button';
const Offer = () => {



    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='d-flex flex-wrap'>
                            <ProfileMenu />
                            <div className='profileDet'>
                                <div className='mainHd mb-2 mb-md-3'>Offer Settings</div>
                                <div className='noteText mb-4 mb-md-5'>Set a minimum offer for collections to ignore low offers.</div>
                                <div><Button type="button" className='viewOffer'>View My Offers</Button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default Offer;