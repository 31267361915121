import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import './MyPurchasedNftDetails.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import DetailImg from '../assets/images/watch_img6.png'
import rotate from '../assets/images/rotate.png';
import DetailNft from '../component/DetailNft.js';
import Button from 'react-bootstrap/Button';
const MyPurchasedNftDetails = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                <div className='container'>
                <div className='mb-4 mb-md-5 purchaceDetail d-flex flex-wrap'>
                    <div className='img position-relative'><span className='rotate'><img alt="" src={rotate} /></span><img src={DetailImg} alt='' /></div>
                    <div className='det ps-0 ps-md-4 ps-xl-5'>
                        <ul className='p-0 m-0'>
                            <li className='d-flex pt-3 pb-3'>
                                <span className='left me-3'>Reference No:</span>
                                <span className='right'>#123456</span>
                            </li>
                            <li className='d-flex pt-3 pb-3'>
                                <span className='left me-3'>NFT ID:</span>
                                <span className='right'>#1234</span>
                            </li>
                            <li className='d-flex pt-3 pb-3'>
                                <span className='left me-3'>NFT Name:</span>
                                <span className='right'>Luxury Watch</span>
                            </li>
                            <li className='d-flex pt-3 pb-3'>
                                <span className='left me-3'>Owner:</span>
                                <span className='right'>Owner Name Here</span>
                            </li>
                            <li className='d-flex pt-3 pb-3'>
                                <span className='left me-3'>Price:</span>
                                <span className='right'>5.89 HBAR</span>
                            </li>
                            <li className='d-flex pt-3 pb-3'>
                                <span className='left me-3'>Address:</span>
                                <span className='right'>133 Ramblewood Ave. Olney, MD 20832</span>
                            </li>
                            <li className='d-flex pt-3 pb-3'>
                                <span className='left me-3'>Expected Delivery:</span>
                                <span className='right'>8/8/2022</span>
                            </li>
                            <li className='d-flex pt-3 pb-3'>
                                <span className='left me-3'>Order Date:</span>
                                <span className='right'>4/8/2022</span>
                            </li>
                            <li className='d-flex pt-3 pb-3'>
                                <span className='left me-3'>Buy Date:</span>
                                <span className='right'>2/8/2022</span>
                            </li>
                            <li className='d-flex pt-3 pb-3'>
                                <span className='left me-3'>Delivery Status:</span>
                                <span className='right'>
                                    <span className='status d-inline-block processing'>Processing</span>
                                </span>
                            </li>
                            <li className='d-flex pt-3 pb-3'>
                                <span className='left me-3'>Cetrificate:</span>
                                <span className='right'>
                                    <Button className='downloadBtn'>Download</Button>
                                </span>
                            </li>
                            <li className='d-flex pt-3 pb-3 desc'>
                                <span className='left me-3'>Description:</span>
                                <span className='right'>Habitant sollicitudin faucibus cursus lectus pulvinar dolor non ultrices eget. Facilisi lobortisal morbi fringilla urna amet sed ipsum vitae ipsum malesuada. Habitant sollic itudin faucibus cursus lectus pulvinar dolor non ultrices eget. Facilisi lobortisal morbi</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div className='dbMainHd mb-3'>Details</div>
                    <div><DetailNft /></div>
                </div>
                </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default MyPurchasedNftDetails;