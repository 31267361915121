import React, { useState, useEffect } from 'react';
import './BestLimitedEditions.scss';
import leImg1 from '../assets/images/limited_editions_img1.jpg';
import leImg2 from '../assets/images/limited_editions_img2.jpg';
import leImg3 from '../assets/images/limited_editions_img3.jpg';
import rotate from '../assets/images/rotate.png';
import { NavLink, Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector, useDispatch } from 'react-redux';
import { getBestLimitedEditionList } from '../pages/redux/action/nftAction';


const BestLimitedEditions = () => {
    const dispatch = useDispatch()
    const { bestLimitedList } = useSelector((state) => state.nftItemDetail)

    AOS.init({
        once: false,
        duration: 1200,
    });

    useEffect(() => {
        dispatch(getBestLimitedEditionList());
    }, [getBestLimitedEditionList])

    return (
        <>
            <div className='pt-large pb-large'>
                <div className='container'>
                    <div className='mainHd mb-3 mb-md-4 text-center' data-aos="fade-up">Best Limited Editions </div>
                    <ul className='leList d-flex flex-wrap p-0' data-aos="zoom-in-up">


                        {
                            (bestLimitedList.data && bestLimitedList.data.length > 0) ?
                                bestLimitedList.data.map((data, i) => (

                                    <li>
                                        <div className='inner'>
                                            <NavLink to="" >   <div className='img'><a><img alt="" src={data.itemProfile} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: {data.nftItemCount}</span></div></NavLink>
                                            <div className='det p-3 d-flex justify-content-between'>
                                                <div>
                                                    <div className='name mb-2'>{data.itemName}</div>
                                                    <div className='timer'>12h : 13m : 12s</div>
                                                </div>
                                                <div className='text-end'>
                                                    <div className='hb mb-2'>Highest Bid</div>
                                                    <div className='price'>{data.amount}15.85 HBAR</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>


                                )) : ""


                        }






                        {/* <li>
                            <div className='inner'>
                            <NavLink to="" >   <div className='img'><a><img alt="" src={leImg2} /></a> <span className='item'>Items: 5</span></div></NavLink>
                                <div className='det p-3 d-flex justify-content-between'>
                                    <div>
                                        <div className='name mb-2'>Limited Edition Painting</div>
                                        <div className='timer'>12h : 13m : 12s</div>
                                    </div>
                                    <div className='text-end'>
                                        <div className='hb mb-2'>Highest Bid</div>
                                        <div className='price'>15.85 HBAR</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='inner'>
                            <NavLink to="" >    <div className='img'><a><img alt="" src={leImg3} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div></NavLink>
                                <div className='det p-3 d-flex justify-content-between'>
                                    <div>
                                        <div className='name mb-2'>Bovet Amadeo Fleurier</div>
                                        <div className='timer'>12h : 13m : 12s</div>
                                    </div>
                                    <div className='text-end'>
                                        <div className='hb mb-2'>Highest Bid</div>
                                        <div className='price'>15.85 HBAR</div>
                                    </div>
                                </div>
                            </div>
                        </li> */}
                    </ul>
                </div>
            </div>
        </>

    )

}
export default BestLimitedEditions;