import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import './Admin.scss';
import AdminFooter from '../../component/AdminFooter'
import logo from '../../assets/images/logo.png'
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { adminFogotPassword } from '../redux/action/adminAction';


const initialState = {
    emailPhone: '',
    emailPhoneError: '',
    errorMsg: ''
}

const ForgotPassword = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [iState, updateState] = useState(initialState)
    const {
        emailPhone,
        emailPhoneError,
        errorMsg
    } = iState
   

    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState, [name]: value

        })
    }

    let handleValidation = () => {
        let emailPhoneError = '';
        let formIsValid = true;

        if (!emailPhone) {
            emailPhoneError = 'Please enter email ID or mobile number.';
            formIsValid = false
        }
        updateState({
            ...iState, emailPhoneError, errorMsg
        });
        return formIsValid

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = handleValidation();
        if (formIsValid) {
            let data;
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(emailPhone)) {
                data = { emailPhone, type: 'email' }
            }
            else {
                data = { emailPhone, type: 'phone' }
            }
            dispatch(adminFogotPassword(data)).then(res => {


                if (res.payload.code == 200) {
                    localStorage.setItem('emailM',data.emailPhone);
                    localStorage.setItem('types', data.type);
                    toast.success(res.payload.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    navigate('/verification')
                } else {
                    toast.error(res.payload.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    updateState({
                        ...iState, errorMsg: res.message,
                        errors: ''
                    })
                }
            })
        }
    }


    return (
        <>

            <div className='adminTop d-flex align-items-center justify-content-center'>
                <div className='w-100'>
                    <div className='mb-4 mb-md-5 text-center'><img alt="" src={logo} /></div>
                    <div className='loginForm'>
                        <div className='inner'>
                            <div className='loginHd text-center mb-3'>Forgot Password</div>
                            <div className='hdText text-center mb-4'>Enter the email address or phone number associated with your account and we'll send you a verification code to reset your password.</div>
                            <ul className='form p-0'>
                                <li>
                                    <input type="text" className='formInput' placeholder='Email/Phone*' name='emailPhone' value={emailPhone} onChange={handleChange} />
                                    <span style={{ color: "red" }}>{emailPhoneError}</span>
                                </li>
                                <li><NavLink><Button type="button" className='full' onClick={handleSubmit}>Continue</Button></NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className='text-center pt-4'><NavLink to="/login" className='back'>Back</NavLink></div>
                </div>
            </div>
            <AdminFooter />
        </>

    )

}
export default ForgotPassword;