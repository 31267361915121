import axios from 'axios';
import config from '../config/config';

const url = `${config.Url}user/`

export const createCollection = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log('frmData----->',frmData)
			const res = await axios.post(`${url}createCollection`, frmData, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getAllCollection = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			let data = frmData ? frmData : ""
			const result = await axios.get(`${url}getCollectionList?tab=${data}`, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(result);
			console.log('result-------->', result.data)
		} catch (error) {
			reject(error);
		}
	});
};

export const getUserCollection = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			let data = frmData ? frmData : ""
			const result = await axios.get(`${url}getUserCollectionList?tab=${data}`, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(result);
			console.log('result-------->', result.data)
		} catch (error) {
			reject(error);
		}
	});
};


export const fetchColectionDetail = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.get(`${url}getCollectionDetail?collectionId=${frmData}`);
			resolve(res.data);
		} catch (error) {
			console.log(error);
			reject(error.message);
		}
	});
};


export const createAuthRequest = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(`${url}createRequestAuthAnswer`, frmData, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getTokenAddress = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await axios.get(`${url}getTokenAddress?collectionId=${frmData}`, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});
			resolve(result);
		} catch (error) {
			reject(error);
		}
	});
};

export const getAllTopCollection = (frmData) => {
	return new Promise(async (resolve, reject) => {
		try {
			let data = frmData ? frmData : ""
			const result = await axios.get(`${url}getTopCollectionList?day=${data}`, {
				headers: {
					token: localStorage.getItem('accessJWT'),
				},
			});

			resolve(result);
			console.log('result-------->', result.data)
		} catch (error) {
			reject(error);
		}
	});
};