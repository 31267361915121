import React, { } from 'react';
import { NavLink, Link } from "react-router-dom";

const AdminFooter = () => {



    return (
        <>
            <footer className='AdminFooter text-center'>
                <div className='container d-flex justify-content-center align-items-center'>&copy; 2022 Ledex Protocol. All Rights Reserved. Designed & Developed By Flexsin</div>
            </footer>
        </>

    )

}
export default AdminFooter;