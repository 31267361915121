import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import moment from 'moment';
import './Admin.scss';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import TotalUser from '../../assets/images/total_user.png';
import Nft from '../../assets/images/total_nft.png';
import Shipped from '../../assets/images/nft-shipped.png';
import Collection from '../../assets/images/total_collection.png';
import StraightIcon from '@mui/icons-material/Straight';
import User from '../../assets/images/user_img.png';
import ItemImg from '../../assets/images/item_img.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { getAdminDashboardDetail } from '../redux/action/adminAction';

const Dashboard = () => {
    const dispatch = useDispatch();
    const { dashboardData } = useSelector((state) => state.admin)
    useEffect(() => {
        let obj = { page: 1, search: '' }
        dispatch(getAdminDashboardDetail(obj));

    }, [getAdminDashboardDetail])

    const getTime = (createdAt) => {
        var date1 = new Date();
        let dateTime1 = moment(date1).format("DD-MM-YYYY HH:mm");
        let dateTime2 = moment(createdAt).format("DD-MM-YYYY HH:mm");
        var m1 = moment(`${dateTime2}`, 'DD-MM-YYYY HH:mm');
        var m2 = moment(`${dateTime1}`, 'DD-MM-YYYY HH:mm');
        var m3 = m2.diff(m1, 'minutes');
        var m4 = m2.diff(m1, 'h');
        var numdays = Math.floor(m3 / 1440);
        var numhours = Math.floor((m3 % 1440) / 60);
        var numminutes = Math.floor((m3 % 1440) % 60);
        let timer = `${numdays} Days ${numhours} Hours ${numminutes}`
        return timer
    }

    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <ul className='d-flex flex-wrap p-0 dbTop'>
                            <li className='col-12 col-sm-6 col-xl-3'>
                                <div className='inner d-flex'>
                                    <div className='img me-3 pt-1'><img src={TotalUser} alt="" /></div>
                                    <div className='det'>
                                        <div className='hd mb-3'>{dashboardData.data && dashboardData.data.userCount}<span className='d-block mt-1'>Users</span></div>
                                        <div className='value d-flex align-items-center'><span className='up d-flex align-items-center ps-1 pe-1 me-2'><StraightIcon /> +{dashboardData.data && dashboardData.data.userPer.length > 0 ? dashboardData.data.userPer[0].percentage.toFixed(3) : 0}%</span> Last 15 days</div>
                                    </div>
                                </div>
                            </li>
                            <li className='col-12 col-sm-6 col-xl-3'>
                                <div className='inner d-flex'>
                                    <div className='img me-3 pt-1'><img src={Nft} alt="" /></div>
                                    <div className='det'>
                                        <div className='hd mb-3'>{dashboardData.data && dashboardData.data.nftCount} <span className='d-block mt-1'>Total Nft</span></div>
                                        <div className='value d-flex align-items-center'><span className='up d-flex align-items-center ps-1 pe-1 me-2'><StraightIcon /> +{dashboardData.data && dashboardData.data.nftPer.length > 0 ? dashboardData.data.nftPer[0].percentage.toFixed(3) : 0}%</span> Last 15 days</div>
                                    </div>
                                </div>
                            </li>
                            <li className='col-12 col-sm-6 col-xl-3'>
                                <div className='inner d-flex'>
                                    <div className='img me-3 pt-1'><img src={Shipped} alt="" /></div>
                                    <div className='det'>
                                        <div className='hd mb-3'>0 <span className='d-block mt-1'>Nft Shipped</span></div>
                                        <div className='value d-flex align-items-center'><span className='down d-flex align-items-center ps-1 pe-1 me-2'><StraightIcon /> +148%</span> Last 15 days</div>
                                    </div>
                                </div>
                            </li>
                            <li className='col-12 col-sm-6 col-xl-3'>
                                <div className='inner d-flex'>
                                    <div className='img me-3 pt-1'><img src={Collection} alt="" /></div>
                                    <div className='det'>
                                        <div className='hd mb-3'>{dashboardData.data && dashboardData.data.collectionCount} <span className='d-block mt-1'>Collections</span></div>
                                        <div className='value d-flex align-items-center'><span className='up d-flex align-items-center ps-1 pe-1 me-2'><StraightIcon /> +{dashboardData.data && dashboardData.data.collectionPer.length > 0 ? dashboardData.data.collectionPer[0].percentage.toFixed(3) : 0}%</span> Last 15 days</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className='d-flex flex-wrap dbBot'>
                            <div className='col-12 col-xl-6 recentActivity'>
                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                    <div className='dbHd'>Recent Activity</div>
                                    <Link to="/recent-active" className='viewAll'>View All</Link>
                                </div>
                                <div className='inner'>
                                    <ul className='dbList p-0'>
                                        {
                                            dashboardData.data && dashboardData.data.userlist.length > 0 ? dashboardData.data.userlist.map((item, i) =>
                                                < li className='d-flex align-items-center justify-content-between'>
                                                    <div className='det d-flex align-items-center'>
                                                        <div className='img'><img className='rounded-circle' src={item.profileImg} alt="" /></div>
                                                        <div className='info ms-3'>{item.userName}<span className='d-block pt-1'>posted new NFT</span></div>
                                                    </div>
                                                    <div className='time ms-2'>

                                                        {getTime(item.createdAt)}  min ago
                                                    </div>

                                                </li>

                                            ) : ""


                                        }
                                    </ul>

                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-xl-3 topCreators'>
                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                    <div className='dbHd'>Top Creators</div>
                                    <Link to="/top-creator" className='viewAll'>View All</Link>
                                </div>
                                <div className='inner'>
                                    <ul className='dbList p-0'>


                                        {
                                            dashboardData.data && dashboardData.data.topNftCreator.length > 0 ? dashboardData.data.topNftCreator.map((item, i) =>
                                                <li>
                                                    <div className='det d-flex align-items-center'>
                                                        <div className='img'><img className='rounded-circle' src={item.profileImg} alt="" /></div>
                                                        <div className='info ms-3'>{item.name} <span className='d-block pt-1'>{item.totalNft} items</span></div>
                                                    </div>
                                                </li>
                                            ) : ""


                                        }



                                    </ul>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-xl-3 mostBids'>
                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                    <div className='dbHd'>Most Bids Items</div>
                                    <Link to="/most-biditem" className='viewAll'>View All</Link>
                                </div>
                                <div className='inner'>
                                    <ul className='dbList p-0'>



                                        {
                                            dashboardData.data && dashboardData.data.mostBidItem.length > 0 ? dashboardData.data.mostBidItem.map((item, i) =>
                                                <li className='d-flex align-items-center justify-content-between'>
                                                    <div className='det d-flex align-items-center'>
                                                        <div className='img'><img className='rounded-circle' src={item.nftImg} alt="" /></div>
                                                        <div className='info ms-3'>{item.name} #{item.nftId} <span className='d-block pt-1'>{item.totalBiding} bids</span></div>
                                                    </div>
                                                </li>
                                            ) : ""


                                        }




                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default Dashboard;