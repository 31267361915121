import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from "react-router-dom";
import './Admin.scss';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import Pagination from 'react-js-pagination';
import CollectibleNFT from '../../assets/images/collectible_nft_dark.png';
import { useSelector, useDispatch } from 'react-redux';
import { getCollectibleNftList, getAdminNftDetail } from '../redux/action/adminNftAction';


const CollectibleNft = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [serach, setSearch] = useState("")
    const { collectibleNftLists } = useSelector((state) => state.adminNftDetail)

    useEffect(() => {
        let obj = { page: currentPage, search: serach }
        dispatch(getCollectibleNftList(obj));
    }, [currentPage])

    const collectibleNftDetail = (id) => {
        dispatch(getAdminNftDetail(id))
        localStorage.setItem('nftItemId', JSON.stringify(id));
        navigate(`/collectible-nft/detail`)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setSearch(value)
        let obj={page:currentPage,search:value}
        dispatch(getCollectibleNftList(value))
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className='d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center'>
                            <div className="dbMainHd d-flex align-items-center mb-3"><span className='d-flex align-items-center justify-content-center rounded-1 me-3'><img alt='' src={CollectibleNFT} /></span> Collectible NFT</div>
                            <div className='d-flex mb-3'>
                                <div className='search me-2'>
                                    <input type='text' className='input' name='search' value={serach} onChange={handleChange} placeholder='Search...' />
                                    <SearchIcon className='searchIcon' />
                                </div>
                                <div><a className='filter d-flex justify-content-center align-items-center'><FilterAltIcon /></a></div>
                            </div>
                        </div>

                        <div className='dbTable rounded-3'>
                            <table>
                                <tr>
                                    <th>Reference</th>
                                    <th>NFT ID</th>
                                    <th>NFT Name</th>
                                    <th>Owner</th>
                                    <th>Buyer</th>
                                    <th>Price</th>
                                    <th>Address</th>
                                    <th>Expected Delivery</th>
                                    <th>Delivery Status</th>
                                </tr>

                                {
                                    (collectibleNftLists.data && collectibleNftLists.data.collectibleNft.length > 0) ?
                                        collectibleNftLists.data.collectibleNft.map((data, i) => (

                                            <tr className='cursor-pointer' onClick={() => collectibleNftDetail(data._id)} >
                                                <td>#{data.nftId}</td>
                                                <td>{data.nftItemCount}</td>
                                                <td>{data.itemName}</td>
                                                <td>{data.userName}</td>
                                                <td>Buyer Name Here</td>
                                                <td>{data.amount ? data.amount : 0} HBAR</td>
                                                <td>133 Ramblewood Ave. Olney, MD 20832</td>
                                                <td>8/8/2022</td>
                                                <td><span className='status d-inline-block delivered'>Delivered</span></td>
                                            </tr>

                                        )) : ""


                                }

                            </table>
                        </div>
                        <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={collectibleNftLists.data && collectibleNftLists.data.totalRecord}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                </div>
            </div>
        </>

    )

}
export default CollectibleNft;