import { createBiding, getAllBidingList, getBidingDetail, claimStatus } from '../../../api/biding';
import {
    createBidPending, createBidSuccess, createBidError, createBidResetMessage,
    getBidingListPending, getBidingListSuccess, getBidingListError, getBidingListResetMessage,
    getBidingDetailPending, getBidingDetailSuccess, getBidingDetailError, getBidingDetailResetMessage,
    claimStatusPending, claimStatusSuccess, claimStatusError, claimStatusResetMessage
} from '../reducer/bidingReducer';




export const createBid = (frmDt) => async (dispatch) => {
    try {
        dispatch(createBidPending());
        const result = await createBiding(frmDt);
        if (result.code == 200) {
            return dispatch(createBidSuccess(result))
        } else {
            return dispatch(createBidError(result.message));
        }

    } catch (error) {
        dispatch(createBidResetMessage(error.message));
    }
};

export const getBidingList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getBidingListPending());
        const result = await getAllBidingList(frmDt);
        result.data.code === 200
            ? dispatch(getBidingListSuccess(result.data))
            : dispatch(getBidingListError(result.message));


    } catch (error) {
        dispatch(getBidingListResetMessage(error.message));
    }
};
export const getBidingDetals = (frmDt) => async (dispatch) => {
    try {
        dispatch(getBidingDetailPending());
        const result = await getBidingDetail(frmDt);
        result.data.code === 200
            ? dispatch(getBidingDetailSuccess(result.data))
            : dispatch(getBidingDetailError(result.message));
    } catch (error) {
        dispatch(getBidingDetailResetMessage(error.message));
    }
};


export const claimStatusUpdate = (frmDt) => async (dispatch) => {
    try {
        dispatch(claimStatusPending());

        const result = await claimStatus(frmDt);
        if (result.code == 200) {
            return dispatch(claimStatusSuccess(result))
        } else {
            return dispatch(claimStatusError(result.message));
        }

    } catch (error) {
        dispatch(claimStatusResetMessage(error.message));
    }
};
