import {
    getAllUser, fetchUser, getAllCollection, updateAuthentication,
    updateUserStatus, getCollectionDetail, createBestLimitedEdition, getAllBestLimitedEditionList
} from "../../../api/adminUserApi";
import {
    getUserListPending, getUserListSuccess, getUserListError, getUserListResetMessage,
    userDeatailPending, userDeatailSuccess, userDeatailError, userDeatailResetMessage,
    getCollectionListPending, getCollectionListSuccess, getCollectionListError, getCollectionListResetMessage,
    authenticationPending, authenticationSuccess, authenticationError, authenticationResetMessage,
    userStatusPending, userStatusSuccess, userStatusError, userStatusResetMessage,
    collectionDeatailPending, collectionDeatailSuccess, collectionDeatailError, collectionDeatailResetMessage, createBestEditionPending, createBestEditionSuccess, createBestEditionError,
    createBestEditionResetMessage, getBestEditionListPending, getBestEditionListSuccess, getBestEditionListError,
    getBestEditionListResetMessage
} from "../reducer/adminUserReducer";

export const getUserList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getUserListPending());
        const result = await getAllUser(frmDt);
        result.data.code === 200
            ? dispatch(getUserListSuccess(result.data))
            : dispatch(getUserListError(result.message));


    } catch (error) {
        dispatch(getUserListResetMessage(error.message));
    }
};

export const getUserDetail = (frmDt) => async (dispatch) => {
    try {
        dispatch(userDeatailPending());
        const result = await fetchUser();
        result.code === 200
            ? dispatch(userDeatailSuccess(result))
            : dispatch(userDeatailError(result.message));


    } catch (error) {
        dispatch(userDeatailResetMessage(error.message));
    }
};

export const getCollectionList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getCollectionListPending());
        const result = await getAllCollection(frmDt);
        result.data.code === 200
            ? dispatch(getCollectionListSuccess(result.data))
            : dispatch(getCollectionListError(result.message));


    } catch (error) {
        dispatch(getCollectionListResetMessage(error.message));
    }
};

export const collectionDetail = (frmDt) => async (dispatch) => {
    try {
        dispatch(collectionDeatailPending());
        const result = await getCollectionDetail(frmDt);
        result.data.code === 200
            ? dispatch(collectionDeatailSuccess(result))
            : dispatch(collectionDeatailError(result.message));


    } catch (error) {
        dispatch(collectionDeatailResetMessage(error.message));
    }
};

export const updateAuth = (frmDt) => async (dispatch) => {
    try {
        dispatch(authenticationPending());
        const result = await updateAuthentication(frmDt);
        if (result.data.code === 200) {
            return dispatch(authenticationSuccess(result.data))
        } else {
            dispatch(authenticationError(result.message));
        }

    } catch (error) {
        dispatch(authenticationResetMessage(error.message));
    }
};

export const updateStatus = (frmDt) => async (dispatch) => {
    try {
        dispatch(userStatusPending());
        const result = await updateUserStatus(frmDt);
        if (result.data.code === 200) {
            return dispatch(userStatusSuccess(result.data))
        }
        else {
            return dispatch(userStatusError(result.message));
        }


    } catch (error) {
        dispatch(userStatusResetMessage(error.message));
    }
};

export const createBestEdition = (frmDt) => async (dispatch) => {
    try {
        dispatch(createBestEditionPending());

        const result = await createBestLimitedEdition(frmDt);
        if (result.code == 200) {
            return dispatch(createBestEditionSuccess(result))
        } else {
            return dispatch(createBestEditionError(result.message));
        }

    } catch (error) {
        dispatch(createBestEditionResetMessage(error.message));
    }
};

export const getBestLimitedEditionList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getBestEditionListPending());
        const result = await getAllBestLimitedEditionList(frmDt);
        result.data.code === 200
            ? dispatch(getBestEditionListSuccess(result.data))
            : dispatch(getBestEditionListError(result.message));
    } catch (error) {
        dispatch(getBestEditionListResetMessage(error.message));
    }
};
