import { saleNft, getAllSaleNftList } from '../../../api/saleNftApi';
import {
    saleNftPending, saleNftSuccess, saleNftError, saleNftResetMessage,
    getSaleNftListPending, getSaleNftListSuccess, getSaleNftListError, getSaleNftListResetMessage
} from '../reducer/saleNftReducer';

export const saleNftItem = (frmDt) => async (dispatch) => {
    try {
        dispatch(saleNftPending());
        const result = await saleNft(frmDt);
        if (result.code == 200) {
            return dispatch(saleNftSuccess(result))
        } else {
            return dispatch(saleNftError(result.message));
        }

    } catch (error) {
        dispatch(saleNftResetMessage(error.message));
    }
};

export const getSaleNftList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getSaleNftListPending());
        const result = await getAllSaleNftList(frmDt);
        result.data.code === 200
            ? dispatch(getSaleNftListSuccess(result.data))
            : dispatch(getSaleNftListError(result.message));


    } catch (error) {
        dispatch(getSaleNftListResetMessage(error.message));
    }
};

