import React, { } from 'react';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';

const Partners = () => {
    return (
        <>
            <div className="mainCon">
                <Header />

                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='mainHd mb-3'>OUR PARTNERS</div>
                    </div>
                </div>
                <a href='https://www.instagram.com/3dmini.lab?igsh=MTQwYnRvdWt3YjZoNg==' target='_blank' rel='noopener noreferrer'>
                <div className='container' style={{ padding: '0 30px' }}>
                    <ul className='d-flex flex-wrap p-0 m-0 facList'>
                        <li className='col-12 col-sm-6'>
                            <span className='count'>1</span>
                            3D MINILABS - Laboratory of prototyping and 3D printing
                        </li>
                    </ul>
                    </div>
                </a>
                <br></br>
                <a href='#' target='_blank' rel='noopener noreferrer'>
                <div className='container' style={{ padding: '0 30px' }}>
                    <ul className='d-flex flex-wrap p-0 m-0 facList'>
                        <li className='col-12 col-sm-6'>
                            <span className='count'>2</span>
                            Pulsify Consultancy
                        </li>
                    </ul>
                    </div>
                </a>
                <br></br>
                <a href='https://game-sphere.io/' target='_blank' rel='noopener noreferrer'>
                <div className='container' style={{ padding: '0 30px' }}>
                <ul className='d-flex flex-wrap p-0 m-0 facList'>
                    <li className='col-12 col-sm-6 col-lg-3'>
                        <span className='count'>3</span>
                        Gamesphere
                    </li>
                </ul>
                </div>
                </a>
                <br></br>
                <a href='https://momentum-dubai.com/' target='_blank' rel='noopener noreferrer'>
                <div className='container' style={{ padding: '0 30px' }}>
                <ul className='d-flex flex-wrap p-0 m-0 facList'>
                    <li className='col-12 col-sm-6 col-lg-3'>
                        <span className='count'>4</span>
                        Momentum Dubai
                    </li>
                </ul>
                </div>
                </a>
                <br></br>
                <a href='https://gmtwatches.ae/' target='_blank' rel='noopener noreferrer'>
                <div className='container' style={{ padding: '0 30px' }}>
                <ul className='d-flex flex-wrap p-0 m-0 facList'>
                    <li className='col-12 col-sm-6 col-lg-3'>
                        <span className='count'>5</span>
                        GMTwatches & Jewellery dubai 
                    </li>
                </ul>
                </div>
                </a>
            </div>

            <Footer />
        </>
    );
}

export default Partners;