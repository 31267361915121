import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Admin.scss';
import { ToastContainer, toast } from 'react-toastify';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import Setting from '../../assets/images/settings_dark.png';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from 'react-redux';
import { changeAdminPassword } from '../redux/action/adminAction'
const initialState = {
    password: '',
    newPassword: '',
    cpassword: '',
    updatedData: {},
    errors: {},
}

const Settings = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoding, status, message } = useSelector((state) => state.admin)
    const [iState, updateState] = useState(initialState)

    const {
        password,
        newPassword,
        cpassword,
        customUrl,
        updatedData,
        errors,
    } = iState

    const handleInputChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState, [name]: value,
            updatedData: {
                ...iState.updatedData, [name]: value
            }
        })
    }

    const handleValidation = () => {
        let passwordEmpty = ''
        let newPasswordEmpty = ''
        let cpasswordEmpty = ''
        let formIsValid = true;

        if (!password.trim()) {
            passwordEmpty = "Please insert password.";
            formIsValid = false;
        }
        if (!newPassword.trim()) {
            newPasswordEmpty = "Please insert new password.";
            formIsValid = false;
        }
        if (!cpassword.trim()) {
            cpasswordEmpty = "Please insert confirm password.";
            formIsValid = false;
        }
        if (newPassword && cpassword && (newPassword !== cpassword)) {
            cpasswordEmpty = "Confirm password must be same as new password.";
            formIsValid = false;
        }
        updateState({
            ...iState, errors: { passwordEmpty, newPasswordEmpty, cpasswordEmpty }

        })
        return formIsValid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = handleValidation();
        if (formIsValid) {
            const data = { password, newPassword }
            dispatch(changeAdminPassword(data)).then(res => {
              
                if (res.payload.code == 200) {
                    toast.success(res.payload.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    navigate('/dashboard')
                } else {
                    toast.error(res.payload.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    updateState({
                        ...iState, errorMsg: res.message,
                        errors: ''
                    })
                }
            })
        }
    }

    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className="dbMainHd d-flex align-items-center mb-3"><span className='d-flex align-items-center justify-content-center rounded-1 me-3'><img alt='' src={Setting} /></span> Settings</div>
                        <div className='settingForm p-3 p-md-4 mb-4 rounded-3'>
                            <ul className='form p-0'>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Current Password*</label>
                                    <input type="password" className='formInput' name="password" value={password} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.passwordEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>New Password*</label>
                                    <input type="password" className='formInput' name="newPassword" value={newPassword} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.newPasswordEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Confirm Password*</label>
                                    <input type="password" className='formInput' name="cpassword" value={cpassword} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.cpasswordEmpty}</span>
                                </li>
                            </ul>
                        </div>
                        <div><Button type="button" onClick={handleSubmit}>Save</Button></div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default Settings;