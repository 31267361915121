import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import './Admin.scss';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash'
import { getAdminDetail, updateAdminDetail } from '../redux/action/adminAction';

import Button from 'react-bootstrap/Button';

const initialState = {
    userName: '',
    twitterUsername: '',
    email: '',
    customUrl: '',
    walletAddress: '',
    imageReview: '',
    bio: '',
    profileImg: '',
    updatedData: {},
    editEnable: false,
    errors: {},
}

const Editadminprofile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoding, status, message, profileData } = useSelector((state) => state.admin)
    const [iState, updateState] = useState(initialState)

    const {
        userName,
        twitterUsername,
        email,
        customUrl,
        walletAddress,
        imageReview,
        bio,
        updatedData,
        profileImg,
        errors,
    } = iState

    useEffect(() => {
        dispatch(getAdminDetail());

    }, [getAdminDetail])

    useEffect(() => {
        if (!_.isEmpty(profileData)) {
            const updateData = _.cloneDeep(iState)
            updateData.userName = profileData.data.userName
            updateData.email = profileData.data.email
            updateData.imageReview = profileData.data.profileImg
            updateState(
                updateData
            )
        }

    }, [profileData])


    const handleInputChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState, [name]: value,
            updatedData: {
                ...iState.updatedData, [name]: value
            }
        })
    }


    const onFileHandler = (e) => {
        const file = e.target.files[0]

        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                previewsFile(file)

            } else {
                toast.error('Only formats are allowed: jpeg, jpg, png files', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }


    const previewsFile = (file) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => {
            updateState({
                ...iState, imageReview: reader.result, profileImg: file
            })
        }, false)
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const handleValidation = () => {
        let userNameEmpty = ''
        let formIsValid = true;

        if (!userName.trim()) {
            userNameEmpty = "Please insert name.";
            formIsValid = false;
        }
        updateState({
            ...iState, errors: { userNameEmpty }

        })
        return formIsValid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let formIsValid = handleValidation();
        if (formIsValid) {
            const data = { userName, profileImg }
            dispatch(updateAdminDetail(data)).then(res => {
                if (res.payload.code == 200) {
                    dispatch(getAdminDetail())
                    toast.success(res.payload.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    dispatch(getAdminDetail())
                } else {
                    updateState({
                        ...iState, errorMsg: res.message,
                        errors: ''
                    })
                }
            })
        }
    }

    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className="dbMainHd d-flex align-items-center mb-3"> Edit Profile</div>
                        <div className='settingForm p-3 p-md-4 mb-4 d-flex justify-content-between'>
                            <ul className='form p-0 leftForm'>

                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Name</label>
                                    <input type="test" className='formInput' name="userName" value={userName} onChange={handleInputChange} />
                                    <span style={{ color: "red" }}>{errors.userNameEmpty}</span>
                                </li>
                                <li>
                                    <label className='fieldHd d-block pb-2 ps-1'>Email</label>
                                    <input type="email" className='formInput' email='email' disabled value={email} />
                                </li>
                            </ul>
                            <ul className='form p-0 rightForm'>
                                <li>
                                    <li>
                                        <label className='fieldHd d-block pb-2 ps-1'>Profile Image</label>
                                        <div className='imgBox' style={{ width: "160px", height: "160px", borderRadius: "10px", }}><img src={imageReview} /></div>
                                        <div className='note pt-2'>We recommend an image of at least 300x300. Gifts work too. Max 5mb.</div>
                                        <div className='pt-3'><Button type="button" className='chhoseFile'>Choose File
                                            <input type='file' className='file' id="imageUpload"
                                                onChange={onFileHandler}
                                                onClick={e => e.target.value == null}
                                                accept=".png, .jpg, .jpeg" />
                                        </Button></div>
                                    </li>


                                </li>
                            </ul>
                        </div>
                        <div><Button type="button" onClick={handleSubmit}>Update Profile</Button></div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default Editadminprofile;