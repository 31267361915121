import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    status: '',
    message: '',
    userData: {},
    collectionDatas: {},
    userList: [],
    collectionList: [],
    bestEditionList:[]

};

const adminUserDetail = createSlice({
    name: 'adminUserDetail',
    initialState,
    reducers: {
        getUserListPending: (state) => {
            state.isLoading = true;
        },
        getUserListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.userList = payload
        },
        getUserListError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getUserListResetMessage: (state) => {
            state.message = '';
        },

        userDeatailPending: (state) => {
            state.isLoading = true;
        },
        userDeatailSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.userData = payload
        },
        userDeatailError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        userDeatailResetMessage: (state) => {
            state.message = '';
        },

        getCollectionListPending: (state) => {
            state.isLoading = true;
        },
        getCollectionListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.collectionList = payload
        },
        getCollectionListError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getCollectionListResetMessage: (state) => {
            state.message = '';
        },

        authenticationPending: (state) => {
            state.isLoading = true;
        },
        authenticationSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload
        },
        authenticationError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        authenticationResetMessage: (state) => {
            state.message = '';
        },

        userStatusPending: (state) => {
            state.isLoading = true;
        },
        userStatusSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload
        },
        userStatusError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        userStatusResetMessage: (state) => {
            state.message = '';
        },


        collectionDeatailPending: (state) => {
            state.isLoading = true;
        },
        collectionDeatailSuccess: (state, { payload }) => {
            console.log('payload------->', payload)
            state.isLoading = false;
            state.status = 'success';
            state.collectionDatas = payload
        },
        collectionDeatailError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        collectionDeatailResetMessage: (state) => {
            state.message = '';
        },

        createBestEditionPending: (state) => {
            state.isLoading = true;
        },
        createBestEditionSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload
        },
        createBestEditionError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        createBestEditionResetMessage: (state) => {
            state.message = '';
        },

        getBestEditionListPending: (state) => {
            state.isLoading = true;
        },
        getBestEditionListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.bestEditionList = payload
        },
        getBestEditionListError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getBestEditionListResetMessage: (state) => {
            state.message = '';
        },



    },
});

const { reducer, actions } = adminUserDetail;
export const { getUserListPending, getUserListSuccess, getUserListError, getUserListResetMessage,
    userDeatailPending, userDeatailSuccess, userDeatailError, userDeatailResetMessage, getCollectionListPending,
    getCollectionListSuccess, getCollectionListError, getCollectionListResetMessage, authenticationPending,
    authenticationSuccess, authenticationError, authenticationResetMessage, userStatusPending, userStatusSuccess, userStatusError, userStatusResetMessage,
    collectionDeatailPending, collectionDeatailSuccess, collectionDeatailError, collectionDeatailResetMessage, createBestEditionPending, createBestEditionSuccess, createBestEditionError,
    createBestEditionResetMessage,getBestEditionListPending,getBestEditionListSuccess,getBestEditionListError,
    getBestEditionListResetMessage
} = actions;

export default reducer;