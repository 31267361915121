import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    status: '',
    message: '',
    watchList: []
};

const watchDetail = createSlice({
    name: 'watchListDetail',
    initialState,
    reducers: {
        createWatchlistPending: (state) => {
            state.isLoading = true;
        },
        createWatchlistSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload
        },
        createWatchlistError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        createWatchlistResetMessage: (state) => {
            state.message = '';
        },
        getWatchListPending: (state) => {
            state.isLoading = true;
        },
        getWatchListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.watchList = payload
        },
        getWatchListError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getWatchListResetMessage: (state) => {
            state.message = '';
        },
    },
});

const { reducer, actions } = watchDetail;
export const { getWatchListPending, getWatchListSuccess, getWatchListError,
    getWatchListResetMessage,createWatchlistPending,createWatchlistSuccess,
    createWatchlistError,createWatchlistResetMessage

} = actions;

export default reducer;