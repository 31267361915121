import React, { useState, useEffect } from 'react';
import './Admin.scss';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import NftIcon from '../../assets/images/nft_icon_dark.png';
import Pagination from 'react-js-pagination';
import { useSelector, useDispatch } from 'react-redux';
import { getAdminDashboardDetail } from '../redux/action/adminAction';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const Mostbiditem = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [serach, setSearch] = useState("")

    const { dashboardData } = useSelector((state) => state.admin)
    
    useEffect(() => {
        let obj = { page: currentPage, search: serach }
        dispatch(getAdminDashboardDetail(obj));

    }, [currentPage])


    const handleChange = (e) => {
        const { name, value } = e.target
        setSearch(value)
        let obj = { page: currentPage, search: value }
        dispatch(getAdminDashboardDetail(obj))
    }


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className='d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center'>
                            <div className="dbMainHd d-flex align-items-center mb-3"><span className='d-flex align-items-center justify-content-center rounded-1 me-3'><img alt='' src={NftIcon} /></span> Most Bit Item List</div>
                            <div className='d-flex mb-3'>
                                <div className='search me-2'>
                                    <input type='text' className='input' name='search' value={serach} onChange={handleChange} placeholder='Search...' />
                                    <SearchIcon className='searchIcon' />
                                </div>
                                <div><a className='filter d-flex justify-content-center align-items-center'><FilterAltIcon /></a></div>
                            </div>
                        </div>

                        <div className='dbTable rounded-3'>
                            <table>
                                <tr>
                                    <th>Image</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Total Bid</th>

                                </tr>





                                {
                                    (dashboardData.data && dashboardData.data.mostBidItem.length > 0) ?
                                        dashboardData.data.mostBidItem.map((item, i) => (

                                            <tr className='cursor-pointer'  >
                                                <td ><img src={item.nftImg} alt='' className='itemImg' /></td>
                                                <td >{`#${item.nftId}`}</td>
                                                <td>{item.name}</td>
                                                <td >{item.totalBiding}</td>




                                            </tr>

                                        )) : ""


                                }


                            </table>
                        </div>
                        <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={dashboardData.data && dashboardData.data.totalBidRecord}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                </div>
            </div>
        </>

    )

}
export default Mostbiditem;