import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	status: '',
	message: '',
	nftData: {},
	nftList: [],
	nftUserList: [],
	nftCollectionList: [],
	nftSellList:[],
	nftOwnerList:[],
	bestLimitedList:[]
};

const nftDetail = createSlice({
	name: 'nftItemDetail',
	initialState,
	reducers: {
		createNftItemPending: (state) => {
			state.isLoading = true;
		},
		createNftItemSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.message = payload
		},
		createNftItemError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		createNftItemResetMessage: (state) => {
			state.message = '';
		},
		getNftItemListPending: (state) => {
			state.isLoading = true;
		},
		getNftItemListSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.nftList = payload
		},
		getNftItemListError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		getNftItemListResetMessage: (state) => {
			state.message = '';
		},

		getUserNftItemListPending: (state) => {
			state.isLoading = true;
		},
		getUserNftItemListSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.nftUserList = payload
		},
		getUserNftItemListError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		getUserNftItemListResetMessage: (state) => {
			state.message = '';
		},

		getCollectionNftItemListPending: (state) => {
			state.isLoading = true;
		},
		getCollectionNftItemListSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.nftCollectionList = payload
		},
		getCollectionNftItemListError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		getCollectionNftItemListResetMessage: (state) => {
			state.message = '';
		},
		nftDeatailPending: (state) => {
			state.isLoading = true;
		},
		nftDeatailSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.nftData = payload
		},
		nftDeatailError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		nftDeatailResetMessage: (state) => {
			state.message = '';
		},


		uploadImagePending: (state) => {
			state.isLoading = true;
		},
		uploadImageSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.message = payload
		},
		uploadImageError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		uploadImageResetMessage: (state) => {
			state.message = '';
		},


		salePending: (state) => {
			state.isLoading = true;
		},
		saleSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.message = payload
		},
		saleError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		saleResetMessage: (state) => {
			state.message = '';
		},

		auctionPending: (state) => {
			state.isLoading = true;
		},
		auctionSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.message = payload
		},
		auctionError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		auctionResetMessage: (state) => {
			state.message = '';
		},

		getSellNftItemListPending: (state) => {
			state.isLoading = true;
		},
		getSellNftItemListSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.nftSellList = payload
		},
		getSellNftItemListError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		getSellNftItemListResetMessage: (state) => {
			state.message = '';
		},


		getOwnerNftItemListPending: (state) => {
			state.isLoading = true;
		},
		getOwnerNftItemListSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.nftOwnerList = payload
		},
		getOwnerNftItemListError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		getOwnerNftItemListResetMessage: (state) => {
			state.message = '';
		},


		bestLimitedPending: (state) => {
			state.isLoading = true;
		},
		bestLimitedSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.bestLimitedList = payload
		},
		bestLimitedError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		bestLimitedResetMessage: (state) => {
			state.message = '';
		},

		NFTApprovalPending: (state) => {
			state.isLoading = true;
		},
		NFTApprovalSuccess: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'success';
			state.message = payload
		},
		NFTApprovalError: (state, { payload }) => {
			state.isLoading = false;
			state.status = 'error';
			state.message = payload;
		},
		NFTApprovalResetMessage: (state) => {
			state.message = '';
		},



	},
});

const { reducer, actions } = nftDetail;
export const { createNftItemPending, createNftItemSuccess, createNftItemError,
	createNftItemResetMessage, getNftItemListPending, getNftItemListSuccess, getNftItemListError,
	getNftItemListResetMessage, nftDeatailPending, nftDeatailSuccess, nftDeatailError, nftDeatailResetMessage,
	getUserNftItemListPending, getUserNftItemListSuccess, getUserNftItemListError, getUserNftItemListResetMessage,
	getCollectionNftItemListPending, getCollectionNftItemListSuccess, getCollectionNftItemListError, getCollectionNftItemListResetMessage,
	uploadImagePending, uploadImageSuccess, uploadImageError, uploadImageResetMessage,
	salePending, saleSuccess, saleError, saleResetMessage,
	auctionPending,auctionSuccess,auctionError,auctionResetMessage,
	getSellNftItemListPending,getSellNftItemListSuccess,getSellNftItemListError,getSellNftItemListResetMessage,
	getOwnerNftItemListPending,getOwnerNftItemListSuccess,getOwnerNftItemListError,getOwnerNftItemListResetMessage,
	bestLimitedPending,bestLimitedSuccess,bestLimitedError,bestLimitedResetMessage,NFTApprovalPending,
	NFTApprovalSuccess,NFTApprovalError,NFTApprovalResetMessage
} = actions;

export default reducer;