import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { ToastContainer, toast } from 'react-toastify';
import './Admin.scss';
import Form from 'react-bootstrap/Form';
import AdminHeader from '../../component/AdminHeader'
import AdminMenu from '../../component/AdminMenu'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import Profile from '../../assets/images/profile_dark.png';
import Pagination from 'react-js-pagination';
import { useSelector, useDispatch } from 'react-redux';
import { getUserList, getUserDetail, updateStatus } from '../redux/action/adminUserAction';


const label = { inputProps: { 'aria-label': 'Switch demo' } };
const Users = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [serach, setSearch] = useState("")
    const { userList } = useSelector((state) => state.adminUserDetail)
    useEffect(() => {
        let obj = { page: currentPage, search: serach }
        dispatch(getUserList(obj));
    }, [currentPage])


    const userDetail = (id) => {
        dispatch(getUserDetail(id))
        localStorage.setItem('userId', JSON.stringify(id));
        navigate(`/users/detail`)
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setSearch(value)
        let obj = { page: currentPage, search: value }
        dispatch(getUserList(obj))
    }

    const handleAuthentication = (userId) => {
        dispatch(updateStatus(userId)).then(res => {
            if (res.payload.code == 200) {
                dispatch(getUserList());
                toast.success(res.payload.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(res.payload.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })

    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };



    return (
        <>
            <div>
                <AdminHeader />
                <div className='dbMid'>
                    <AdminMenu />
                    <div className='dbRight'>
                        <div className='d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center'>
                            <div className="dbMainHd d-flex align-items-center mb-3"><span className='d-flex align-items-center justify-content-center rounded-1 me-3'><img alt='' src={Profile} /></span> Users</div>
                            <div className='d-flex mb-3'>
                                <div className='search me-2'>
                                    <input type='text' className='input' name='search' value={serach} onChange={handleChange} placeholder='Search...' />
                                    <SearchIcon className='searchIcon' />
                                </div>
                                <div><a className='filter d-flex justify-content-center align-items-center'><FilterAltIcon /></a></div>
                            </div>
                        </div>

                        <div className='dbTable rounded-3'>
                            <table>
                                <tr>
                                    <th>Sr.No</th>
                                    <th>Name</th>
                                    <th>Wallet</th>
                                    <th>Wallet Address</th>
                                    <th>Total NFTs</th>
                                    <th>NFTs Sold</th>
                                    <th>Total Volume</th>
                                    <th>User Status</th>
                                    <th>Action</th>
                                </tr>



                                {
                                    (userList.data && userList.data.getUserlist.length > 0) ?
                                        userList.data.getUserlist.map((data, i) => (

                                            <tr className='cursor-pointer' >
                                                <td>{i + 1}</td>
                                                <td onClick={() => userDetail(data._id)}>{data.userName}</td>
                                                <td onClick={() => userDetail(data._id)}>Hashpack</td>
                                                <td>{data.walletAddress}</td>
                                                <td onClick={() => userDetail(data._id)}>{data.totalNft ? data.totalNft : 0}</td>
                                                <td>3,419</td>
                                                <td>{data.totalVolumes ? data.totalVolumes : 0}  HBAR</td>
                                                <td>{data.userStatus}</td>
                                                {/* <td> <Link onClick={() => handleAuthentication(data._id)}> <div className='ms-2 toggleBtn'><Form.Check type="switch" id="custom-switch2" label="" /></div></Link></td> */}
                                                <td> <div className='ms-2 toggleBtn' onClick={() => handleAuthentication(data._id)}> <Switch {...label} defaultChecked={data.userStatus == 'enable' ? true : false} /></div>

                                                </td>
                                            </tr>

                                        )) : ""


                                }


                            </table>
                        </div>
                        <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={userList.data && userList.data.totalRecord}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />

                    </div>
                </div>
            </div>
        </>

    )

}
export default Users;