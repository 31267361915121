import React, { } from 'react';
import './HelpCenter.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import bannerImg from '../assets/images/help-banner.jpg';
import SearchIcon from '@mui/icons-material/Search';
import GettingStarted from '../assets/images/getting-started.png';
import Buying from '../assets/images/buying.png';
import Selling from '../assets/images/selling.png';
import Faq from '../assets/images/faq.png';
import Creating from '../assets/images/creating.png';
import Partners from '../assets/images/partners.png';
import Developers from '../assets/images/developers.png';
import UserSafety from '../assets/images/user-safety.png';
const HelpCenter = () => {



    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='HelpCenterBanner' style={{ backgroundImage: 'url(' + bannerImg + ')', backgroundPosition: 'center' }}>
                    <div className='container'>
                        <div className='img'><img src={bannerImg} alt='' /></div>
                        <div className='inner text-center d-flex align-items-center justify-content-center'>
                            <div className='col-12'>
                                <div className='hd mb-4'>How Can We Help You With?</div>
                                <div className='helpSearch rounded-3 position-relative'>
                                    <input type='text' className='input' placeholder='Search...' />
                                    <SearchIcon className='searchIcon' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <ul className='p-0 d-flex flex-wrap helpList text-center'>
                            <li className='col-6 col-md-3'>
                                <a><span className='icon d-flex align-items-center justify-content-center'><img src={GettingStarted} alt='' /></span> Getting Started</a>
                            </li>
                            <li className='col-6 col-md-3'>
                                <a><span className='icon d-flex align-items-center justify-content-center'><img src={Buying} alt='' /></span> Buying</a>
                            </li>
                            <li className='col-6 col-md-3'>
                                <a><span className='icon d-flex align-items-center justify-content-center'><img src={Selling} alt='' /></span> Selling</a>
                            </li>
                            <li className='col-6 col-md-3'>
                                <a><span className='icon d-flex align-items-center justify-content-center'><img src={UserSafety} alt='' /></span> User Safety</a>
                            </li>
                            <li className='col-6 col-md-3'>
                                <a><span className='icon d-flex align-items-center justify-content-center'><img src={Faq} alt='' /></span> FAQs</a>
                            </li>
                            <li className='col-6 col-md-3'>
                                <a><span className='icon d-flex align-items-center justify-content-center'><img src={Creating} alt='' /></span> Creating</a>
                            </li>
                            <li className='col-6 col-md-3'>
                                <a><span className='icon d-flex align-items-center justify-content-center'><img src={Partners} alt='' /></span> Partners</a>
                            </li>
                            <li className='col-6 col-md-3'>
                                <a><span className='icon d-flex align-items-center justify-content-center'><img src={Developers} alt='' /></span> Developers</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='pb-4 pb-md-5'>
                    <div className='container'>
                        <div className='mainHd mb-3 text-center'>Promoted Articles</div>
                        <ul className='d-flex flex-wrap p-0 articleList'>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>What is a wallet?</a>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>How to Complete Identity Verification</a>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>Setting up your account & Life hacks with NFTs!</a>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>How do I report an NFT, user, or collection?</a>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>What is a wallet?</a>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>How to Complete Identity Verification</a>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>Setting up your account & Life hacks with NFTs!</a>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>How do I report an NFT, user, or collection?</a>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>What is a wallet?</a>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>How to Complete Identity Verification</a>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>Setting up your account & Life hacks with NFTs!</a>
                            </li>
                            <li className='col-12 col-sm-6 col-lg-4 col-xl-3'>
                                <a>How do I report an NFT, user, or collection?</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <Footer />
            </div>
        </>

    )

}
export default HelpCenter;