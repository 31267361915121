
import { getAllNft, getAllUserNftItem, fetchNftDetails, getAllCollectibleNft, updateNftStatus,getAllApprovalNft } from "../../../api/adminNftApi";
import {
    getNftListPending, getNftListSuccess, getNftListError, getNftListResetMessage,
    getUserNftItemListPending, getUserNftItemListSuccess, getUserNftItemListError,
    getUserNftItemListResetMessage, adminNftDetailPending, adminNftDetailSuccess,
    adminNftDetailError, adminNftDetailResetMessage, getCollectibleNftListPending, getCollectibleNftListSuccess, getCollectibleNftListError,
    getCollectibleNftListResetMessage, nftStatusPending, nftStatusSuccess, nftStatusError,
     nftStatusResetMessage,getNftAppurvalListPending,getNftAppurvalListSuccess,getNftListErAppurvalror,getNftAppurvalListResetMessage
} from "../reducer/adminNftReducer";

export const getNftList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getNftListPending());
        const result = await getAllNft(frmDt);
        result.data.code === 200
            ? dispatch(getNftListSuccess(result.data))
            : dispatch(getNftListError(result.message));


    } catch (error) {
        dispatch(getNftListResetMessage(error.message));
    }
};

export const getUserNftList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getUserNftItemListPending());
        const result = await getAllUserNftItem(frmDt);
        result.data.code === 200
            ? dispatch(getUserNftItemListSuccess(result.data))
            : dispatch(getUserNftItemListError(result.message));


    } catch (error) {
        dispatch(getUserNftItemListResetMessage(error.message));
    }
};

export const getAdminNftDetail = () => async (dispatch) => {
    try {
        dispatch(adminNftDetailPending());
        const result = await fetchNftDetails();
        result.code === 200
            ? dispatch(adminNftDetailSuccess(result))
            : dispatch(adminNftDetailError(result.message));


    } catch (error) {
        dispatch(adminNftDetailResetMessage(error.message));
    }
};

export const getCollectibleNftList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getCollectibleNftListPending());
        const result = await getAllCollectibleNft(frmDt);
        result.data.code === 200
            ? dispatch(getCollectibleNftListSuccess(result.data))
            : dispatch(getCollectibleNftListError(result.message));


    } catch (error) {
        dispatch(getCollectibleNftListResetMessage(error.message));
    }
};

export const updateStatus = (frmDt) => async (dispatch) => {
    try {
        dispatch(nftStatusPending());
        const result = await updateNftStatus(frmDt);
        if (result.data.code === 200) {
            return dispatch(nftStatusSuccess(result.data))
        }
        else {
            return dispatch(nftStatusError(result.message));
        }


    } catch (error) {
        dispatch(nftStatusResetMessage(error.message));
    }
};

export const getApprovalNftList = (frmDt) => async (dispatch) => {
    try {
        dispatch(getNftAppurvalListPending());
        const result = await getAllApprovalNft(frmDt);
        result.data.code === 200
            ? dispatch(getNftAppurvalListSuccess(result.data))
            : dispatch(getNftListErAppurvalror(result.message));


    } catch (error) {
        dispatch(getNftAppurvalListResetMessage(error.message));
    }
};