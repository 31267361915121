import React, { useState, useEffect } from 'react';
import rotate from '../assets/images/rotate.png';
import watchImg1 from '../assets/images/watch_img1.png';
import watchImg2 from '../assets/images/watch_img2.png';
import watchImg3 from '../assets/images/watch_img3.png';
import watchImg4 from '../assets/images/watch_img4.png';
import watchImg5 from '../assets/images/watch_img5.png';
import watchImg6 from '../assets/images/watch_img6.png';
import watchImg7 from '../assets/images/watch_img7.png';
import watchImg8 from '../assets/images/watch_img8.png';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getSellNftList, getNftDetail } from '../pages/redux/action/nftAction';

const OnSale = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { nftSellList } = useSelector((state) => state.nftItemDetail)
    useEffect(() => {
        dispatch(getSellNftList());
    }, [getSellNftList])


    const nftDetail = (id, userid) => {
        dispatch(getNftDetail({ nftItemId: id, userId: userid }))
        localStorage.setItem('nftItemId', JSON.stringify(id));
        localStorage.setItem('userId', JSON.stringify(userid));
        navigate(`/product-detail`)
    }
    return (
        <>
            <ul className='p-0 productList d-flex flex-wrap'>
                {
                    (nftSellList.data && nftSellList.data.length > 0) ?
                        nftSellList.data.map((data, i) => (

                            <li onClick={() => nftDetail(data._id, data.userId)}>
                                <div className='inner'>
                                    <div className='img'><a><img alt="" src={data.featuredImage} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: {data.nftItemCount}</span></div>
                                    <div className='userName'>
                                        <a>{data.itemName}</a>
                                        <span className='d-block pt-1'>{data.userName}</span>
                                    </div>
                                    {
                                        data.amount ? <div className='price'>Price <span className='d-block mt-1'>{data.amount} HBAR</span></div> : ''
                                    }
                                </div>
                            </li>
                        )) : ""


                }
            </ul>

            {/* <ul className='p-0 productList d-flex flex-wrap'>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg1} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg2} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg3} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg4} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg5} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg6} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg7} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
                <li>
                    <div className='inner'>
                        <div className='img'><a href='#'><img alt="" src={watchImg8} /></a> <span className='rotate'><img alt="" src={rotate} /></span> <span className='item'>Items: 5</span></div>
                        <div className='userName'>
                            <a>Luxury Watch #101</a>
                            <span className='d-block pt-1'>Creator Name</span>
                        </div>
                        <div className='price'>Price <span className='d-block mt-1'>2.58 HBAR</span></div>
                    </div>
                </li>
            </ul> */}
        </>

    )

}
export default OnSale;