
import React from 'react';
import ReactDOM from "react-dom";
import './index.scss';
import App from './App';
import store from './store'
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import HashConnectAPIProvider from './helpers/pairwallet'
// import * as dotenv from 'dotenv'
import 'react-toastify/dist/ReactToastify.css';
// dotenv.config()
let persistor = persistStore(store);
const rootElement = document.getElementById("root");
ReactDOM.render(
    <HashConnectAPIProvider debug={true}>
    <React.StrictMode> 
        
         <Provider store={store}>
    <PersistGate persistor={persistor}>
<App />
</PersistGate>
</Provider></React.StrictMode>
</HashConnectAPIProvider>
  
, rootElement);


