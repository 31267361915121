import React, { } from 'react';
import './EditProfile.scss';
import Header from '../component/Header.js';
import Footer from '../component/Footer.js';
import ProfileMenu from '../component/ProfileMenu';
import Button from 'react-bootstrap/Button';
const Payment = () => {



    return (
        <>
            <div className="mainCon">
                <Header />
                <div className='pt-4 pb-4 pt-md-5 pb-md-5'>
                    <div className='container'>
                        <div className='d-flex flex-wrap'>
                            <ProfileMenu />
                            <div className='profileDet'>
                                <div className='mainHd mb-2 mb-md-3'>Payment Settings</div>
                                <div className='subHd mb-3'>Accept card payments</div>
                                <div className='noteText mb-4'>
                                    Complete the verification steps below to accept card payments for your listings, using Moonpay.<br />
                                    Moonpay is required to collect certain information to use their services. This information is not stored by Ledex.
                                </div>
                                <ul className='mb-4 mb-md-5 p-0 paymentList'>
                                    <li className='d-block d-sm-flex align-items-center justify-content-between'>
                                        <div>
                                            <span className='d-block mb-2'>Basic verification</span>
                                            Buy or sell up to $7,500 worth of NFTs through card payments with Moonpay.
                                            <ul className='list p-0 mt-2'>
                                                <li>Name</li>
                                                <li>Date of birth</li>
                                                <li>Residence</li>
                                            </ul>
                                        </div>
                                        <div className='mt-3 mt-sm-0'><Button>Begin</Button></div>
                                    </li>
                                    <li className='d-block d-sm-flex align-items-center justify-content-between'>
                                        <div>
                                            <span className='d-block mb-2'>Advanced Verification</span>
                                            Buy or sell NFTs with no lifetime limits
                                            <ul className='list p-0 mt-2'>
                                                <li>Advanced customer verification</li>
                                                <li>ID Verification</li>
                                            </ul>
                                        </div>
                                        <div className='mt-3 mt-sm-0'><Button>Begin</Button></div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default Payment;