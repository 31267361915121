import React, { } from 'react';
import './Home.scss';
import Header from '../component/Header.js';
import HomeBanner from '../component/HomeBanner.js';
import BestLimitedEditions from '../component/BestLimitedEditions.js';
import BestCollection from '../component/BestCollection';
import NFTCollections from '../component/NFTCollections.js';
import TopCollections from '../component/TopCollections.js';
import CreateAndSell from '../component/CreateAndSell.js';
import BrowseByCategory from '../component/BrowseByCategory.js';
import Footer from '../component/Footer.js';
import bg from '../assets/images/blurred.png';
// import Video from '../assets/images/video_img.png';
// import PlayIcon from '../assets/images/play_icon.png';
// import VideoLeft from '../assets/images/video_left.png';
import VideoLeft1 from '../assets/1.png'
import VideoRight1 from '../assets/2.png';
// import VideoRight from '../assets/images/video_right.png';
import mailingImg from '../assets/images/mailling_img.png';
import background from '../assets/images/background.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Home = () => {

   
    AOS.init({
        once: true,
        duration: 1200,
        });
    return (
        <>
            <div className="mainCon home" style={{ backgroundImage: 'url(' + background + ')', backgroundPosition: 'center top', backgroundAttachment: 'fixed' }}>
                <Header />
                <HomeBanner />
                <BestLimitedEditions />
                <div className='overflow-hidden'>
                    <div className='container'>
                        <div className='d-flex flex-wrap justify-content-between'>
                            <BestCollection />
                            <NFTCollections />
                        </div>
                    </div>
                </div>
                <TopCollections />
                <CreateAndSell />
                <BrowseByCategory />
                <div className='pb-large overflow-hidden'>
                    <div className='container'>
                        <div className='mainHd mb-3 mb-md-4 text-center' data-aos="fade-down" data-aos-duration="1000">Meet Ledex Protocol</div>
                        <div className='videoBox position-relative'>
                            <div className='video' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/3WdWTrbYBAk?si=fmkJNa10dPtJooeO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            {/* <div className='videoLeftImg' data-aos="fade-right" data-aos-duration="1000"><img alt="" src={VideoLeft1} /></div>
                            <div className='videoRightImg' data-aos="fade-left" data-aos-duration="1000"><img alt="" src={VideoRight1} /></div> */}
                        </div>
                    </div>
                </div>
                <div className='pb-large'>
                    <div className='container'>
                        <div className='mailingList position-relative d-flex align-items-center justify-content-center' style={{ backgroundImage: 'url(' + bg + ')', backgroundPosition: 'center', backgroundSize: 'cover' }}>
                            <div className='inner'>
                                <div className='mailingImg' data-aos="flip-up" data-aos-duration="1500"><img alt="" src={mailingImg} /></div>
                                <div className='mainHd mb-3 mb-sm-4 text-center' data-aos="fade-down" data-aos-duration="1500">Join Our Mailing List</div>
                                <div className='eamilerBox position-relative' data-aos="fade-up" data-aos-duration="1500"><input type="text" className='emailerInput' placeholder='Email Address' /> <button type='button' className='subscribeBtn'>Subscribe</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    )

}
export default Home;