import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    status: '',
    message: '',
    nftAdminData: {},
    nftList: [],
    nftUserList: [],
    collectibleNftLists: [],
    nftAppurvalList:[]

};

const adminNftDetail = createSlice({
    name: 'adminNftDetail',
    initialState,
    reducers: {
        getNftListPending: (state) => {
            state.isLoading = true;
        },
        getNftListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.nftList = payload
        },
        getNftListError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getNftListResetMessage: (state) => {
            state.message = '';
        },

        getUserNftItemListPending: (state) => {
            state.isLoading = true;
        },
        getUserNftItemListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.nftUserList = payload
        },
        getUserNftItemListError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getUserNftItemListResetMessage: (state) => {
            state.message = '';
        },

        adminNftDetailPending: (state) => {
            state.isLoading = true;
        },
        adminNftDetailSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.nftAdminData = payload
        },
        adminNftDetailError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        adminNftDetailResetMessage: (state) => {
            state.message = '';
        },

        getCollectibleNftListPending: (state) => {
            state.isLoading = true;
        },
        getCollectibleNftListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.collectibleNftLists = payload
        },
        getCollectibleNftListError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getCollectibleNftListResetMessage: (state) => {
            state.message = '';
        },

        nftStatusPending: (state) => {
            state.isLoading = true;
        },
       nftStatusSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.message = payload
        },
       nftStatusError: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
       nftStatusResetMessage: (state) => {
            state.message = '';
        },

        getNftAppurvalListPending: (state) => {
            state.isLoading = true;
        },
        getNftAppurvalListSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'success';
            state.nftAppurvalList = payload
        },
        getNftListErAppurvalror: (state, { payload }) => {
            state.isLoading = false;
            state.status = 'error';
            state.message = payload;
        },
        getNftAppurvalListResetMessage: (state) => {
            state.message = '';
        },



    },
});

const { reducer, actions } = adminNftDetail;
export const { getNftListPending, getNftListSuccess, getNftListError, getNftListResetMessage,
    getUserNftItemListPending, getUserNftItemListSuccess, getUserNftItemListError,
    getUserNftItemListResetMessage, adminNftDetailPending, adminNftDetailSuccess, adminNftDetailError,
    adminNftDetailResetMessage, getCollectibleNftListPending, getCollectibleNftListSuccess, getCollectibleNftListError,
    getCollectibleNftListResetMessage,nftStatusPending,nftStatusSuccess,nftStatusError,nftStatusResetMessage
    ,getNftAppurvalListPending,getNftAppurvalListSuccess,getNftListErAppurvalror,getNftAppurvalListResetMessage
} = actions;

export default reducer;